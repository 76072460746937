import Dialog from "../../lib/Dialog";
import general from "../../general";

export default ({type, on}) => {
  const operate = () => {
    Dialog.dialog({
      mask: true,
      title: '选择操作',
      btns: [
        {
          text: '一键已读', onPress: () => {
            Dialog.confirm({
              title: '确定一键已读吗？',
              yes: () => {
                Dialog.loading();
                general.axios.post('mess_operate_all', {type, operate_type: 1})
                    .finally(() => {
                      Dialog.close();
                    })
                    .then(res => {
                      let {status} = res.data;
                      if (!status.succeed) {
                        Dialog.error(status.error_desc);
                        return;
                      }
                      on && on();
                    })
                    .catch(err => {
                      Dialog.error(err.message);
                    });
              }
            });
          }
        },
        {
          text: '一键删除', onPress: () => {
            Dialog.confirm({
              title: '确定一键删除吗？',
              yes: () => {
                Dialog.loading();
                general.axios.post('mess_operate_all', {type, operate_type: 2})
                    .finally(() => {
                      Dialog.close();
                    })
                    .then(res => {
                      let {status} = res.data;
                      if (!status.succeed) {
                        Dialog.error(status.error_desc);
                        return;
                      }
                      on && on();
                    })
                    .catch(err => {
                      Dialog.error(err.message);
                    });
              }
            });
          }
        },
        {text: '取消'},
      ]
    });
  }

  return <div className="message-operate" onClick={operate}>编辑</div>;
};