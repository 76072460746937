import React, {useState} from "react";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import moment from "moment";
import Image from "../../lib/Image";
import Dialog from "../../lib/Dialog";
import {CSSTransition} from "react-transition-group";
import { CloseOutlined } from "@ant-design/icons";

export default ({gift, setGift, copyShow}) => {
  const [show, setShow] = useState(true);

  // console.log(copyShow);

  return gift ? <CSSTransition in={show} timeout={250} appear={true} onExited={() => {
    setGift(null);
    setShow(true);
  }}>
    <div className="gift-detail">
      <div className="gift-mask" onClick={() => setShow(false)}/>
      <div className="gift-container">
        <div className="gift-top">
          <div className="icon"><Image src={gift.new_image?.thumb}/></div>
          <div className="text">
            <div className="name">{gift.gift_name}</div>
            <div className="time">有效期：{moment(gift.gift_dealine * 1000).format('YYYY-MM-DD HH:mm:ss')}</div>
          </div>
        </div>
        <div className="gift-main">
          <div className="gift-desc">
            <div className="title">礼包说明</div>
            <div className="main">{gift.gift_desc}</div>
          </div>
          <div className="gift-desc">
            <div className="title">使用说明</div>
            <div className="main">
              {gift.gift_introduce}
            </div>
          </div>
        </div>
        <CloseOutlined onClick={()=>{
          setShow(false)
        }} style={{position:'absolute',padding:'20px',top:'0',right:'0',color:'#999'}}></CloseOutlined>
        {copyShow ? 
        <CopyToClipboard text={gift.gift_code} onCopy={() => Dialog.info('已复制')}>
          <div className="gift-bottom">复制</div>
        </CopyToClipboard>
        : null}
      </div>
    </div>
  </CSSTransition> : null;
};