import {useHistory, Link} from "react-router-dom";
import React, {useState, useEffect} from "react";
import general from "../../general";
import {CloseOutlined} from "@ant-design/icons";
import {useInterval} from "ahooks";
import {prod} from "../../env";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";

export default props => {
  const history = useHistory();
  const [user, setUser] = useActiveState('user');
  const [data, setData] = useState({});
  const [focus, setFocus] = useState('');

  const [coding, setCoding] = useState(false);
  const [codeTime, setCodeTime] = useState(0);

  useInterval(() => {
    if (codeTime) {
      setCodeTime(codeTime - 1);
    }
  }, 1000);
  const code = () => {
    if (!data.email) {
      Dialog.error('请输入邮箱');
      return;
    }
    if (coding || codeTime) {
      return;
    }
    setCoding(true);
    Dialog.loading();
    general.axios.post('/sendEmailCode', {
      email: data.email,
      type: 'bindEmail',
    })
      .finally(() => {
        setCoding(false);
        Dialog.close();
      })
      .then(response => {
        let result = response.data;
        if (!result.status.succeed) {
          Dialog.error(result.status.error_desc);
        } else {
          setCodeTime(60);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const [saving, setSaving] = useState(false);
  const save = () => {
    if (saving) {
      return;
    }
    if (!data.email) {
      Dialog.error('请输入邮箱');
      return;
    }
    if (!data.code) {
      Dialog.error('请输入验证码');
      return;
    }
    setSaving(true);
    Dialog.loading();
    general.axios.post('/user/user/bindEmail', data)
      .finally(() => {
        setSaving(false);
        Dialog.close();
      })
      .then(res => {
        let {status} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          Dialog.success('保存成功', () => {
            setUser({...user, email: res.data.data.email});
            history.goBack();
          });
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  return <div className="user-nickname">
    <div className="user-input">
      <div className="name">
        {/* 手机号 */}
        <div className="area-num-text">
          <div to="/account/country" className="value">
             邮箱
          </div>
        </div>
      </div>
      <input name="mobile" 
      type="text" 
        value={data.email} 
        placeholder="请输入邮箱" 
        autoComplete="off" 
        onFocus={() => setFocus('mobile')} 
        onChange={e => setData({...data, email: e.target.value})}/>
      {focus === 'mobile' && data.email ? <CloseOutlined className="close" 
        onClick={() => setData({...data, email: ''})}/> : null}
    </div>
    <div className="user-input">
      <div className="name">验证码</div>
      <input 
        name="code" type="text" 
        value={data.code} 
        placeholder="请输入验证码" 
        autoComplete="off" 
        onFocus={() => setFocus('code')} 
        onChange={e => setData({...data, code: e.target.value})}/>
      <div className={'btn ' + (coding || codeTime ? 'disable' : '')} onClick={code}>
        {coding ? '发送中' : (codeTime ? (codeTime + '秒后重发') : '发送验证码')}
      </div>
    </div>
    <div className="user-btn" onClick={save}>保存</div>
  </div>;
};