import React, { useEffect, useState } from 'react'
import Dialog from '../../../lib/Dialog'
import general from '../../../general'
import Image from '../../../lib/Image'
import Subtitle from '../../components/Subtitle'
import ReceiveAlert from './ReceiveAlert'
import { NavLink } from '../../../lib/Navigation'
import { useScroll } from 'ahooks'
import $ from 'jquery'
import useActiveState from '../../../lib/useActiveState'

export default function GameList(props) {

  const [data, setData] = useState()
  const [game_list, setgame_list] = useState([])
  const [tag_id, settag_id] = useState('')
  const [init, setInit] = useState(false)
  const [visible,setVisible] = useState(false)
  const [receiveData,setReceiveData] = useState()
  const [more,setMore] = useState(true)
  const [page,setPage] = useState(1)
  const [load,setLoad] = useState(false)
  const [refresh,setRefresh] = useActiveState('receive-refresh')

  const getData = (tag,pages) => {
    if (load){
      return
    }
    setLoad(true)
    Dialog.loading()
    general.axios.post('/base/signbonus/get648GiftAndVoucher', {
      id: '',
      tag_id:tag,
      pagination:{
        page:pages,
        count:20
      }
    })
      .finally(() => {
        Dialog.close()
      })
      .then((res) => {
        const { data, status } = res.data
        if (status.succeed) {
          setLoad(false)
          setInit(true)
          setData(data)
          setgame_list(() => pages == 1 ? res.data.data.game_list : [...game_list, ...res.data.data.game_list]);
          setPage(pages)
          let {paginated} = data;
          setMore(paginated.more);
          if (props.handleShow){
            props.handleShow()
          }
        } else {
          Dialog.error(status.error_desc)
        }
      })
  }

  const scroll = useScroll()
  useEffect(()=>{
    if (props.active){
      if (scroll.top < -80) {
        getData(tag_id,1);
      }
      if (scroll.top + window.innerHeight + 20 > $(document).height() && more) {
        getData(tag_id, page + 1);
      }
    }
  },[scroll,props.active])

  useEffect(() => {
    if (props.active && !init) {
      getData(tag_id,1)
    }
  }, [props.active,init])

  const handleTag = (item) => {
    settag_id(item.id)
    getData(item.id,1)
  }

  const receive = (game) => {
    if (game.isreceived){
      return
    }
    Dialog.loading()
    const tmp = {
      packid: game.packid || '',
      voucherId:game.voucherId || '',
      claim_type: game.claim_type,
      topic_id: game.maiyou_gameid
    }
    general.axios.post('/base/signbonus/receive', tmp).finally(() => {
      Dialog.close()
    }).then((res) => {
      const { data, status } = res.data
      if (status.succeed) {
        const info = {...data, game_id: game.game_id}
        setReceiveData(info)
        setVisible(true)
        game_list.map(item => {
          if (item.game_id == game.game_id) {
            item.isreceived = true
          }
          return item
        })
        setgame_list([...game_list])
        setRefresh(e=>!e)
      } else {
        Dialog.error(status.error_desc)
      }
    })
  }

  return (
    <div className="game-list">
      <div className='list-header'>
        {data?.tag.map(item => {
          return (
            <div key={item.id} onClick={() => handleTag(item)} className={'tag ' + (tag_id == item.id ? 'active' : '')}>
              <div className="name">{item.tagname}</div>
            </div>
          )
        })}
      </div>
      <div className="games">
        {game_list?.map(game => {
          return (
            <div key={JSON.stringify(game)} className='item'>
              <div className="game">
                <NavLink user={false} to={`/game?id=${game.game_id}`}>
                  <Image className="icon" src={game.game_image.thumb} />
                </NavLink>
                <NavLink user={false} to={`/game?id=${game.game_id}`} className="text">
                  <div className="name">{game.game_name}</div>
                  <div className="info1">
                    {game.game_classify_type.trim()}
                    <Subtitle>{game.nameRemark}</Subtitle>
                  </div>
                  <div className="info3">
                    {
                      game.bottom_lable ?
                        <>
                          {Object.keys(game.bottom_lable).length > 0 ?
                            <div style={{ display: 'flex', alignItems: 'center' ,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}}>
                              <span className="first-text" style={{ background: game.bottom_lable.type == 1 ? '#FF8C50' : '#9F9DFC' }}>{game.bottom_lable.left_content}</span><span className="second-text" style={{ color: game.bottom_lable.type == 1 ? '#FF8C50' : '#9F9DFC', borderColor: game.bottom_lable.type == 1 ? '#FF8C50' : '#9F9DFC' }}>{game.bottom_lable.right_content}</span>
                            </div> : null}
                        </>
                        : null
                    }
                    {game.exclusive_game_describe?.length > 0 ? <div className="speed">{game.exclusive_game_describe}</div> : null}
                    {game.is_support_accele ? <div className="speed">可加速</div> : null}
                    {game.is_easy_play ? <div className="speed">省心玩</div> : null}
                    {Object.keys(game.bottom_lable).length == 0 && !game.is_support_accele && !game.is_easy_play && game.introduction && game.exclusive_game_describe?.length == 0 ? <span style={{ color: '#888' }}>{game.introduction}</span> : null}
                  </div>
                </NavLink>
                <div className="action">
                  <NavLink className={"btn " + (game.isreceived ? 'disable' : '')} onClick={() => receive(game)}>{game.isreceived ? '已领取' : game.button}</NavLink>
                  {!game.isreceived && <div className="btn-desc">{game.but_desc}</div>}
                </div>
              </div>
            </div>
          )
        })}
      </div>
      {game_list?.length > 0 && !more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
      {visible && <ReceiveAlert receiveData={receiveData} action={()=>setVisible(false)} visible={visible}></ReceiveAlert>}
    </div>
  )
}
