import { useEffect, useState } from "react";
import general from "../../../general";
import {CloseOutlined, EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import { prod, url } from "../../../env";
import { useLocalStorageState, useToggle, useLockFn } from "ahooks";
import useActiveState from "../../../lib/useActiveState";
import Dialog from "../../../lib/Dialog";
import URI from "urijs";
import MobileDetect from "mobile-detect";
import { NavLink } from "../../../lib/Navigation";
import useUrlState from "@ahooksjs/use-url-state";
import { Popup } from 'antd-mobile'
import { useInterval } from "ahooks";
export default function Account(props) {

  const [loging, setLoging] = useState(false);
  const [user, setUser] = useActiveState('user');
  const [whether, setWhether] = useActiveState('whether');//是否进行过退出操作

  const [visiable, setVisiable] = useState(true)
  const [visibility, setVisibility] = useState('visible')

  const finalLogin = user => {
    if (loging) {
      return;
    }
    setLoging(true);
    Dialog.loading();
    general.axios.post('/tickLogin', {username: user.username, tick: user.tick})
        .finally(() => {
          setLoging(false);
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            setLoging(false);
            Dialog.error(status.error_desc);
            return;
          }
          localStorage.setItem('token', data.token);
          localStorage.setItem('user_id', data.user_id);
          localStorage.setItem('username', data.username);
          setWhether('true');
          load_load();
          
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  const load_load = () => {
    general.axios.post('/user/user/getMemberInfo')
      .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {

        } else {
          setUser({isLogin: true, ...data.member_info});
          Dialog.success('登录成功',props.dismiss());
        }
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  }
  
  return (
    <div>
      <Popup className={"alert-login-index " + visibility}
        visible={visiable}
        bodyStyle={{
          borderTopLeftRadius: '13px',
          borderTopRightRadius: '13px',
          overflow: 'hidden',
        }}>
        <div className="alert-login-header">选择账号</div>
        <div className="alert-login-content">
          {props.users.map((user,index)=>{
            return (
              <div className="user" key={index} onClick={()=>finalLogin(user)}>{user.username}</div>
            )
          })}
        </div>
      </Popup>
    </div>
  )
}
