import {HashRouter as Router, Route} from "react-router-dom";
import Index from "./view/Index";
import moment from 'moment';
import 'moment/locale/zh-cn';
import {Provider} from "react-redux";
import {store} from "./lib/store";

moment.locale('zh-cn');

function App(props) {
  return <Provider store={store}>
    <Router>
      <Route render={props => <Index {...props} />}/>
    </Router>
  </Provider>;
}

export default App;

