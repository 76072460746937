import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useMount} from "ahooks";
import general from "../../general";
import Dialog from "../../lib/Dialog";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import useActiveState from "../../lib/useActiveState";
import { NavLink } from "../../lib/Navigation";

export default props => {
  const [data, setData] = useState({});
  const [agent] = useActiveState('agent');

  useEffect(()=>{
    if (localStorage.getItem('username')){
      index()
    }
  },[localStorage.getItem('username')])

  useMount(() => {
    props.setOperate(<NavLink user={true} to="/invite/record" className="invite-index-record">邀请明细</NavLink>);

    index()
  });

  const index = ()=>{
    general.axios.post('/invite/getIndexData?noaccess=1')
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          setData(data);
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  return <div className="invite-index">
    <div className="img"><img src="/resources/invite/1.png" alt=""/></div>
    <div className="img"><img src="/resources/invite/2.png" alt=""/></div>
    <NavLink user={true}>
      <CopyToClipboard text={data.inviteShareHost?.indexOf('?') !== -1 ? data.inviteShareHost : data.inviteShareHost + '?agent='+ agent} onCopy={() => {
        if (localStorage.getItem('username')){
          Dialog.info('已复制邀请链接')
        }
      }}>
        <div className="img"><img src="/resources/invite/3.png" alt=""/></div>
      </CopyToClipboard>
    </NavLink>
    <div className="img">
      <img src="/resources/invite/4.png" alt=""/>
      <div className="number">
        <div className="count">{data.count}</div>
        <div className="sum">{data.sum}</div>
      </div>
    </div>
    <div className="img"><img src="/resources/invite/5.png" alt=""/></div>
  </div>;
};