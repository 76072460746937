import { useEffect, useState } from "react";
import general from "../../general";
import { Link } from "react-router-dom";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import useUrlState from "@ahooksjs/use-url-state";
import Subtitle from "../components/Subtitle";

export default props => {
  const [article, setArticle] = useState({});
  const [game, setGame] = useState({});
  const [params, setParams] = useUrlState({}, { navigateMode: 'replace' });
  const [init, setInit] = useState(false);
  useEffect(() => {
    setGame(general.game);
  }, []);
  useEffect(() => {
    Dialog.loading();
    general.axios.post('/base/news/getNewsDetail', { news_id: props.query.id })
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let { status, data } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setInit(true);
        let result = /<body[^>]*?>([\s\S]*)<\/body>/g.exec(data.news_info.content);
        if (result && result[1]) {
          data.news_info.content = result[1];
        }
        setArticle(data.news_info);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }, []);
  useEffect(() => {
    if (article) {
      if (params.type == 'raiders') {
        props.setTitle('攻略详情');
      } else {
        props.setTitle('活动详情');
      }
      // props.setTitle(article.title);
    }
  }, [article]);

  // console.log(params);

  return init ?
    <div className="article-detail">
      <div className="roof-top">

        {params.type == 'raiders' ? <Link to={'/game?id=' + game.game_id} className="game">
          <div className="icon"><Image src={game.game_image?.thumb} /></div>
          <div className="text">
            <div className="name">
              <div className={" " + (game.nameRemark ? " name-new" : "")}>
                {game.game_name}
              </div>
            </div>
            <div className="info">
              {game.game_classify_name?.map((item, i) => <span>{item.tagname} </span>)}
              <Subtitle>{game.nameRemark}</Subtitle>
            </div>
            {/* <div className="info2">
              {game.game_desc ? game.game_desc.split('+').map(item => <div
                className="tag">{item}</div>) : game.introduction}
            </div> */}
            <div className="info3">
              {
                game.bottom_lable ? 
                <>
                {Object.keys(game.bottom_lable).length > 0 ? 
                <div >
                  <span className="first-text" style={{background:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.left_content}</span><span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
                </div> : null }
                </>
                : null
              }
              {game.exclusive_game_describe?.length > 0 ? <div className="speed">{game.exclusive_game_describe}</div> : null}
              {game.is_support_accele ? <div className="speed">可加速</div> : null}
              {game.is_easy_play ? <div className="speed">省心玩</div> : null}
              {Object.keys(game.bottom_lable).length == 0 && !game.is_support_accele && !game.is_easy_play && game.introduction && game.exclusive_game_describe?.length == 0 ? <span style={{color:'#888'}}>{game.introduction}</span> : null}                        
              </div>
          </div>
          {game.discount < 1 && game.goodGame?.length == 0 ? <div className="discount" ><span>{game.discount * 10}</span>折</div> : null}
        </Link> :
          <div className="roof-message" style={{ backgroundImage: 'url(/resources/game/activity-detail-bg.png)' }}>
            <div className="input-item">
              <div className="label">游戏名称：</div>
              {/* <div className="info">{game.game_name}</div> */}
              <div className={'name info '}>
                <div className={" " + (game.nameRemark ? game.nameRemark.length > 10 ? " name-new-length" : " name-new" : "")}>
                  {game.game_name}
                </div>
                {game.nameRemark != '' ? <div className="nameRemark-new">{game.nameRemark}</div> : null}
              </div>
            </div>
            {article?.title ?
              <div className="input-item">
                <div className="label">活动名称：</div>
                <div className="info">{article?.title}</div>
              </div>
              : null}
            {article?.receiveDesc ?
              <div className="input-item">
                <div className="label">领取方式：</div>
                <div className="info">{article.receiveDesc}</div>
              </div>
              : null}
            {article?.dateRange ?
              <div className="input-item">
                <div className="label">活动时间：</div>
                <div className="info">{article.dateRange}</div>
              </div>
              : null}
          </div>}
      </div>

      {/* <div className="roof-message-space">
        <div className="input-item">
          <div className="label">游戏名称：</div>
          <div className={'name info '}>
            <div className={" " + (game.nameRemark ? game.nameRemark.length > 10 ? " name-new-length" : " name-new" : "")}>
              {game.game_name}
            </div>
            {game.nameRemark != '' ? <div className="nameRemark-new">{game.nameRemark}</div> : null}
          </div>
        </div>
        {article?.title ?
          <div className="input-item">
            <div className="label">活动名称：</div>
            <div className="info">{article?.title}</div>
          </div>
          : null}
        {article?.receiveDesc ?
          <div className="input-item">
            <div className="label">领取方式：</div>
            <div className="info">{article.receiveDesc}</div>
          </div>
          : null}
        {article?.dateRange ?
          <div className="input-item">
            <div className="label">活动时间：</div>
            <div className="info">{article.dateRange}</div>
          </div>
          : null}
      </div> */}

      {article?.themeType == 2 ?
        <>
          <div className="act-tit">{params.type == 'raiders' ? '攻略' : '活动'}内容</div>
          <div className="contentItems">
            {
              article?.contentItems.map((item, index) =>
                item.type == 2 ?
                  <div className="contentitem" dangerouslySetInnerHTML={{ __html: item.content || null }} />
                  :
                  <div className="contentitem">
                    <div className="title">{item.title}</div>
                    <div className="contenttext">{item.content}</div>
                  </div>
              )
            }
          </div>
        </>
        :
        <>
          <div className="act-tit">{params.type == 'raiders' ? '攻略' : '活动'}内容</div>
          <div className="article">
            <div className="content" dangerouslySetInnerHTML={{ __html: article.content || null }} />
          </div>
        </>
      }

      <div className="article-footer">
        {
          article?.wechatWorkUrl ?
            <div className="article-operate" onClick={() => window.location.href = article.wechatWorkUrl}>联系客服</div>
            :
            <Link to={"/service"} className="article-operate">
              联系客服
            </Link>
        }
      </div>
      <div style={{ height: "70px",color:"transparent" }}></div>
      {/* <div className="view-end">别撩啦！已经到底啦~</div> */}
    </div>
    : null;
};