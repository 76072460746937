import React, {useEffect, useState} from "react";
import general from "../../general";
import $ from "jquery";
import {Link, useHistory} from "react-router-dom";
import {QuestionCircleOutlined,} from '@ant-design/icons';
import {useUpdateEffect, useScroll} from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import {CSSTransition} from "react-transition-group";
import useActiveState from "../../lib/useActiveState";

export default props => {
  const [explainState, setExplainState] = useState(false);
  const explain = () => {
    Dialog.alert('问答须知', <div className="qa-explain">
      想对新接触的游戏有更多的了解？游戏问答让萌新有地方问，让大神为您解答<br/>
      问答利益<br/>
      理性提问，中肯发言<br/>
      问的清楚，答的明白<br/>
    </div>);
  }
  useEffect(() => {
    props.setOperate(<QuestionCircleOutlined onClick={()=>setExplainState(true)}/>);
  }, []);
  const [game] = useState(general.game ?? {});
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const [questions, setQuestions] = useActiveState('questions');
  const [init, setInit] = useState(false);
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      game_id: props.query.id,
      pagination: {page: page},
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios.post('/base/qa/getQuestions', tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then(res => {
        let {status, data: {list, paginated}} = res.data;
        setInit(true);
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          if (parms.page) {
            setPage(parms.page);
          }
          setDatas(datas => parms.page === 1 || !parms.page ? list : [...datas, ...list]);
          setMore(paginated.more && list.length > 0);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }
  useEffect(() => {
    load();
  }, []);

  const scroll = useScroll();
  useUpdateEffect(() => {
    if (props.active) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 >= $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, props.active]);

  const history = useHistory();
  const toDetail = data => {
    // general.question = data;
    setQuestions(data);
    history.push('/answer?id=' + data.id);
  }

  const [question, setQuestion] = useState(false);
  const [content, setContent] = useState('');
  const [submiting, setSubmiting] = useState(false);
  const submit = () => {
    if (!content) {
      Dialog.info('回复评论字数不少于5个');
      return;
    }
    if(content.length < 5) {
      Dialog.info('回复评论字数不少于5个');
      return;
    }
    if (submiting) {
      return;
    }
    setSubmiting(true);
    Dialog.loading();
    general.axios.post('/base/qa/submitQuestion', {
      game_id: props.query.id,
      question: content,
    })
      .finally(() => {
        setSubmiting(false);
        Dialog.close();
      })
      .then(response => {
        let {status} = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          Dialog.success('提交成功', () => {
            setQuestion(false);
            setContent('');
          });
        }
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  }

  return <div className="qa-index">
    <div className="qa-game">
      <Link to={'/game?id=' + game.game_id} className="icon"><Image src={game.game_image?.thumb}/></Link>
      <div className="info">
        <div className="name">
          {/* {game.game_name} */}
          <div className={" " + (game.nameRemark ? " name-new" : "")}>
            {game.game_name}
          </div>
          {game.nameRemark != '' ? <div className="nameRemark-new">{game.nameRemark}</div> : null}
        </div>
        <div className="desc">有{game.played_count}人正在玩该游戏</div>
        <div className="desc">共{game.question_count}条问题，收到{game.answer_count}个回答，去逛逛吧</div>
      </div>
    </div>
    <div className="qa-items">
      {datas.map(item => <div className="qa-item" onClick={() => toDetail(item)}>
        <div className="qa-q">
          <div className="icon">问</div>
          <div className="text">{item.question}</div>
        </div>
        <div className="qa-as">
          {item.answers.list.length > 0 ? <div>
            {item.answers.list.map(item => <div className="qa-a">
              <div className="icon">答</div>
              <div className="text">{item.content}</div>
            </div>)}
            <div className="qa-more">查看全部<span>{item.answers.count}</span>个回答</div>
          </div> : <div className="qa-empty">暂无回答 快来帮帮他</div>}
        </div>
      </div>)}

      {datas.length === 0 && init ? <div className="recovery-empty"><img src="/resources/message/message_empty.png" alt=""/><br/>咦~什么都没有...</div> : null}
    </div>
    <div className="qa-operate">
      <div className="btn" onClick={() => setQuestion(true)}>我要请教</div>
    </div>
    <CSSTransition in={question} timeout={250} unmountOnExit={true}>
      <div className="qa-form">
        <div className="mask" onClick={() => setQuestion(false)}/>
        <div className="form">
          <div className="head">
            <div className="title">像{game.played_count}位玩过该游戏的人请教</div>
            <div className={'btn ' + (!content ? ' disable' : '')} onClick={submit}>提交</div>
          </div>
          <div className="input">
            <textarea rows="5" placeholder="5-100字范围内，请准确描述您的问题吧~" value={content} onChange={e => setContent(e.target.value)}/>
          </div>
        </div>
      </div>
    </CSSTransition>
    {datas.length > 0 ? <div className="view-end">别撩啦！已经到底啦~</div> : null}

    {explainState ? 
    <div className="qas-tips">
      <div className="tips">
        <div className="head">
          <div className="title">问答须知</div>
        </div>
        <div className="content">
          <div className="title">
            想对新接触的游戏有更多的了解?游戏问答---让萌新有地方问，让大神来为你解答。
          </div>
          <div className="etiquette">
            问答礼仪
          </div>
          <div className="matter">
            <img src="/resources/game/qa.png" alt=""/>
            理性提问，中肯发言:
          </div>
          <div className="matter">
            <img src="/resources/game/qa.png" alt=""/>
            问的清楚，答的明白
          </div>
        </div>
        <div className="operate" onClick={()=>setExplainState(false)}>
          知道了
        </div>
      </div>
    </div>
    : null}

  </div>;
};