import React, { useEffect, useState } from 'react'
import Dialog from '../../../lib/Dialog'
import List from '../../trade/List'

export default function trade(props) {

  useEffect(()=>{
    props.setTitle(props.query.title)
  },[])

  return (
    <div className="trade-good">
      <List {...props} 
            source={'czjl'}
            type={'project'}
            handleTitle={()=>{}}/>
    </div>
  )
}
