import React, {useEffect, useState} from "react";
import Dialog from "../../lib/Dialog";
import useActiveState from "../../lib/useActiveState";
import general from "../../general";
import {useInterval} from "ahooks";
import {Link, useHistory, useLocation} from "react-router-dom";
import {NavLink} from "../../lib/Navigation";
import URI from "urijs";

export default props => {
    const location = useLocation();
    const history = useHistory();
    const [datas, setDatas] = useState([]);
    const [gameList, setGameList] = useState([]);
    const [bateUserlist, setBateUserList] = useState([]);

    // console.log(props.query.id);

    const load = () => {
        Dialog.loading();
        general.axios.post('/base/Special/getData', {id:(props.query?.id?props.query.id:props.valueInfo)})
        .finally(() => {
            Dialog.close();
        })
        .then(response => {
            let {status, data} = response.data;
            if (!status.succeed) {
                Dialog.error(status.error_desc);
                return;
            }
            setDatas(data);
            setGameList(data.projectGameslist);
            setBateUserList(data.bate_user_list);
        })
        .catch(err => {
            Dialog.error(err.message);
        });
    }
    
    useEffect(() => {
        load();
    }, []);

    //领取
    const receive = (item, type, packid, is_received) => {
        if(!general.isLogin()) {
            PubSub.publish('alert-login',true)
        } else {
            if(type != 'add') {
                if(is_received == true) {
                    Dialog.info("您已经领取过了")
                    return false;
                }
                if(item.bate_has_joined == false) {
                    Dialog.info("加入游戏内测员进行领取");
                    return false;
                }
            }
            Dialog.loading();
            general.axios.post('/user/BateSpecial/receive', {type:type,packid:packid,maiyou_gameid:item.maiyou_gameid})
            .finally(() => {
                Dialog.close();
            })
            .then(response => {
                let {status, data} = response.data;
                if (!status.succeed) {
                    Dialog.error(status.error_desc);
                    return;
                } else {
                    Dialog.info(type == 'add' ? '加入成功，请领取内测员专属礼包' : '领取成功');
                    load();
                }
            })
            .catch(err => {
                Dialog.error(err.message);
            });
        }
    }

    const begin = () => {
        let bate_user_list = bateUserlist;
        bate_user_list.push(bateUserlist[0]);
        bate_user_list.splice(0,1);
        
        setBateUserList('');
        setBateUserList(bate_user_list);
    }

    useInterval(() => {
        begin();
    }, 1000);
    
    return <div className="beta-index">
        <div className="all">
            <div className="top">
                <img src="/resources/party/beta-top.png"/>
            </div>
            <div className="second" style={{backgroundImage: 'url(/resources/party/beta-two.png)'}}>
                <div className="baglist">
                    <div className="text">
                        <div className="name"><img src="/resources/party/beta-bag-one.png" /></div>
                        <div className="desc">内测体验券</div>
                    </div>
                    <div className="text">
                        <div className="name"><img src="/resources/party/beta-bag-two.png" /></div>
                        <div className="desc">福利体验礼包</div>
                    </div>
                    <div className="text">
                        <div className="name"><img src="/resources/party/beta-bag-three.png" /></div>
                        <div className="desc">线上特权</div>
                    </div>
                </div>
                <p>
                内测员，邀你品玩鉴赏游戏！成为内测员，领专属福利助力体验哦～
                </p>
            </div>
            <div className="third" style={{backgroundImage: 'url(/resources/party/beta-third.png)'}}>
                <div className="rollinglist">
                    <div className="inner">
                        {
                            bateUserlist ?
                            <>
                            {bateUserlist.map((item) =>
                            <div className="text">
                                <div className="banner">
                                    <img src="/resources/party/beta-banner.png" />
                                </div>
                                <div className="desc">
                                恭喜 <span style={{color:'#F9D420'}}>玩家{item}</span> 成为内测员，领取专属礼包
                                </div>
                            </div>
                            )}
                            </>
                            : null
                        }
                    </div>
                </div>
            </div>

            <div className="glist" style={{backgroundImage: 'url(/resources/party/beta-four.png)'}}>
                <div className="width">
                    <div className="title">
                        选择一款游戏，成为内测员并领取专属礼包吧！
                    </div>
                    {
                        gameList ?
                        <>
                        {gameList.map((item) => 
                            <div className="content" style={{backgroundImage: 'url(/resources/party/beta-content.png)'}}>
                            <div className="project-item">
                                <div>
                                    <div className="item-base">
                                        <div className="icon">
                                            <img src={item.game_image.thumb}/>
                                        </div>
                                        <div className="text">
                                            <div className="name">
                                                {/* {item.game_name} */}
                                                <div className={" " + (item.nameRemark ? " name-new" : "")}>
                                                    {item.game_name}
                                                </div>
                                                {item.nameRemark ? <div className="nameRemark-new">{item.nameRemark}</div> : null}
                                            </div>
                                            <div className="info1">{item.game_classify_type}&nbsp;&nbsp; {item.howManyPlay}人在玩</div>
                                            <div className="info2">
                                                已招募内测员：<span>{item.bate_test_total}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {
                                        item.bate_list.length > 0 ?
                                        <>
                                        {item.bate_list.map((bate) => <div className="item-gift-list">
                                            <div className="icon">
                                                <img src={"/resources/party/" + (bate.type == 'welfare' ? 'beta-gift-one': bate.type == 'voucher' ? 'beta-gift-two' : 'beta-gift-third') +".png "} />
                                            </div>
                                            <div className="item-gift-text">
                                                <div className="text-title" style={{backgroundImage: 'url(/resources/party/'+ (bate.type == 'welfare' ? 'beta-style-one': bate.type == 'voucher' ? 'beta-style-two' : 'beta-style-third') +'.png)'}}>
                                                    {
                                                        bate.type == 'welfare' ? '内测员福利': bate.type == 'voucher' ? '内测员专属券' : '内测员特权礼包'
                                                    }
                                                </div>
                                                <div className="text-info">
                                                    <div className="info-content">
                                                        {bate.content}
                                                    </div>
                                                    <div className="info-btn">
                                                        <div className={"btn-receive " + (bate.is_received ? 'already-lq' : '')} onClick={() => receive(item, bate.type, bate.packid, bate.is_received)}>
                                                            {bate.is_received ? '已领取':'领取'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )}
                                        </>
                                        :null
                                    }
                                </div>
                                <div className="join" >
                                    {item.bate_has_joined 
                                    ?
                                    <Link to={"/game?id="+item.game_id}>
                                        <img src="/resources/party/beta-already-join.png" />
                                    </Link>
                                    :
                                    <img src="/resources/party/beta-join.png" onClick={() => receive(item, 'add', '', '')}/>
                                    }
                                </div>
                            </div>
                        </div>
                        )}
                        </>
                        : null
                    }
                </div>
            </div>
        </div>
    </div>
};