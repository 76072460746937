import {useEffect} from "react";
import general from "../../general";
import $ from "jquery";
import {useLocalStorageState, useReactive, useScroll} from "ahooks";
import KeepScroll from "../../lib/KeepScroll";
import moment from "moment";
import {Link} from "react-router-dom";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";

const types = [
  {
    id: '1',
    text: '活动推荐',
  },
  {
    id: '2',
    text: '活动公告',
  },
];

export default props => {
  const [typeIndex, setTypeIndex] = useLocalStorageState('article-index', 0);
  const datas = useReactive(types.map(item => ({
    id: item.id,
    text: item.text,
    init: false,
    loading: null,
    page: 1,
    more: null,
    datas: []
  })));
  const load = (parms = {}) => {
    let data = datas[typeIndex];
    if (data.loading) {
      return;
    }
    data.loading = true;
    Dialog.loading();
    let tmp = {
      news_type: data.id,
      pagination: {count: 20, page: data.page},
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios.post('/base/news/getNewsList', tmp)
      .finally(() => {
        data.loading = false;
        Dialog.close();
      })
      .then(res => {
        let {status, data: result} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        data.init = true;
        if (parms.page) {
          data.page = parms.page;
        }
        data.datas = parms.page === 1 || !parms.page ? result.top_news_list : [...data.datas, ...result.top_news_list];
        data.more = result.paginated.more && result.top_news_list.length > 0;
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  }
  useEffect(() => {
    if (props.active && !datas[typeIndex].init) {
      load();
    }
  }, [props.active, typeIndex]);

  const scroll = useScroll();
  useEffect(() => {
    let data = datas[typeIndex];
    if (props.active && data.init) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 >= $(document).height() && data.more) {
        load({page: data.page + 1});
      }
    }
  }, [scroll, props.active]);

  return <div className="article-index">
    <div className="article-head">
      {types.map((item, index) => <div className={'item' + (index === typeIndex ? ' active' : '')} onClick={() => setTypeIndex(index)}>{item.text}</div>)}
    </div>
    <div className="article-body">
      {datas.map((item, index) =>
        <div className={'type-item' + (index === typeIndex ? ' active' : '')}>
          <KeepScroll active={index === typeIndex}>
            {item.datas.map(item =>
              <Link to={'/article/detail?id=' + item.news_id} className="item" onClick={() => general.article = item}>
                <div className="icon"><Image src={item.new_image.thumb} alt=""/></div>
                <div className="info">
                  <div className="title">{item.news_title}</div>
                  <div className="time">发布时间：{moment(item.news_date * 1000).format('YYYY-MM-DD HH:mm:ss')}</div>
                </div>
              </Link>)}
          </KeepScroll>
        </div>)}
      {datas.length > 0 ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
    </div>
  </div>;
};