import React, { useEffect, useState } from 'react'
import Login from './Login'
import Register from './Register'
import Forgot from './Forgot'
import Account from './Account'
import Password from './Password'
import Thingsknow from './Thingsknow'
import Auth from './Auth'
import useActiveState from '../../../lib/useActiveState'

export default function Index(props) {

  const [users, setUsers] = useState()
  const [visibility, setVisibility] = useState('')
  const [registerAgreement, setRegisterAgreement] = useState(false)
  const [authArray, setAuthArray] = useState([])
  const [data, setData] = useState({})
  const [user, setUser] = useActiveState('user');
  const [iframeUrl, setIframeUrl] = useActiveState('iframeUrl');
  /**
   * -1 隐藏
   * 0 登录
   * 1 注册
   * 2 忘记密码
   * 3 选择账号
   * 4 设置密码
   * 5 须知
   * 6 身份认证
   * */

  const [alertType, setAlertType] = useState(-1)

  const dismiss = () => {
    setAlertType(-1)
    handleVisibility('hidden')
    if (iframeUrl && iframeUrl.length > 0) {
      let url = iframeUrl
      url = url.replace('username','username=' + localStorage.getItem('username'))
      url = url.replace('token','token=' + localStorage.getItem('token'))
      window.location.replace(url)
      setIframeUrl('')
    }
  }

  const login = () => {
    setAlertType(0)
    handleVisibility('visible')
  }

  const register = () => {
    handleVisibility('hidden')
    setAlertType(1)
  }

  const forgot = () => {
    handleVisibility('hidden')
    setAlertType(2)
  }

  const account = (e) => {
    handleVisibility('hidden')
    setUsers(e)
    setAlertType(3)
  }

  const password = () => {
    handleVisibility('hidden')
    setAlertType(4)
  }

  const thingsknow = () => {
    handleVisibility('hidden')
    setAlertType(5)
  }

  const auth = (methods, data) => {
    setAuthArray(methods)
    setData(data)
    handleVisibility('hidden')
    setAlertType(6)
  }

  const handleVisibility = (e) => {
    setVisibility(e)
  }

  const handleRegisterAgreement = (e) => {
    setRegisterAgreement(e)
  }

  useEffect(()=>{
    window.addEventListener('message',(e) => {
      if (e.data == 'locatlogin'){
        PubSub.publish('alert-login',true)
        setIframeUrl(window.location.href)
      }
    }, false);
  },[])

  return (
    <div>
      {user.isLogin ? null :
        <div>
          <div key={0}>
            <Login
              active={props.active}
              visibility={visibility}
              register={register}
              dismiss={dismiss}
              forgot={forgot}
              account={account}
              password={password}
              thingsknow={thingsknow}
              auth={auth}
              registerAgreement={registerAgreement}
              handleRegisterAgreement={handleRegisterAgreement}>
            </Login>
          </div>
          <div key={1}>
            {alertType == 1 &&
              <Register
                login={login}
                dismiss={dismiss}
                visibility={visibility}
                handleVisibility={handleVisibility}
                registerAgreement={registerAgreement}
                handleRegisterAgreement={handleRegisterAgreement}>
              </Register>}
          </div>
          <div key={2}>{alertType == 2 && <Forgot login={login} registerAgreement={registerAgreement} handleRegisterAgreement={handleRegisterAgreement}></Forgot>}</div>
          <div key={3}>{alertType == 3 && <Account users={users} dismiss={dismiss}></Account>}</div>
          <div key={4}>{alertType == 4 && <Password login={login} dismiss={dismiss}></Password>}</div>
          <div key={5}>
            {alertType == 5 &&
              <Thingsknow
                dismiss={dismiss}
                login={login}
                handleVisibility={handleVisibility}>
              </Thingsknow>}
          </div>
          <div key={6}>{alertType == 6 && <Auth login={login} authArray={authArray} data={data} handleRegisterAgreement={handleRegisterAgreement}></Auth>}</div>
        </div>
      }
    </div>
  )
}
