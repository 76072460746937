import React from 'react'
import { useEffect, useState } from 'react'
import './css/GameTools.css';
import Dialog from '../../../lib/Dialog'
import general from '../../../general'
import { NavLink } from '../../../lib/Navigation'
import Manager from '../../components/Manager';

export default function GameTools(props) {

  const [list, setList] = useState([])
  const [indexArray, setIndexArray] = useState([])

  const getToolBoxs = () => {
    general.axios.post('getToolBoxs',{game_id:props.game.maiyou_gameid})
    .finally(() => {
      Dialog.close();
    })
    .then(res => {
      let {status, data} = res.data;
      if (!status?.succeed) {
        Dialog.error(status.error_desc);
      } else {
        setList(data)
      }
    })
    .catch(err => {
      Dialog.error(err.message);
    });
  }

  useEffect(() => {
    if (props.active) {
      getToolBoxs()
    }
  }, [props.active])
  
  useEffect(()=>{
    console.log(indexArray);
  },[indexArray])

  return (
    <div className="game-tools-index">
      <div className="game-tools-index-container">
        {list.map((item,index)=>(
          <div key={index} className='game-tools-list-item'>
            <div className="item-top">
              <img className='icon' src={item.icon} alt="" />
              <div className="content">
                <div className="left">
                  <div className="title">{item.title}</div>
                  <div className="desc">{item.desc}</div>
                </div>
                {item.type == 'cloudafk' ? <NavLink onClick={()=>{
                  const video = document.getElementById('video')
                  if (video){
                    video.pause()
                  }
                  Manager.push({event_id:'D-5-2'})
                }} to="/hangup" className="right">去使用</NavLink> : null}
              </div>
            </div>
            <div className="item-line"></div>
            {indexArray.includes(index) && item.video.length > 0 && <div style={{padding:'15px 15px 0'}}>
              <video id='video' controls loop poster={item.video_img_url} playsinline="true" src={item.video} width='100%' autoPlay={true} style={{borderRadius:'8px'}}></video>
            </div>}
            {item.video.length == 0 && <div className="item-content" style={{display:indexArray.includes(index) ? '' : 'none',marginTop:'15px'}} dangerouslySetInnerHTML={{__html: item.content.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2')}}/>}
            <div className="item-action" onClick={()=>{
              if (indexArray.includes(index)) {
                const arr = indexArray.filter(item => item != index)
                setIndexArray(arr)
              }else{
                setIndexArray([...indexArray,index])
              }
            }}>
              <div className="text">{indexArray.includes(index) ? '收起' : '使用介绍'}</div>
              <img className='icon' src={'resources/hangup/' + (indexArray.includes(index) ? 'up' : 'down') + '.png'} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
