import React, {useEffect, useRef, useState, useCallback, useLayoutEffect} from "react";
import {useLocalStorageState, useScroll, useThrottleFn, useLockFn, useMount, useSessionStorageState} from "ahooks";
import general, {redux} from "../../general";
import {Swiper, SwiperSlide} from 'swiper/react';
import {NavLink} from "../../lib/Navigation";
import moment from "moment";
import useActiveState from "../../lib/useActiveState";
import $ from "jquery";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import URI from "urijs";
import { unstable_concurrentAct } from "react-dom/cjs/react-dom-test-utils.production.min";
import { url } from "../../env";
import MobileDetect from "mobile-detect";
import SwiperCore, {Autoplay} from 'swiper';
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import useUrlState from "@ahooksjs/use-url-state";
import {useUpdate} from "ahooks";
import {Link, useHistory, useLocation} from "react-router-dom";
import CopyMask from '../mask/CopyMask'
import Subtitle from "../components/Subtitle";
import HomeSearch from "../components/Home/HomeSearch";
import HomeBanner from "../components/Home/HomeBanner";
import HomeTopItem from "../components/Home/HomeTopItem";
import WantPlay from "../components/Home/WantPlay";
import Manager from "../components/Manager";
import AllSearch from "../components/Home/AllSearch";

const mobileDetect = new MobileDetect(navigator.userAgent);

const fixedMenus = [
  {
    id: 'card',
    text: '省钱卡',
    url: '/save?type=card',
    user: true,
  },
  {
    id: 'voucher',
    text: '免费领券',
    url: '/voucher',
    user: true,
  },
  {
    id: 'trade',
    text: '账号交易',
    url: '/trade',
    user: true,
  },
  // {
  //   id: 'server',
  //   text: '今日开服',
  //   url: '/server',
  //   user: false,
  // },
  /*{
    id: 'topic',
    text: '游戏圈子',
    url: '/topic',
    user: true,
  },*/
];
const swipers = [];
SwiperCore.use([Autoplay]);

export default (props) => {
  const {aplus_queue} = window;
  const history = useHistory();
  const location = useLocation();
  const update = useUpdate();
  // SwiperCore.use([Autoplay]);

  
  const [whether, setWhether] = useActiveState('whether');//是否进行过退出操作
  const [user] = useActiveState('user');
  const [config, setConfig] = useActiveState('config');
  const [agent] = useActiveState('agent');
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [banners, setBanners] = useLocalStorageState('good-banner', []);
  const [menus, setMenus] = useLocalStorageState('good-menu', fixedMenus);

  // const [datas, setDatas] = useLocalStorageState('good-data', []);
  // const [datas, setDatas] = useSessionStorageState('good-data', []);
  const [datas, setDatas] = useState([]);
  const [goodDatas, setGooDatas] = useLocalStorageState('good-data', []);//这俩是首页专题缓存的测试

  const [firstChargeState, setFirseChargeState] = useState(false); //首充弹框状态
  const [packageGiftState, setPackageGiftState] = useState(false); //代金券弹框状态
  const [noLoginPop, setNoLoginPop] = useState(false); //未登录弹框的状态
  const [isAccord, setIsAccord] = useState(false); //是否显示
  const [noLoginDatePop, setNoLoginDatePop] = useLocalStorageState('nologin-date-pop', []); //未登录每日的状态
  const [firstDatePop, setFirstDatePop] = useLocalStorageState('first-date-pop', []); //首充每日弹框状态
  const [packDatePop, setPackDatePop] = useLocalStorageState('pack-date-pop', []); //代金券每日弹框状态
  const [betweenEigthDay, setBetweenEigthDay] = useLocalStorageState('eigth-date-pop', []); //代金券超过30天标识
  const [indexActiveState, setIndexActiveState] = useState(false);
  const [isIndexActivityDatePop, setIsIndexActivityDatePop] = useLocalStorageState('index-activity', []); //活动的每日弹框状态
  const [isIndexNoticeDatePop, setIsIndexNoticeDatePop] = useLocalStorageState('index-notice', []); //活动的每日弹框状态
  const [downBoxState, setDownBoxState] = useState(true);
  const [copyright] = useActiveState('copyright');
  const [app] = useActiveState('app');
  const [activeInfo, setActiveInfo] = useActiveState('activeInfo');
  const [testConfig, setTestConfig] = useLocalStorageState('testConfig', []);

  const [newMenus, setNewMenus] = useState([]);
  const [newMenusCache, setNewMenusCache] = useLocalStorageState('new-good-menu', []);//导航缓存的两个字段
  const [copyPopState] = useActiveState('copyPopState');
  const [spweardRecord, setSpweardRecord] = useLocalStorageState('spweard-state', 0);//记录首页推广专题的弹框是否弹过
  const [spweardState, setSpweardState] = useState(false); //首页推广专题的弹框
  const [gamePopRecord, setGamePopRecord] = useLocalStorageState('gamepop-state', 0);//记录首页推广游戏的弹框是否弹过
  const [gamePopState, setGamePopState] = useState(false); //首页推广专题的弹框
  const [cqweardRecord, setCqweardRecord] = useLocalStorageState('cqweard-state', 0);//记录首页传奇是否跳转过
  const [bgameweardRecord, setBgameweardRecord] = useLocalStorageState('bgameweard-state', 0);//记录首页是否跳转过

  const [randomColorInfo] = useActiveState('randomColorInfo');
  let [mb, setMb] = '' || useActiveState('mb');
  const [infoTextColor, setInfoTextColor] = useState();
  const [alertPicCompletion, setAlertPicCompletion] = useState(false);
  const [likeTags] = useActiveState('likeTags');

  const load = () => {
    if (loading) {
      return;
    }
    if(Object.keys(newMenusCache).length > 0) {
      if (init){
        setLoading(true);
        Dialog.loading();
      }
    }else{
      setLoading(true);
      Dialog.loading();
    }
    general.axios.post('/getHomeData')
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setInit(true);
        setNewMenus(data.nav);
        setBanners([data.banner]);
        setDatas(data.list);

        setTimeout(()=>{
          setNewMenusCache(data.nav);
          setGooDatas(data.list);
        }, 5000);

        setInfoTextColor(randomColorInfo[parseInt(Math.random() * 4)]);
      })
  }

  useEffect(() => {
    if (props.active && !init) {
      load();
      noticeAlert();
    }
  }, [props.active]);

  useEffect(() => {
    if(whether == 'true') {
      load();
      noticeAlert();
      setWhether('false');
    }
  }, [user])
  useEffect(() => {
    if(whether == 'true' && init) {
      load();
    }
  }, [whether,init])
  useEffect(() => {
    load();
  }, [likeTags])
  const { pathname } = location;
  const scrollTo = () => {
    const scrollY = sessionStorage.getItem(pathname);
    if (scrollY) {
      const scrollResult = Number(scrollY) < 150 ? 0 : Number(scrollY);
      window.scrollTo(0, scrollResult);
    }
  };
  const scroll = useScroll();
  useLayoutEffect(() => {
    if(props.active) {
      scrollTo();
      if(Object.keys(newMenusCache).length > 0 && newMenus?.length == 0) {
        setNewMenus(newMenusCache);
      }

      if(Object.keys(goodDatas).length > 0 && datas?.length == 0) {
        setDatas(goodDatas);
      }
    }else{
      const top = scroll.top
      sessionStorage.setItem(pathname, String(top));
    }
  }, [props.active]);

  const [addMarks, setAddMarks] = useLocalStorageState('is-add-marks', 0);//添加书签统计标识

  useEffect(() => {
    if(props.active) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['HomeViewAppear', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), agent: agent}]
      });
      if(addMarks == 0 && window.navigator.standalone) {
        aplus_queue.push({
          action: 'aplus.record',
          arguments: ['NewUserOfBookmark', 'CLK', {agent:agent}]
        });
        setAddMarks(1);
      }
    }
  }, [props.active]);

  const {run: setOpacity} = useThrottleFn(value => props.setOpacity(value), {wait: 10});
  
  useEffect(() => {
    let opacity;
    if (props.active) {
      if (scroll.top) {
        opacity = scroll.top / 45;
        if (opacity > 1) {
          opacity = 1;
        }
      } else {
        opacity = 0;
      }
    }
    setOpacity(opacity);

    if (props.active && init) {
      if (scroll.top < -80) {
        load();
      }
    }
  }, [scroll, props.active, init]);

  const [swiper, setSwiper] = useState(null);
  useEffect(() => {
    swiper && swiper.update();
  }, [props.active, swiper, banners]);

  const [banner, setBanner] = useState(null);
  const menuRef = useRef(null);
  useEffect(() => {
    if (menuRef.current && props.active && !init) {
      setTimeout(() => {
        $(menuRef.current).animate({scrollLeft: menuRef.current.offsetWidth}, 1000, null, () => {
          $(menuRef.current).animate({scrollLeft: 0}, 1000);
        });
      }, 250);
    }
  }, [props.active, init]);
  const menuScroll = useScroll(menuRef);
  const scrollbarRef = useRef(null);

  useEffect(() => {
    if (menuRef.current && scrollbarRef.current) {

      $(scrollbarRef.current).css({left: (menuScroll.left + $(menuRef.current).width() / 2) / menuRef.current.scrollWidth * 100 + '%'}); //原来的
      $(scrollbarRef.current).css({width: '50%'});//原来的

      // $(scrollbarRef.current).css({left: 25 * ((menuScroll.left / (menuRef.current.scrollWidth / newMenus.length)) + 1) + '%'});
      // $(scrollbarRef.current).css({width: $(menuRef.current).width() / menuRef.current.scrollWidth * 100 + '%'});
    }
  }, [menuScroll, init]);

  useEffect(() => {
    props.active && swipers.forEach(swiper => swiper.update());
  }, [props.active, datas]);

  const closePop = useLockFn(() => {
    Manager.push({event_id:"B-8"})
    let asIs = user.member_id ?? localStorage.getItem('user_id');
    let today = asIs + moment().format('YYYY-MM-DD');

    if(indexActiveState) {
      let active_day = 'active_' + today;
      setIsIndexActivityDatePop(active_day);
      setIndexActiveState(false);

      ClickPopUpAds();
    }

    //代金券弹框
    if(packageGiftState) {
      let pack_day = 'pack_' + today;
      setPackDatePop(pack_day);
      setPackageGiftState(false);
      // setFirstDatePop(null);
      // setFirseChargeState(false);
      user.is_show_reg_between_8day_30day ? setBetweenEigthDay(1) : null;
    }
    
    //送首充弹框
    if(firstChargeState) {
      let first_day = 'first_' + today;
      setFirstDatePop(first_day);
      setFirseChargeState(false)
    }
    // console.log(noLoginPop);
    //未登录时的弹框
    if(noLoginPop) {
      let nologin_day = 'nologin_' + moment().format('YYYY-MM-DD');
      setNoLoginDatePop(nologin_day);
      setNoLoginPop(false);
    }
  })
  // console.log(isIndexActivityDatePop);
  const judge = useLockFn(() => {
  // const judge = () => {
    let asIs = user.member_id ?? localStorage.getItem('user_id');
    // if((Object.keys(user).length > 1 || localStorage.getItem('username')) && isIndexActivityDatePop !== 'active_' + asIs + moment().format('YYYY-MM-DD') && ( ((mobileDetect.is('iPhone') || mobileDetect.is('iPad')) && window.navigator.standalone) || (!mobileDetect.is('iPhone') && !mobileDetect.is('iPad') ) ) ) {
    if(isIndexActivityDatePop !== 'active_' + asIs + moment().format('YYYY-MM-DD') && ( ((mobileDetect.is('iPhone') || mobileDetect.is('iPad')) && window.navigator.standalone) || (!mobileDetect.is('iPhone') && !mobileDetect.is('iPad') ) ) ) {
      if(config.is_activity == '1') {
        setIndexActiveState(true);

        aplus_queue.push({
          action: 'aplus.record',
          arguments: ['ShowPop-upAds', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), activename:activeInfo.name, agent: agent}]
        });
      } else {
        setIndexActiveState(false);
      }
    }

    if ((Object.keys(user).length > 0 || localStorage.getItem('username')) && indexActiveState == false && packDatePop !== 'pack_' + asIs + moment().format('YYYY-MM-DD') && ( ((mobileDetect.is('iPhone') || mobileDetect.is('iPad')) && window.navigator.standalone) || (!mobileDetect.is('iPhone') && !mobileDetect.is('iPad') ) ) ) {
      if(user.novice_fuli_v2101_show || user.is_show_reg_between_8day_30day) {
        // setPackageGiftState(true);
      } else if (user.is_show_reg_gt_30day == true) {
        if(betweenEigthDay !== '1') {
          // setPackageGiftState(true);
        }
      }
    } else if ((Object.keys(user).length > 0 || localStorage.getItem('username')) && firstDatePop !== 'first_' + asIs + moment().format('YYYY-MM-DD') && packageGiftState == false && indexActiveState == false && ( ( (mobileDetect.is('iPhone') || mobileDetect.is('iPad')) && window.navigator.standalone) || (!mobileDetect.is('iPhone') && !mobileDetect.is('iPad') ) ) ){
      if(user.is_finish_newtask) {
        setFirseChargeState(false);
      } else {
        if(testConfig.is_activity != '1') {
          setFirseChargeState(true); 
        }
      }
    }
    // if(user.isLogin === false && window.navigator.standalone && noLoginDatePop !== 'nologin_' + user.member_id + moment().format('YYYY-MM-DD')) {
    if((Object.keys(user).length === 0) && noLoginDatePop !== 'nologin_' + moment().format('YYYY-MM-DD') && (window.navigator.standalone || mobileDetect.os() == 'AndroidOS') && packageGiftState == false && indexActiveState == false && firstChargeState == false) {
      setNoLoginPop(true);
    }
  })
  
  const active_load = () => {
    general.axios.post('/base/activity/getActivity')
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }

        setActiveInfo(data);
        let asIs = user.member_id ?? localStorage.getItem('user_id');
        if (!indexActiveState && isIndexActivityDatePop != 'active_' + asIs + moment().format('YYYY-MM-DD') && (window.navigator.standalone || mobileDetect.os() == 'AndroidOS')){
        // if ((Object.keys(user).length > 1 || localStorage.getItem('username')) && !indexActiveState && isIndexActivityDatePop != 'active_' + asIs + moment().format('YYYY-MM-DD') && (window.navigator.standalone || mobileDetect.os() == 'AndroidOS')){
          setIndexActiveState(true);
        }
        // setPackageGiftState(false);
        // setFirseChargeState(false);
        // setNoLoginPop(false);
        
        setTimeout(() =>{setIsAccord(true)}, 1000);
        setTimeout(() =>{judge()}, 1000);
      })
      .catch(err => {
        
      });
  }
// console.log(isAccord);
  const qq_config = () => {
    general.axios.post('/base/common/getConfig')
      .then(res => {
        let {status, data} = res.data;
        if (status.succeed) {
          setTestConfig({...testConfig, ...data});
          if(data.is_activity == '1') {
            active_load();
          } else {
            setTimeout(()=>{setIsAccord(true)}, 1000);
            setTimeout(() =>{judge()}, 1000);
          }
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      })
  }

  // useEffect(() => {
  //   qq_config()
  // }, [app, user.member_id, props.active, config.is_activity]);

  useEffect(() => {
    if(config.is_activity == '1' && likeTags == '1') {
      active_load();
    }
    // qq_config()
  }, [config.is_activity, props.active, likeTags]);

  const [browserState, setBrowserState] = useState(false);
  const [browserUrlText, setBrowserUrlText] = useState();
  const [browserCopys, setBrowserCopys] = useState(false);
  const [isUnfreeze, setIsUnfreeze] = useState(true);//copy状态
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});

  //阻止滑动
  const ref = useCallback(node => {
    if (node) {
      node.addEventListener('touchmove', e => {
        e.preventDefault();
      }, {passive: false});
    }
  }, []);

  const verdict = () => {
    // let url = "https://" + window.location.host + "/#/";
    // setBrowserUrlText(url);
    if(mobileDetect.is('iPhone') || mobileDetect.is('iPad')) {
      if(mobileDetect.userAgents() != 'Safari') {
        if(copyPopState == false) {
          // let url = "https://" + window.location.host + "/#/";
          let url = window.location.href;
          setBrowserUrlText(url);
          setBrowserState(true);
        }
      }
    }
  }

  useEffect(()=>{
    general.axios.post('/base/newindex/getAgentState', {agent:agent})
    .then(res => {
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }
      
      setIsUnfreeze(data);

      if(data == true) {
        setBrowserState(false);
        setBrowserCopys(false);
      } else {
        // verdict();
        if(mobileDetect.is('iPhone') || mobileDetect.is('iPad')) {
          if(mobileDetect.userAgents() != 'Safari') {
            if(data == false) {
              let url = "https://" + window.location.host + "/#/";
              if(window.location.host == 'webbox.99maiyou.cn') {
                if(params.agent) {
                  url = window.location.href;
                } else {
                  url = window.location.href + "&agent=" + agent;
                }
              }
              if (mb != 1){
                setBrowserUrlText(url);
                setBrowserState(true);
              }
            }
          }
        }

      }
    })
    .catch(err => {
      Dialog.error(err.message);
    });

  }, [agent]);

  const [tradesStatePop, setTradesStatePop] = useState(false);
  const [tradesReject, setTradesReject] = useState({});

  const [tradeGamePop, setTradeGamePop] = useState(false);
  const [tradeNotice, setTradeNotice] = useState([]);

  const [alertList, setAlertList] = useState([]);
  const [alertInit, setAlertInit] = useState(false);

  const [bottomGame, setBottomGame] = useLocalStorageState('bottom-banner', []);
  const [bottomBannerState, setBottomBannerState] = useState(false);
  // const [recommendTop, setRecommendTop] = useLocalStorageState('recommend-top', []);
  const [recommendTop, setRecommendTop] = useState({});
  const [popularGame, setPopularGame] = useState([]);
  
  useEffect(() => {

    if (!props.active) {
      return;
    }

    //新加start -2023042701
    if(!general.isLogin()) {
      // setPopularGame([]);
      setBottomGame([]);
      setBottomBannerState(false);
    }
    //新加end -2023042701

    if(props.active && !alertInit && (window.navigator.standalone || mobileDetect.os() == 'AndroidOS')) {
    // if(props.active && !alertInit) {
      noticeAlert();
    }
  }, [props.active, alertInit]);

  useEffect(() => {

    let asIs = user.member_id ?? localStorage.getItem('user_id');
    let today = asIs + moment().format('YYYY-MM-DD');
    let notice_day = 'notice_' + today;

    // if(isIndexNoticeDatePop !== notice_day && (window.navigator.standalone || mobileDetect.os() == 'AndroidOS')) {

    // if(!alertInit && (window.navigator.standalone || mobileDetect.os() == 'AndroidOS')) {
    //   noticeAlert();
    // }
  }, [user]);

  const noticeAlert = () => {
    let asIs = user.member_id ?? localStorage.getItem('user_id');
    let today = asIs + moment().format('YYYY-MM-DD');
    let notice_day = 'notice_' + today;

    general.axios.post('/base/common/notice')
    .then(res => {
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }

      if(data.alertList.length > 0) {
        let alertlist = data.alertList.map(item => ({...item, display: true}));
        setAlertList(alertlist);
      }
      
      setAlertInit(true);

      if(general.isLogin()) {

        let asIs = user.member_id ?? localStorage.getItem('user_id');
        let today = asIs + moment().format('YYYY-MM-DD');
        let notice_day = 'notice_' + today;

        setIsIndexNoticeDatePop(notice_day);
      }

      //横幅活动
      let popular = data.popularGame ? (data.popularGame.activity_top.length == 0 ? '' : data.popularGame.activity_top) : '';
      setPopularGame(popular);

      if(general.isLogin()) {
        if(mobileDetect.os() != 'AndroidOS') {
          setBottomGame(data.popularGame ? (data.popularGame.bottom_banner ? data.popularGame.bottom_banner : '') : '')
          setBottomBannerState(true);
        }
      }

      // console.log(data.popularGame.recommend_top);
      //重磅推荐
      if(general.isLogin()) {
        setRecommendTop(data.popularGame ? (data.popularGame.recommend_top ? data.popularGame.recommend_top : '') : '');
      }
      
      if(data.reject && Object.keys(data.reject).length > 0) {
        if(data.reject.type) {
          setTradesReject(data.reject);
          setTradesStatePop(true);
        }
      }
    })
  }

  const closeTradePop = () => {
    setTradeGamePop(false);

    let asIs = user.member_id ?? localStorage.getItem('user_id');
    let today = asIs + moment().format('YYYY-MM-DD');
    let notice_day = 'notice_' + today;

    setIsIndexNoticeDatePop(notice_day);
  }

  const closeNewTradePop = item => {
    item.display = false;
    update()
  }

  useEffect(() => {
    if(spweardRecord == 0) {
      if(params.zt && params.zu && (window.navigator.standalone || mobileDetect.os() == 'AndroidOS')) {
        setSpweardRecord(1);
        setSpweardState(true);
      }
    }

  }, [params.zt, params.zu]);

  useEffect(() => {
    if(cqweardRecord == 0) {
      if(params.ct && params.cu && (window.navigator.standalone || mobileDetect.os() == 'AndroidOS')) {
        setCqweardRecord(1);
        redux.dispatch({
          type: 'type',
          data: {
            screenRouter: {
              screen: '/game',
              type: 0,
              tag: params.cu,
            },
          }
        });
      }
    }

  }, [params.ct, params.cu]);
  
  useEffect(() => {
    if(window.navigator.standalone === true && bgameweardRecord == 0) {
      if(init && params.b) {
          setTimeout(() => {
            setBgameweardRecord(1);
            history.push('/game?id=' + params.b + '&indexsource=index');
          }, 1000);
        }
      }

  }, [params.b, init]);

  //首页广告位 点击埋点
  const pageActivityBanner = (e) => {
    let active_name = '';
    if(e.type === 'inner_web' || e.type === 'onner_web') {
      active_name = e.title;
    }
    Manager.push({event_id:e.event_id})
  }

  //首页活动弹框点击埋点
  const ClickPopUpAds = () => {
    
  }

  //首页专题游戏点击埋点
  const clickTheGameInTheTopic = (item) => {
    Manager.push({event_id:item.event_id})
  }

  //首页专题详情埋点
  const clickTheTopicToSeeMore = (item) => {
    Manager.push({event_id:item.event_id})
  }

  const newoper = e => {
    if(e.jumpType == 'outer_web') {
      window.open(e.value, "_blank");
    } else if(e.jumpType == 'inner_web') {
      let url = URI(e.value)
        .addQuery('username', localStorage.getItem('username'))
        .addQuery('token', localStorage.getItem('token'))
        .addQuery('location_url', window.location.origin)
        .addQuery('origin', 'react')
        .toString();
      window.open(url, "_blank");
    } else {

    }
  }

  const bottomOper = e => {
    if(e.is_check_login) {
      if(!general.isLogin()) {
        PubSub.publish('alert-login',true)
        return;
      }
    }
    if(e.type == 'work_weixin') {
      window.open(e.value, "_blank");
    } else if(e.type == 'game_info') {
      history.push('/game?id='+e.value);
    } else if(e.type == 'special') {
      history.push('/game/project?id='+e.value);
    } else if(e.type == 'news_info') {
      history.push('/message/detail?id='+e.value);
    } else if(e.type == 'inner_web' || e.type == 'outer_web') {
      window.open(e.value + "?username="+localStorage.getItem('username') + "&token="+localStorage.getItem('token'), "_blank");
    }
  }

  useEffect(()=>{
    //活动跳转新的平台币
    window.addEventListener('message', function(event) {
      var result = event.data;
      if(result?.type) {
        if(result.type == 'nisptb') {//跳转平台币
          let url = URI("https://sys.wakaifu.com/home/MixPay/ptb")
                .addQuery('username', localStorage.getItem('username'))
                .addQuery('token', localStorage.getItem('token'))
                .addQuery('location_url', window.location.origin)
                .addQuery('agent', agent)
                .addQuery('from', 'react')
                .toString();
          
          // let url = "https://sys.wakaifu.com/home/MixPay/ptb?username="+localStorage.getItem('username')+"&token="+localStorage.getItem('token')+"&location_url=" + window.location.origin +"&agent="+agent+"&from=react&title=充值";
          window.location.href = '#/iframe?url=' + encodeURIComponent(url) + '&title=充值&sinfo=nisptb';
        } else if(result.type == 'flexible') {//活动跳转
          let url = URI(result.active_url)
                .addQuery('username', localStorage.getItem('username'))
                .addQuery('token', localStorage.getItem('token'))
                .addQuery('location_url', window.location.origin)
                .addQuery('agent', agent)
                .addQuery('from', 'react')
                .toString();

          window.location.href = '#/iframe?url=' + encodeURIComponent(url) + '&title=' + result.active_title;
        }
      }
    });
  });

  return <div className="index-good">
  
    <div className="">
      <div className="index-allban">
        <HomeSearch></HomeSearch>
        {banners.length > 0 && <HomeBanner banner={banners[0]}></HomeBanner>}
        {newMenus.length > 0 && <HomeTopItem newMenus={newMenus}></HomeTopItem>}
      </div>
    </div>

    {/** 横幅广告 **/}
    {popularGame.length > 0 ?
    <div className="index-popularGame">
      <Swiper onSwiper={swiper => swipers.push(swiper)} 
          initialSlide={0} //定位从第几个slider开始轮播
          slidesPerView={'auto'}
          // loop={true}
          spaceBetween={15}
          loopedSlides={1}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          loop={popularGame.length > 1 ? true : false} 
        >
        {popularGame.map((popular,index) => <SwiperSlide key={index}>
          {popular.type === 'inner_web' || popular.type === 'onner_web' || popular.type === 'rechargePtb' ?
          <NavLink to={(() => {
            let url = URI(popular.value)
                .addQuery('username', localStorage.getItem('username'))
                .addQuery('token', localStorage.getItem('token'))
                .addQuery('location_url', window.location.origin)
                .addQuery('origin', 'react')
                .toString();
              return url;
            })()}
            iframe={{title: popular.title}}
            className="popular"
            user={popular.is_check_login == true?true:false}
            onClick={()=>pageActivityBanner(popular)}
          >
            {popular.icon?.length > 0 && <img className="small-pic" src={popular.icon} alt="" />}
            <img style={{width:'calc(100% - 2px)'}} src={popular.img?popular.img:'/resources/placeholder/index-active.png'} alt=""/>
          </NavLink>
          : popular.type === 'work_weixin' || popular.type === 'outer_web' ? 
          <div className="popular" onClick={()=>{window.open(popular.value, "_blank");}}>
            {popular.icon?.length > 0 && <img className="small-pic" src={popular.icon} alt="" />}
            <img style={{width:'calc(100% - 2px)'}} src={popular.img?popular.img:'/resources/placeholder/index-active.png'} alt=""/>
          </div>
          : <Link to={(() => {
            if(popular.type === 'special' || popular.type === 'project' || popular.type === 'gameProject') {
              return '/game/project?id=' + popular.value.id;
            }
            if(popular.type === 'news_info') {
              return '/message/detail?id=' + popular.value;
            }
            if(popular.type === 'game_info' || popular.type === 'game') {
              return '/game?id=' + popular.value;
            }
            if(popular.type === 'invite') {
              return config?.inviteFriendsType == 2 ? "/newinvite" : "/invite";
            }
            if(popular.type === 'rechargeVip') {
              return "/save/newvip";
            }
            if(popular.type === 'moneySavingCard') {
              return "/save/newcard";
            }
            if(popular.type == 'vouchandgift') {
              return '/giftproject'
            }
            if(popular.type == 'signgift') {
              return '/signgift'
            }

//             game、game_info：游戏详情
// news_info：新闻咨询什么的
// special、project、gameProject：游戏专题
// invite：邀请好友
// activity：活动
// rechargeVip：会员
// moneySavingCard：省钱卡
// rechargePtb：平台币
          })()}
          onClick={()=>pageActivityBanner(popular)}
          ><img src={popular.img?popular.img:'/resources/placeholder/index-active.png'} alt=""/></Link>
          }
        </SwiperSlide>)}
      </Swiper>
    </div>
    : null}

    {/** 重磅推荐 **/}
    {
      popularGame.length == 0 && Object.keys(recommendTop).length > 0 ? <div className="index-recommend-top" style={{backgroundImage:'url(/resources/index/recomment_top_banner.png)'}}>
        <Link to={(()=>{
          if(recommendTop.type == 'game_info') {
            return '/game?id=' + recommendTop.value;
          } else if(recommendTop.type == 'news_info') {
            return '/message/detail?id=' + recommendTop.value;
          }
        })()} className="item">
          <div className="tips">
            重磅推荐
          </div>
          <div className="icon"><Image src={recommendTop.img}/></div>
          <div className="text">
            <div className="name">
              {recommendTop.title}
            </div>
            <div className="info1">
              {recommendTop.info}
            </div>
          </div>
          <div className="operate">
            立即下载
          </div>
        </Link>
      </div>
      : null
    }

    {datas.map((item,index) => <div key={index} className={'index-data ' + item.tag}>
    {item.tag !== 'specialBanner' && item.tag !== 'hotSearchWord' && item.tag !== 'guessYouLike' && item.tag !== 'videoList' && item.tag !== 'agame' && item.outstyle != 3
        ? <div className={'data-head ' + item.tag}>
          <div className="text"><img src={item.title_image} /></div>
          {item.tag === 'projectGames' ? <Link
            onClick={()=>Manager.push({event_id:item.event_id})}
            to={(() => {
              if(item.style == 6) {
                return '/party/beta?id=' + item.id
              } else {
                if (item.jump_type === 'extraLeak') {
                  return `/index/trade?title=${item.title}`;
                } else {
                  return '/game/project?id=' + item.id;
                }
              }
            })()} 
            className="more">更多<img src="/resources/welfare/left-back.png" className="left-back"/></Link> : null}
          {item.tag === 'startingGames' ? <Link onClick={()=>Manager.push({event_id:item.event_id})} to="/game/today-start" className="more">更多<img src="/resources/welfare/left-back.png" className="left-back"/></Link> : null}
          {item.tag === 'reserverGames' ? <Link onClick={()=>Manager.push({event_id:item.event_id})} to="/game/reserve" className="more">更多<img src="/resources/welfare/left-back.png" className="left-back"/></Link> : null}
          {item.tag === 'diycategory' ? <Link onClick={()=>Manager.push({event_id:item.event_id})} to={'/game/project?id=' + item.id} className="more">更多<img src="/resources/welfare/left-back.png" className="left-back"/></Link> : null}
          {/* {item.tag === 'diycategory' ? <div onClick={() => {
            redux.dispatch({
              type: 'type',
              data: {
                screenRouter: {
                  screen: '/game',
                  type: 0,
                  tag: item.target_game_cate,
                },
              }
            });
          }} className="more">更多></div> : null} */}
        </div>
        : null}
        {item.outstyle != 3 && item.tag !== 'reserverGames' && item.tag !== 'agame' && item.tag !== 'guessYouLike'
        ?
        <div className="tips">
            {item.tips}
        </div>
        :null}

      <div className={'data-body ' + item.tag}>
        {(() => {
          if (item.tag === 'recommendGames') {
            return <div className="items">
              {item.list?.map((game,index) => <Link key={index} to={'/game?id=' + game.game_id} className="item" onClick={()=>clickTheGameInTheTopic(item)}>
                <div className="icon">
                  <Image src={game.game_image.thumb}/>
                </div>
                <div className="name">{game.game_name}</div>
                <div className="info">{game.game_classify_type.trim().replace(' ',' · ')}</div>
              </Link>)}
            </div>
          } else if (item.tag === 'projectGames') {
            if(item.outstyle == '2' && item.list?.length > 0) {
              return <div className="items">
                {[item.list[0], item.list[1], item.list[2], item.list[3]].map((game,index) => 
                  <>
                  {game ?
                    <Link key={index} className="item" to={(()=>{
                      //跳转类型
                      if(item.jump_type == 'tradeGoods') {
                        return '/trade/good?source=czjl';
                      } else if(item.jump_type == 'extraLeak') {
                        return `/index/trade?title=${item.title}`;
                      } else {
                        return '/game?id=' + game.game_id;
                      }
                    })} 
                    onClick={()=>clickTheGameInTheTopic(item)}
                    >
                    {/*  to={'/game?id=' + game.game_id}> */}
                      <div className="icon">
                        <Image src={game.game_image.thumb} />
                        {game.discount < 1 ? <div className="type_label"><div><span>{game.discount * 10}折</span></div></div> : null}
                      </div>
                      <div className="name">{game.game_name}</div>
                      <div className="info">{game.game_classify_type.trim()}</div>
                      {/* {
                        game.nameRemark ?
                        <div className="nameRemark">
                          <span>
                          {game.nameRemark}
                          </span>
                          
                        </div>
                        : null
                      } */}
                
                    </Link>
                  : null}
                  </>
                )}
              </div>
            } else if(item.outstyle == '3') {
              return <div className="">
                {[item.game_info]?.map((game,index) => <div key={index} className={'project-item-third'}><Link 
                  to={(()=>{
                    //跳转类型
                    if(item.jump_type == 'tradeGoods') {
                      return '/trade/good?source=czjl';
                    } else if(item.jump_type == 'extraLeak') {
                      return `/index/trade?title=${item.title}`;
                    } else {
                      return '/game?id=' + game.game_id;
                    }
                  })}
                  onClick={()=>clickTheGameInTheTopic(item)}
                  >
                  {/*to={'/game?id=' + game.game_id} >*/}
                    <div className="item-base">
                      <div className="icon"><Image src={game.game_image.thumb}/></div>
                      <div className="text">
                        <div className="name">
                          {game.game_name}
                        </div>
                        <div className="info1">
                          {/* <span className="nameRemark">{game?.nameRemark}</span> */}
                          {game.game_classify_type.trim()}
                          {/* {
                            game.nameRemark ?
                            <>{game.game_classify_type.replace(/(^\s*)|(\s*$)/g, "").replace(/\s+/g, "&")}</>
                            :
                            <>{game.game_classify_type.trim()}</>
                          } */}
                          {game.game_species_type != 3 ? <>&nbsp;|&nbsp;{game.howManyPlay}人在玩</> : null}
                        </div>
                        <div className="info2">
                          {game.game_desc ? game.game_desc.split('+').map((item,index) => <div key={index}
                            className="tag">{item}</div>) : <span style={{color:infoTextColor}}>{game.introduction}</span>}
                        </div>
                      </div>
                    </div>
                    <div className="item-img"><Image src={item.top_image} alt="" className="project-img-four"/></div>
                    <div className="item-desc">{item.tips}</div>
                  </Link>
                </div>)}
              </div>
            } else if(item.style == '6') {
              return <Link 
              to={"/party/beta?id=" + item.id} onClick={()=>clickTheTopicToSeeMore(item)}>
                <Image src={item.top_image} alt="" className="project-img-four"/>
              </Link>
            } else {
              return <Link 
              to={(()=>{
                //跳转类型
                if(item.jump_type == 'tradeGoods') {
                  return '/trade/good?source=czjl';
                } else if(item.jump_type == 'extraLeak') {
                  return `/index/trade?title=${item.title}`;
                } else {
                  return '/game/project?id=' + item.id;
                }
              })}
              onClick={()=>clickTheTopicToSeeMore(item)}
              >
                <Image src={item.top_image} alt="" className="project-img-four"/>
              </Link>
            }
          } else if (item.tag === 'hotGames') {
            return <div className="">
              {(() => {
                return item.list?.map((game, index) => <Link key={index} to={'/game?id=' + game.game_id} className="item" onClick={()=>clickTheGameInTheTopic(item)}>
                    <div className="entity">
                      <div className="icon">
                        <Image src={game.game_image.thumb}/>
                        {
                          game?.lablePosition == 2 && game.top_lable != '' ?
                          <div className="iconlable jx" style={{backgroundImage: 'linear-gradient('+game?.lableColor.t+', '+game?.lableColor.b+')'}}>
                            {game.top_lable}
                          </div>
                          : null
                        }
                      </div>
                      <div className="text">
                        <div className="name">
                          {/* {game.top_lable != '' ? <div className="span">{game.top_lable }</div> : null } */}
                          <div className={" " + (game.nameRemark ? " name-new" : "")}>
                            {game?.lablePosition == 1 && game.top_lable != '' ? <div className="span">{game.top_lable}</div> : null }
                            {game.game_name}
                          </div>
                        </div>
                        <div className="info1">
                          {/* <span className="nameRemark">{game?.nameRemark}&nbsp;</span> */}
                          {game.game_classify_type.trim()}
                          <Subtitle>{game.nameRemark}</Subtitle>
                          {/* {
                            game.nameRemark ?
                            <>{game.game_classify_type.replace(/(^\s*)|(\s*$)/g, "").replace(/\s+/g, "&")}</>
                            :
                            <>{game.game_classify_type.trim()}</>
                          } */}
                          {/* {game.game_classify_type.replace(/(^\s*)|(\s*$)/g, "").replace(/\s+/g, "&")} */}
                          {/* {!game.nameRemark && game.game_species_type != 3 ? <>&nbsp;|&nbsp;{game.howManyPlay}人在玩</> : null} */}
                          {/* {game.game_species_type != 3 ? <>&nbsp;·&nbsp;{game.howManyPlay}人在玩</> : null} */}
                        </div>
                        {/* <div className="info2">
                          {game.game_desc ? game.game_desc.split('+').map(item => <div className="tag">{item}</div>) : <span style={{color:infoTextColor}}>{game.introduction}</span>}
                        </div> */}
                        <div className="info3">
                        {
                          game.bottom_lable ? 
                          <>
                          {Object.keys(game.bottom_lable).length > 0 ? 
                          <div >
                            <span className="first-text" style={{background:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.left_content}</span><span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
                          </div> : null }
                          </>
                          : null
                        }
                        {game.exclusive_game_describe?.length > 0 ? <div className="speed">{game.exclusive_game_describe}</div> : null}
                        {game.is_support_accele ? <div className="speed">可加速</div> : null}
                        {game.is_easy_play ? <div className="speed">省心玩</div> : null}
                        {Object.keys(game.bottom_lable).length == 0 && !game.is_support_accele && !game.is_easy_play && game.introduction && game.exclusive_game_describe?.length == 0 ? <span style={{color:'#888'}}>{game.introduction}</span> : null}                        
                        </div>

                        {/* {
                        Object.keys(game.bottom_lable) && Object.keys(game.bottom_lable).length > 0 ?
                        <div className="info3">
                            <span className="first-text" style={{background:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.left_content}</span><span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
                        </div>
                        :null
                        } */}
                        {game.discount < 1 && game.goodGame?.length == 0 ? <div className="discount" ><span>{game.discount * 10}</span>折</div> : null}
                      </div>
                      {game.goodGame?.length > 0 ? <div className="goodGame">
                        <img className="goodgame-icon" src="resources/index/left.png" alt="" />
                        <div className="goodgame-content">
                          <div className="goodgame-title">
                            <div className="goodgame-name">{game.discount * 10}</div>
                            <div className="goodgame-desc">折</div>
                          </div>
                          <div className="goodgame-text">{game.goodGame}</div>
                        </div>
                        <img className="goodgame-icon" src="resources/index/right.png" alt="" />
                      </div> : null}
                    </div>

                    {(() => {
                      if(item.theme_style == 'all_image') {
                        if(game?.banner_url) {
                          return <div className="entity-img">
                            <img src={game?.banner_url} />
                          </div>
                        }
                      } else if (item.theme_style == 'first_image') {
                        if(index == 0) {
                          return <div className="entity-img">
                            <img src={game?.banner_url} />
                          </div>
                        }
                      }
                    })()}
                  </Link>);
              })()}
            </div>
          } else if (item.tag === 'specialBanner') {
            return <div className="items clearfix">
              {item.list?.map((item,index) => <NavLink
                key={index}
                className="item"
                to={(() => {
                  if (item.type === 'h5') {
                    return item.param;
                  }
                })()}
                iframe={item.type === 'h5' ? {} : false}
                user={item.type === 'h5' && item.is_verification_login}
              >
                <Image src={item.banner_img} alt=""/>
              </NavLink>)}
            </div>
          } else if (item.tag === 'reserverGames') {
            {/*return <Swiper onSwiper={swiper => swipers.push(swiper)}>
              {item.reserverGameslist.map(game => <SwiperSlide>
                <Link to={'/game?id=' + game.game_id} className="item">
                  <div className="img" style={{backgroundImage: 'url(' + game.video_img_url + ')'}}/>
                  <div className="container">
                    <div className="icon"><Image src={game.game_image.thumb}/></div>
                    <div className="text">
                      <div className="name">{game.game_name}</div>
                      <div className="time">{moment(game.starting_time * 1000).format('MM月DD日HH:mm')}首发</div>
                    </div>
                    <div className="btn">查看</div>
                  </div>
                </Link>
              </SwiperSlide>)}
            </Swiper>*/}
            return <Swiper onSwiper={swiper => swipers.push(swiper)}>
              {item.list?.map((game,index) => <SwiperSlide key={index}>
                <Link to={'/game?id=' + game.game_id} className="item" onClick={()=>clickTheGameInTheTopic(item)}>
                  {/* <div className="img" style={{backgroundImage: 'url(' + game.video_img_url + ')'}}/> */}
                  <Image src={game.video_img_url} style={{borderRadius:'12px'}}/>
                  <div className="container">
                    <div className="icon"><Image src={game.game_image.thumb}/></div>
                    <div className="text">
                      <div className="name">
                        {/* {game.game_name} */}
                        <div className={" " + (game.nameRemark ? " name-new" : "")}>
                          {game.game_name}
                        </div>
                        {game.nameRemark ? <div className="nameRemark-new" style={{clear: 'both'}}>{game.nameRemark}</div> : <div ></div>}
                      </div>
                      <div className="info1">
                        {/* {
                          game.nameRemark ?
                          <span className="nameRemark">
                            {game?.nameRemark}&nbsp;
                          </span>
                          : null
                        } */}
                        {/* {game.game_classify_type.trim()} */}
                        {/* {
                          game.nameRemark ?
                          <>{game.game_classify_type.replace(/(^\s*)|(\s*$)/g, "").replace(/\s+/g, "&")}</>
                          :
                          ""
                        } */}
                      </div>
                      <div className="time">
                        {moment(game.starting_time * 1000).format('MM月DD日 HH:mm')}首发
                      </div>
                      <div className="info3">
                        {game.exclusive_game_describe?.length > 0 ? <div className="speed">{game.exclusive_game_describe}</div> : null}
                        {game.is_support_accele ? <div className="speed">可加速</div> : null}
                        {game.is_easy_play ? <div className="speed">省心玩</div> : null}
                        {game.bottom_lable && Object.keys(game.bottom_lable).length == 0 && !game.is_support_accele && !game.is_easy_play && game.introduction && game.exclusive_game_describe?.length == 0 ? <span style={{color:'#888'}}>{game.introduction}</span> : null}                        
                      </div>
                    </div>
                    <div className="btn">查看</div>
                  </div>
                </Link>
              </SwiperSlide>)}
            </Swiper>
          } else if (item.tag === 'agame') {
            return <Link className="item" to={"/game?id="+item?.list[0]?.game_id}>
              <div className="entity-img">
                <img src={item?.list[0]?.banner_url} />
                <div className="img-tips" style={{backgroundImage: 'linear-gradient(#f0ebeb1f, '+item.bannerBgColor+' 80%, '+item.bannerBgColor+')'}}>
                  {/* 真开局送绝版龙魂七彩祥瑞！ */}
                  {item?.tips}
                </div>
              </div>
              <div className="entity-dividing" style={{backgroundColor: ' ' + item.bannerBgColor +' '}}>
                <div className="dividing-line">
                  <img src="/resources/index/dividing-line.png" />
                </div>
                <div className="entity">
                  <div className="icon"><Image src={item?.list[0]?.game_image.thumb}/></div>
                  <div className="text">
                    <div className="name">
                      <div className={" " + (item?.list[0]?.nameRemark ? " name-new" : "")}>
                        {item?.list[0]?.game_name}
                      </div>
                      {item?.list[0]?.nameRemark ? <div className="nameRemark-new" style={{clear: 'both'}}>{item?.list[0]?.nameRemark}</div> : <div ></div>}
                    </div>
                    <div className="info1">
                      {item?.list[0]?.game_classify_type}
                      {item?.list[0]?.game_species_type != 3 ? <>&nbsp;|&nbsp;{item?.list[0]?.howManyPlay}人在玩</> : null}
                    </div>
                    <div className="info2">
                      {item?.list[0]?.game_desc ? item.list[0].game_desc.split('+').map((item,index) => <div key={index} className="tag">{item}</div>) : <span style={{color:infoTextColor}}>{item?.list[0]?.introduction}</span>}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          } else if (item.tag === 'diycategory') {
            item.hide = item.list?.length === 0;
            return <div className="items ">
              {item.list?.map((game, index) => index < 6 ?
                <Link key={index} to={'/game?id=' + game.game_id} className={"item" + (index == 3 ? ' clear' : '') } onClick={()=>clickTheGameInTheTopic(item)}>
                  <div className="icon">
                    <Image src={game.game_image.thumb}/>
                  </div>
                  <div className="name">{game.game_name}</div>
                  <div className="info1">{game.game_classify_type.trim().replace(' ',' · ')}</div>
                </Link> : null)}
            </div>
          } else if (item.tag === 'hotCategory') {
            return <div className="items clearfix">
              {item.list?.map((item,index) => <div key={index} className="item">
                <div className="container" onClick={() => {
                  Manager.push({event_id: item.event_id})
                  redux.dispatch({
                    type: 'type',
                    data: {
                      screenRouter: {
                        screen: '/game',
                        type: 0,
                        tag: item.game_classify_id,
                      },
                    }
                  });
                }}>{item.game_classify_name}</div>
              </div>)}
            </div>
          } else if (item.tag === 'guessYouLike') {
            return <div>
              <WantPlay item={item}></WantPlay>
            </div>
          } else if (item.tag === 'hotSearchWord') {
            return <div>
              <AllSearch item={item}></AllSearch>
            </div>
          }
        })()}
      </div>
    </div>)}
    <div className="index-tips" style={{display:datas.length == 0 ? 'none' : ''}}>
      <div className="text">更多游戏请到发现中查看</div>
    </div>
    {/* 备案号 */}
    {
      copyright ?
      <div className="index-provider" style={{textAlign:'center'}}>
        <a href="https://beian.miit.gov.cn/">{copyright}</a>
      </div>
      :null
    }
    {/**下载横幅 **/}
    {/* {
      (!mobileDetect.is('iPhone') && !mobileDetect.is('iPad')) && downBoxState ?
      <div className="index-g-ad">
          <div className="close" onClick={() => setDownBoxState(false)} style={{backgroundImage: 'url(http://cdn1.oss.wakaifu.com/active/20210401/close.png)'}}></div>
          <div className="left">
              <div className="icon">
                  <img src={app.oapp_icon} />
              </div>
              <div className="txt">
                  <div className="name">{app.appname}</div>
                  <p>海量变态福利手游大全</p>
              </div>
          </div>
          <div className="right">
              <a href={"http://app.milu.com/?a=" + agent}  className="btn">立即下载</a>
          </div>
      </div>
      : null
    } */}
    
    {/* 送首充and代金券 */}
    <div className="index-fixdiv">

      {
        isAccord ? (
        indexActiveState || config.is_activity == '1' ?
          <NavLink onClick={()=>{
            Manager.push({event_id:'B-9'})
            // setIndexActiveState(true)
          }} user={activeInfo.needlogin} to={(() => {
            if(activeInfo.url) {
              if (!localStorage.getItem('username')){
                let url = URI(activeInfo.url)
                  .addQuery('location_url', window.location.origin)
                  .addQuery('origin', 'react')
                  .toString();
                return url;
              }else{
                let url = URI(activeInfo.url)
                  .addQuery('username', localStorage.getItem('username'))
                  .addQuery('token', localStorage.getItem('token'))
                  .addQuery('location_url', window.location.origin)
                  .addQuery('origin', 'react')
                  .toString();
                return url;
              }
            }
          })()} iframe={{title: activeInfo.name}}>
          <img src={config.activity_thumb}  className="ssc"/></NavLink>
        : null) : null
      }
    </div>

    {/**首充活动弹框 */}
    {
      isAccord ?
      // (indexActiveState || packageGiftState || firstChargeState || noLoginPop ?
      (indexActiveState || packageGiftState ?
      <>
      <div className="index-first-charge" id="first-charge"  ref={ref}>
      {/* onTouchMove={touchmove} */}
        {
          (indexActiveState ? 
            <>
            <NavLink user={activeInfo.needlogin}
            to={(() => {
              if(activeInfo.url) {
                if (!localStorage.getItem('username')){
                  let url = URI(activeInfo.url)
                    .addQuery('location_url', window.location.origin)
                    .addQuery('origin', 'react')
                    .toString();
                  return url;
                }else{
                  let url = URI(activeInfo.url)
                    .addQuery('username', localStorage.getItem('username'))
                    .addQuery('token', localStorage.getItem('token'))
                    .addQuery('location_url', window.location.origin)
                    .addQuery('origin', 'react')
                    .toString();
                  return url;
                }
              }
            })()}
            iframe={{title: activeInfo.name}}
            onClick={closePop}
            >
              <img src={activeInfo.img} onClick={closePop}/>
            </NavLink>
            </>
          :
          (!indexActiveState && packageGiftState ? 
            user.novice_fuli_v2101_show == 1 || user.is_show_reg_between_8day_30day? 
              <>
              <NavLink to={(() => {
                let url = URI(user.novice_fuli_v2101_url)
                      .addQuery('username', localStorage.getItem('username'))
                      .addQuery('token', localStorage.getItem('token'))
                      .addQuery('location_url', window.location.origin)
                      .addQuery('origin', 'react')
                      .toString();
                  return url;
                })()} iframe={{title: '新人福利'}} onClick={closePop}>
                {user.novice_fuli_v2101_show ? <img src="https://cdn1.oss.wakaifu.com/active/new-agent-website/index/t3.png"  onClick={closePop}/> : <img src="https://cdn1.oss.wakaifu.com/active/new-agent-website/index/t4.png"  onClick={closePop}/>}
              </NavLink>
              </>
            :
            (user.is_show_reg_gt_30day ? 
              <>
              <NavLink to="/party/regression" iframe={{title: '老用户回归活动'}} onClick={closePop}>
                <img src="https://cdn1.oss.wakaifu.com/active/new-agent-website/index/t1.png"  onClick={closePop}/>
              </NavLink>
              </>
            : null)
          :
            null
          ) )
        }
        <div className="close001">
          <img src="https://cdn1.oss.wakaifu.com/active/20210401/close.png" onClick={closePop}/>
        </div>
      </div>
      </>
      : null) : null
    }

    {
    browserState ?
    <CopyToClipboard text={browserUrlText}>
        <div style={{display:(browserCopys ? 'none' : '')}} className="index-wechat-img-bg" onClick={()=>{setBrowserCopys(true)}}></div>
    </CopyToClipboard>
    : null}


    {browserCopys ?
      <CopyMask></CopyMask>
    : null}

    {/** 交易驳回 **/}
    {tradesStatePop ?
    <div className="index-tradesreject">
      <div className="container">
        <div className="body">
          <div className="icon"><img src="/resources/rebate/confirm.png" alt=""/></div>
          <div className="title">{tradesReject.content.title}</div>
        </div>
        <div className="info">
          {tradesReject.content.desc}
        </div>
        <div>
          <NavLink className="btns" to={(() => {
            if(tradesReject.link_info.link_route == 'trades_info') {
              return "/trade/detail?id=" + tradesReject.link_info.link_value;
            }
          })()} onClick={()=>setTradesStatePop(false)}>
            {tradesReject.content.button}
          </NavLink>
        </div>
      </div>
      <div className="close">
        <img src="https://cdn1.oss.wakaifu.com/active/20210401/close.png" onClick={()=>setTradesStatePop(false)}/>
      </div>
    </div>
    :null}

    {/** 推广弹框 **/}
    {spweardState || gamePopState ? 
    <div className="index-spweard">
      <div className="spweard-site">
        <div className="spweard-main">
          <div className="spweard-imt-t">
            <img src="/resources/index/spweard-img-title.png"/>
          </div>
          {/* <div className="close" onClick={() => setExchangeState(false)}>
            <img src="/resources/user/close.png" />
          </div> */}
          <div className="spweard-title">
          温馨提示
          </div>
          <div className="info">
            <p>打开您刚刚点击的{gamePopState ? '游戏' : ''}</p>
            {gamePopState ? '' :
              <p className="des2">{params.zt}类游戏</p>
            }
          </div>
          <div className="spweard-btn">
            <div className="item btn-about" onClick={()=>{setSpweardState(false);setGamePopState(false)}}>
              否
            </div>

            {spweardState ? 
            <NavLink
              to={(() => {
                let url = URI(params.zu)
                    .addQuery('username', localStorage.getItem('username'))
                    .addQuery('token', localStorage.getItem('token'))
                    .addQuery('location_url', window.location.origin)
                    .addQuery('origin', 'react')
                    .toString();
                return url;
              })()} iframe={{title: params.zt}} user={false} onClick={()=>setSpweardState(false)} className="item btn-confirm">
              是
            </NavLink>
            :
            <NavLink to={"/game?id=" + params.b} user={false} onClick={()=>setGamePopState(false)} className="item btn-confirm btn-game-pop">
              是
            </NavLink>
            }
          </div>
        </div>
      </div>
    </div>
    : null}

    {/** message 弹框 **/}
    {
      alertList.length > 0 ?
      alertList.map(item => item.display ? <>
        {(()=>{
          if(item.jumpType == 'outer_web') {
            return <div className="index-first-charge index-game-info-img" id="first-charge" >
              <div onClick={()=>{closeNewTradePop(item);newoper(item)}}>
                <img src={item.img} onLoad={()=>setAlertPicCompletion(true)}/>
              </div>
              {alertPicCompletion && <div className="close001" onClick={()=>{closeNewTradePop(item)}}>
                <img src="https://cdn1.oss.wakaifu.com/active/20210401/close.png" />
              </div>}
            </div>
          } else if(item.jumpType == 'agentMessage') {
            return <div className="index-first-messageAlert ">
              <div className="exit-site">
                <div className="exit-main">
                  <div className="exit-top-img">
                    <img src="/resources/index/message-top-img.png" />
                  </div>
                  <div className="bar-top" onClick={()=>{closeNewTradePop(item)}}>
                    <img src="/resources/party/couple-close.png" />
                  </div>
                  <div className="exit-title">
                  {item.title}
                  </div>
                  <div className="info">
                    <p>{item.content}</p>
                  </div>
                  <div className="exit-btn">
                    <NavLink to={"/message/infobox?id=" + item.value} className="item btn-about" onClick={()=>{closeNewTradePop(item)}}>
                      查看详情
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          } else if(item.jumpType == 'news_info') {
            return <div className="index-first-messageAlert ">
              <div className="exit-site">
                <div className="exit-main">
                  <div className="exit-top-img">
                    <img src="/resources/index/message-top-img.png" />
                  </div>
                  <div className="bar-top" onClick={()=>{closeNewTradePop(item)}}>
                    <img src="/resources/party/couple-close.png" />
                  </div>
                  <div className="exit-title">
                  {item.title}
                  </div>
                  <div className="info">
                    <p>{item.content}</p>
                  </div>
                  <div className="exit-btn">
                    <NavLink user={false} to={"/message/detail?id=" + item.value} className="item btn-about" onClick={()=>{closeNewTradePop(item)}}>
                      {item.button}
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          } else if(item.jumpType == 'inner_web') {
            return <div className="index-first-charge index-game-info-img" id="first-charge" >
              <div onClick={()=>{closeNewTradePop(item);newoper(item)}}>
                <img src={item.img} onLoad={()=>setAlertPicCompletion(true)}/>
              </div>
              {alertPicCompletion && <div className="close001" onClick={()=>{closeNewTradePop(item)}}>
                <img src="https://cdn1.oss.wakaifu.com/active/20210401/close.png" />
              </div>}
            </div>
          } 
          else if(item.jumpType == 'rebate_info') {
            return <div className="index-first-messageAlert ">
              <div className="exit-site">
                <div className="exit-main">
                  <div className="exit-top-img">
                    <img src="/resources/index/message-top-img.png" />
                  </div>
                  <div className="bar-top" onClick={()=>{closeNewTradePop(item)}}>
                    <img src="/resources/party/couple-close.png" />
                  </div>
                  <div className="exit-title">
                  {item.title}
                  </div>
                  <div className="info">
                    <p>{item.content}</p>
                  </div>
                  <div className="exit-btn">
                    <NavLink to={"/rebate/detail?id=" + item.value} className="item btn-about" onClick={()=>{closeNewTradePop(item)}}>
                      查看详情
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          } else if(item.jumpType == 'game_info') {
            return <div className="index-first-charge index-game-info-img" id="first-charge" >
              <NavLink to={"/game?id=" + item.value} user={false} className="item btn-about" onClick={()=>{closeNewTradePop(item)}}>
                <img src={item.img} onLoad={()=>setAlertPicCompletion(true)}/>
              </NavLink>

              {alertPicCompletion && <div className="close001" onClick={()=>{closeNewTradePop(item)}}>
                <img src="https://cdn1.oss.wakaifu.com/active/20210401/close.png" />
              </div>}
            </div>
          }
        })()}
      </> : null)
      :null
    }

    {/** bottom-banner **/}
    {
      Object.keys(bottomGame).length > 0 ? bottomBannerState ? <>
        {(()=>{
          if(bottomGame.type == 'inner_web') {
            return <div className="index-bottom-banner">
              {/* <div className="close" style={{backgroundImage: 'url(/resources/index/white-close.png)'}} onClick={()=>{setBottomBannerState(false)}}></div> */}
              <div className="close" onClick={()=>{setBottomBannerState(false)}}>
                <img src="/resources/index/white-close.png" />
              </div>
              <NavLink to={(() => {
              let url = URI(bottomGame.value)
                  .addQuery('username', localStorage.getItem('username'))
                  .addQuery('token', localStorage.getItem('token'))
                  .addQuery('location_url', window.location.origin)
                  .addQuery('origin', 'react')
                  .toString();
                return url;
              })()}
              iframe={{title: bottomGame.title}}
              className="img-body" 
              user={bottomGame.is_check_login == true?true:false}
            >
              <img src={bottomGame.img} />
            </NavLink>
            </div>
          } else {
            return <div className="index-bottom-banner">
              <div className="close" onClick={()=>{setBottomBannerState(false)}}>
                <img src="/resources/index/white-close.png" />
              </div>
              <div className="img-body" onClick={()=>{bottomOper(bottomGame)}}>
                <img src={bottomGame.img} />
              </div>
            </div>
          }
        })()}
      </>
      : null
      : null
    }

    {tradeGamePop ? 
    <div className="index-first-charge index-game-info-img" id="first-charge" >
      <div onClick={()=>{newoper()}}>
        <img src={tradeNotice.content.image} />
      </div>
      <div className="close001">
        <img src="https://cdn1.oss.wakaifu.com/active/20210401/close.png" onClick={closeTradePop}/>
      </div>
    </div>
    : null}


  </div>;
}