import { useEffect, useState } from "react";
import { Popup } from 'antd-mobile'
import { NavLink } from '../../../lib/Navigation'
import useActiveState from "../../../lib/useActiveState";
import URI from "urijs";
import MobileDetect from "mobile-detect";
import './css/ AlertLogin.css'

const mobileDetect = new MobileDetect(navigator.userAgent);

export default function Thingsknow(props) {

  const [agreement, setAgreement] = useState(true);
  const [agent] = useActiveState('agent');

  return (
    <div>
      <Popup className={"alert-login-index"}
        visible={true}
        bodyStyle={{
          borderTopLeftRadius: '13px',
          borderTopRightRadius: '13px',
          overflow: 'hidden',
        }}>
        <div className="alert-login-header">登录须知</div>
        <div className="alert-login-content">
          <div className="account-agreement big">
            <label>
              我已阅读同意
              <NavLink to={(() => {
                let url = URI('//api3.app.wakaifu.com/userAgreement')
                  .addQuery('title', '用户协议')
                  .addQuery('channel', agent)
                  .addQuery('device_type', (mobileDetect.os() == 'iOS' ? 10 : 20))
                  .toString();
                return url;
              })()}
                iframe={{ title: '《用户协议》' }}
                user={false}
              >《用户协议》</NavLink>
              <NavLink to={(() => {
                let url = URI('//api3.app.wakaifu.com/privacyPolicy')
                  .addQuery('title', '隐私政策')
                  .addQuery('channel', agent)
                  .addQuery('device_type', (mobileDetect.os() == 'iOS' ? 10 : 20))
                  .toString();
                return url;
              })()}
                iframe={{ title: '《隐私政策》' }}
                user={false}
              >《隐私政策》</NavLink>
              <br />
              接受免除或者限制责任、诉讼管辖约定等粗体标示条款
            </label>
          </div>
          <div className='operate' onClick={() => {
            PubSub.publish('handlelogin',true)
          }}>确定</div>
          <div className='autologin' onClick={() => {
            props.login()
          }}>取消</div>
        </div>
      </Popup>
    </div>
  )
}
