import {useEffect, useState} from "react";
import general from "../general";
import {BulbOutlined, DownOutlined} from '@ant-design/icons';

export default props => {
  // let faqGroup = general.faqGroups[props.query.group];
  // let faqs = faqGroup.items;

  const [type] = useState(props.query.type)
  const [idx, setIdx] = useState(-1)
  const [list, setList] = useState([])
  const [data, setData] = useState('')

  useEffect(() => {
    if (props.active){
      props.setTitle(general.history.location.title);
      load()
    }
  }, [props.active]);

  const load = ()=>{
    const url = type == 1 ? '/base/kefu/getQuickService' : '/base/kefu/getFrequentlyQuest'
    general.axios.post(url,{id:props.query.id})
    .then(response => {
      let result = response.data.data;
      if (type == 1){
        setList(result)
      }else {
        setData(result)
      }
    })
    .catch(err => {
        Dialog.error(err.message);
      });
  }

  return <div className="faq-index">
    {type == 1 && <div>
      {list.map((item,index)=>{
        return (
          <div key={index} className="faq-item" onClick={()=>{
            if (idx == index){
              setIdx(-1)
            }else{
              setIdx(index)
            }
          }}>
            <div className="q">
              <div className="text">{item.q}</div>
              <DownOutlined style={{fontSize:'10px',color:'#DEDEDE'}}></DownOutlined>
            </div>
            {idx == index && <div className="a" dangerouslySetInnerHTML={{__html:item.a.replace(/([^>\r\n\r\n]?)(\r\n\r\n|\n\r|\r|\n)/g, '$1<br>$2')}}></div>}
          </div>
        )
      })}
    </div>}
    {type == 2 && <div dangerouslySetInnerHTML={{__html:data}}></div>}
  </div>;
};