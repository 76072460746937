import { Swiper } from 'antd-mobile'
import React, { useEffect, useState } from 'react'
import { NavLink } from '../../../lib/Navigation';
import Manager from '../Manager';

export default function WantPlay(props) {

  const games = props.item.list_x

  const items = games.map((game, index) => (
    <Swiper.Item key={index}>
      <div className='swiper-index' key={game.game_id}>
        {game.map((obj,idx)=>{
          return (
            <NavLink onClick={()=>{
              Manager.push({event_id:props.item.event_id})
            }} to={"/game?id=" + obj.game_id} className='item' user={false} key={obj.game_id}>
              <div className='test'>
                <img className='icon' src={obj.banner_url} alt="" />
              </div>
              <div className='name'>
                <span className='gameName'>{obj.game_name}</span>
                {obj.discount < 1 && <span className="nameRemark">{obj.discount * 10}<span className='text'>折</span></span>}
              </div>
              <div className='classify'>{obj.game_classify_type.trim()} {obj.nameRemark ? <>· {obj.nameRemark}</> : null}</div>
              {/* <div>{obj.desc}</div>
              {obj.exclusive_game_describe?.length > 0 || obj.is_support_accele || obj.is_easy_play ?
              <div className='tags'>
                {obj.exclusive_game_describe?.length > 0 ? <div className="speed">{obj.exclusive_game_describe}</div> : null}
                {obj.is_support_accele ? <div className="speed">可加速</div> : null}
                {obj.is_easy_play ? <div className="speed">省心玩</div> : null}
              </div>
              : null} */}
              <div className="desc">{obj.rule}</div>
            </NavLink>
          )
        })}
      </div>
    </Swiper.Item>
  ))

  return (
    <div className='index-want-play'>
      <div className='index-want-play-header-icon'>
        <img src={props.item.title_image} alt="" />
        <div className='index-want-play-header-desc'>{props.item.tips}</div>
      </div>
      <div className='index-want-play-swiper'>
        <Swiper indicator={(total, current) => {
              return games.length > 1 ? <div className='index-want-play-swiper-dot'>
                {games.map((item,index)=>{
                  return <div key={index} className='index-want-play-swiper-dot-item' style={{
                    backgroundColor: index === current? '#ffc000' : '#AAAAAA'
                  }}></div>
              })}
          </div> : <div className='index-want-play-empty-interval'></div>
        }}>{items}</Swiper>
      </div>
    </div>
  )
}
