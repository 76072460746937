import React from 'react'
import Draggable from 'react-draggable'
import general from './general';
import useActiveState from './lib/useActiveState';

export default function Service() {

  const [app] = useActiveState('app')

  let isDragging = false;

  const handleDrag = () => {
    isDragging = true;
  };
  const handleStop = () => {
    isDragging = false;
  };

  const handleClick = () => {
    isDragging = false;
    /*此处延迟，是为了等待 handleDrag 监听拖拽事件先执行，若该方法未执行则判断为点击事件， 
      否则为拖拽事件*/
    setTimeout(() => {
      if (!isDragging) {
        general.history.push('/service')
      }
    }, 200);

  };


  return (
    <div>
      {app.is_overseas && <Draggable
        onDrag={handleDrag} onStop={handleStop} bounds="body">
        <img src='resources/service_milu.png' onTouchStart={() => { handleClick() }} style={{
          position: 'fixed', right: '20px',
          bottom: '180px', width: '50px', height: '50px', zIndex: '9999'
        }}></img>
      </Draggable>}
    </div>
  )
}
