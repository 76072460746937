import { useEffect, useState } from "react";
import Dialog from "../../../lib/Dialog";
import general from "../../../general";
import useActiveState from "../../../lib/useActiveState";
import { CloseOutlined, DownOutlined, EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useLocalStorageState, useToggle, useInterval } from "ahooks";
import { Popup } from 'antd-mobile'
import './css/ AlertLogin.css'
import { Link } from "react-router-dom";

export default function Auth(props) {

  const [data, setData] = useState(props.data);
  const [focus, setFocus] = useState('');
  const [password, { toggle: togglePassword }] = useToggle(true);
  const [saving, setSaving] = useState(false);
  const [tk, setTk] = useActiveState('tk');
  const [user, setUser] = useActiveState('user');
  const [tkUrl, setTkUrl] = useActiveState('tkUrl');
  const [tkStatus, setTkStatus] = useLocalStorageState('register-tk-status', 0);
  const [visiable, setVisiable] = useState(true)
  const [visibility, setVisibility] = useState('visible')
  const [loging, setLoging] = useState(false);
  const [codIng, setCodIng] = useState(false);
  const [codeTime, setCodeTime] = useState(0);
  const [authIdx, setAuthIdx] = useState(0)
  const [authCode, setAuthCode] = useState('')
  const [authCoding, setAuthCoding] = useState(false);
  const [authCodeTime, setAuthCodeTime] = useState(0);
  const [authArray, setAuthArray] = useState(props.authArray);
  const [authMobile, setAuthMobile] = useState('');
  const [areaNumData, setAreaNumData] = useState([]);

  useEffect(()=>{
    const handleBack = PubSub.subscribe('alert-back',()=>{
      setVisibility('visible')
    })
  },[])

  useInterval(() => {
    if (authCodeTime) {
      setAuthCodeTime(authCodeTime - 1);
    }
    console.log(authCodeTime);
  }, 1000);

  const getAuthCode = () => {
    if (loging) {
      return
    }
    if (authCodeTime > 0) {
      return
    }
    setAuthCoding(true)
    Dialog.loading();
    general.axios.post('/base/tool/getVerifyCode', {
      username: data.username,
      type: '10',
    })
      .finally(() => {
        Dialog.close();
        setAuthCoding(false)
      })
      .then(response => {
        let result = response.data;
        if (!result.status.succeed) {
          Dialog.error(result.status.error_desc);
        } else {
          setAuthCodeTime(60);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  //绑定手机号发送验证
  const code = () => {
    if (authMobile.length == 0) {
      Dialog.error('请输入手机号');
      return;
    }
    if (authCodeTime > 0) {
      return
    }
    setAuthCoding(true);
    Dialog.loading();
    general.axios.post('/base/tool/getVerifyCode', {
      mobile: (areaNumData ? areaNumData : '86') + '-' + authMobile,
      username: data.username,
      type: '11',
    })
      .finally(() => {
        setAuthCoding(false);
        Dialog.close();
      })
      .then(response => {
        let result = response.data;
        if (!result.status.succeed) {
          Dialog.error(result.status.error_desc);
        } else {
          setAuthCodeTime(60);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const authSubmit = () => {
    if (loging) {
      return
    }
    var params = {}
    if (authArray.length == 0) {
      if (authMobile.length == 0) {
        Dialog.error('请输入手机号');
        return;
      }
      if (authCode.length == 0) {
        Dialog.error('请输入验证码');
        return;
      }
      params['username'] = data.username
      params['password'] = data.password
      params['mobile'] = authMobile
      params['code'] = authCode
      params['type'] = '3'
    } else {
      if (authCode.length == 0) {
        Dialog.error('请输入验证信息');
        return;
      }
      params['username'] = data.username
      params['code'] = authCode
      params['type'] = authArray[authIdx]['type']
    }
    Dialog.loading();
    general.axios.post('/unlockAccount', params)
      .finally(() => {
        setLoging(false);
        Dialog.close();
      })
      .then(response => {
        let result = response.data;
        if (!result.status.succeed) {
          Dialog.error(result.status.error_desc);
          setAuthCode('')
        } else {
          PubSub.publish('handlelogin', true)
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const callback = (e)=>{
    setVisibility('visible');
    setAreaNumData(e);
    props.handleRegisterAgreement(false)
  }

  useEffect(() => {
    setAreaNumData(general.areaNumber);
  }, []);

  return (
    <div>
      <Popup className={"alert-login-index " + visibility}
        visible={visiable}
        bodyStyle={{
          borderTopLeftRadius: '13px',
          borderTopRightRadius: '13px',
          overflow: 'hidden',
        }}>
        <CloseOutlined className='alert-close' onClick={() => props.login()}></CloseOutlined>
        <div className="alert-login-header">{authArray.length > 0 ? '身份信息验证' : '绑定手机号'}</div>
        <div className="alert-login-content">
          <div className="auths">
            {authArray.map((item, index) => {
              return (
                <div className="item" onClick={() => setAuthIdx(index)}>
                  <div>{item.name}</div>
                  <img className="icon" src={"resources/account/" + (authIdx == index ? "checked.png" : "check.png")} alt="" />
                </div>
              )
            })}
          </div>
          <div>
            {authArray.length > 0 ?
              <div className="input-item">
                <div className="input auth-input">
                  <input name="code" placeholder={authArray[authIdx]['tips']} onChange={e => {
                    setAuthCode(e.target.value)
                  }} />
                </div>
                {authArray[authIdx].name.search('验证码') !== -1 && <div className={'code-btn ' + (codIng || codeTime ? 'disable' : '')} onClick={getAuthCode}>
                  {authCoding ? '发送中' : (authCodeTime ? (authCodeTime + '秒') : '获取验证码')}
                </div>}
              </div> :
              <div>
                <div className="input-item">
                  <div className="label">
                    <Link onClick={() => { 
                        props.handleRegisterAgreement(false)
                        setVisibility('hidden')
                      }} to={{
                      pathname: "/account/country",
                      callback: callback
                    }} className="area-num-text">
                      <img src="/resources/account/jia.png" />
                      <span className="value">
                        {areaNumData ? areaNumData : '86'}
                      </span>
                      <img src="/resources/account/sel-down.png" />
                    </Link>
                  </div>
                  <div className="input">
                    <input name="phone" type="number" placeholder="请输入手机号" autoComplete="off" onChange={e => setAuthMobile(e.target.value)}/>
                  </div>
                </div>
                <div className="input-item">
                  <div className="label">验证码</div>
                  <div className="input">
                    <input name="code" type={'number'} placeholder="请输入验证码" autoComplete="off" onChange={e => setAuthCode(e.target.value)}/>
                  </div>
                  <div className={'code-btn ' + (codIng || codeTime ? 'disable' : '')} onClick={code}>
                    {authCoding ? '发送中' : (authCodeTime ? (authCodeTime + '秒') : '获取验证码')}
                  </div>
                </div>
              </div>
            }
            <div className="register"></div>
          </div>
          <div className='operate' onClick={authSubmit}>确定</div>
          <div className='autologin'></div>
        </div>
      </Popup>
    </div>
  )
}
