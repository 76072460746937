import React, { useEffect, useState } from 'react'
import './css/wechat.css'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Dialog from '../../lib/Dialog';
import MobileDetect from "mobile-detect";

const mobileDetect = new MobileDetect(navigator.userAgent);

export const inWechat = ()=>{
  let ua = window.navigator.userAgent.toLocaleLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger'){
    return true
  }else{
    return false
  }
}

export const inQQ = ()=>{
  if(mobileDetect.is('iPhone')) {
    let ua = window.navigator.userAgent.toLocaleLowerCase();
    if (ua.match(/QQ/i) == "qq"){
      return true
    }else{
      return false
    }
  } else {
    return false
  }
}

export default function Index() {

  const [wechatAlert,setWechatAlert] = useState(false)
  const [QQAlert,setQQAlert] = useState(false)
  const [isCopyed,setIsCopyed] = useState(false)

  useEffect(()=>{
    if( inWechat() ){
      setWechatAlert(true)
      const body = document.body;
      body.classList.add("body-overflow-hidden");
    } 
    if (inQQ()){
      setQQAlert(true)
      const body = document.body;
      body.classList.add("body-overflow-hidden");
    }
  },[])

  return (
    <>
    {
      inWechat() ? <div className="wechat-alert" 
         style={{backgroundImage:'url(resources/wechat-alert.jpg)',display:wechatAlert ? '' : 'none'}}>
      </div> : null
    }
    {
      inQQ() ? <div className="qq-alert" 
         style={{display:QQAlert ? '' : 'none'}}>
         <div style={{color:'white'}}>
          <div className='tips'>重要提示</div>
          <div className='desc'>由于QQ限制，点击右上角复制链接或者打开浏览器会导致数据丢失或者无法下载游戏，请点击下方复制链接在<span className='ua'>Safari浏览器</span>里面打开！</div>
          <div className='href'>{window.location.href}</div>
          <CopyToClipboard className='copybtn' text={window.location.href} onCopy={() => { 
            setIsCopyed(true)
            }}>
            <div className="c-btn">{isCopyed ? '已复制' : '复制链接'}</div>
          </CopyToClipboard>
         </div>
      </div> : null
    }
    </>
    
  )
}
