import React, { useEffect, useState } from 'react'
import { NavLink } from '../../../lib/Navigation';
import URI from 'urijs';
import { Link } from 'react-router-dom';
import Manager from '../Manager';
import "./css/css.css"

export default function HomeBanner(props) {

  const banner = props.banner
  
  return (
    <div className='index-home-banner' key={banner.id}>
      {banner.type === 'insideUrl' || banner.type === 'externalUrl' || banner.type == 'news' ?
        <NavLink user={banner.login} to={(() => {
          if (localStorage.getItem('username')){
            let url = URI(banner.val)
              .addQuery('username', localStorage.getItem('username'))
              .addQuery('token', localStorage.getItem('token'))
              .addQuery('mb', localStorage.getItem('mb'))
              .addQuery('location_url', window.location.origin)
              .addQuery('origin', 'react')
              .toString();
            return url;
          }else{
            let url = URI(banner.val)
              .addQuery('mb', localStorage.getItem('mb'))
              .addQuery('location_url', window.location.origin)
              .addQuery('origin', 'react')
              .toString();
            return url;
          }
        })()}
        onClick={()=>{
          Manager.push({ event_id:banner.event_id })
        }}
        iframe = {{title: banner.title}}
        >
          <img className = "banner-img" src={banner.img} alt="" />
        </NavLink>
      : 
      <Link
        to={(() => {
          if (banner.type === 'game') {
            return '/game?id=' + banner.val;
          }
          if (banner.type === 'special') {
            return '/game/project?id=' + banner.val;
          }
          if(banner.type == 'vouchandgift') {
            return '/giftproject'
          }
          if(banner.type == 'signgift') {
            return '/signgift'
          }
        })()}
        onClick={()=>{
          Manager.push({ event_id:banner.event_id })
        }}
      >
      <img className = "banner-img" src={banner.img} alt="" />
      </Link>}
    </div>
  )
}
