import {useEffect, useState} from "react";
import general from "../../../general";
import MobileDetect from "mobile-detect";
import Dialog from "../../../lib/Dialog";
import ImagePicker from "../../util/ImagePicker";
import {Link} from "react-router-dom";
import {useMount, useScroll} from "ahooks";
import $ from "jquery";

const mobileDetect = new MobileDetect(navigator.userAgent);

export default props => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [more, setMore] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);

  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      pagination: {page: page},
    };
    if (parms.page) {
      tmp.pagination = {page: parms.page};
    }
    general.axios.post('/discountComplaintList', tmp)
        .finally(() => {
          setLoading(false);
          Dialog.close();
        })
        .then(response => {
          setInit(true);
          let result = response.data.data;
          if (parms.page) {
            setPage(parms.page);
          }

          setData(data => parms.page === 1 || !parms.page ? result.list : [...data, ...result.list]);
          setMore(result.paginated.more && result.list.length > 0);
          setLoaded(true);
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 > $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, props.active]);
  
  // console.log(data);
  return <div className="game-report">
    <div className="report-body">
      <div className="items">

        {data?.map((item,index) => <div className="report-item">
          {/* <div className="title">
              折扣游戏
          </div> */}
          <div className="name">
              <div className="  name-new">{item.gameName}</div>
              {
                item.nameRemark ? 
                <div className="nameRemark">{item.nameRemark}</div>
                : null
              }
          </div>
          <div className="content">
            <div className="label">
              账号:
            </div>
            <div className="text">
              {item.username}
            </div>
          </div>
          <div className="content">
            <div className="label">
              折扣平台:
            </div>
            <div className="text">
              {item.platform}
            </div>
          </div>
          {item.downLink ? 
          <div className="content">
            <div className="label">
              下载链接:
            </div>
            <div className="text">
              <span>
                {item.downLink}
              </span>
            </div>
          </div>
          : null}
          {item.discount ? 
          <div className="content">
            <div className="label">
              充值折扣:
            </div>
            <div className="text">
              {item.discount}
            </div>
          </div>
          : null}
          <div className="content">
            <div className="label">
              联系方式:
            </div>
            <div className="text">
              {item.contact}
            </div>
          </div>
          {item.images ? 
          <div className="content">
            <div className="label">
              证据截图:
            </div>
            <div className="text">
              <div className="imgs">
                {item.images.split(',').map((item)=><>
                <img src={item} />
                </>)}
                {/* <img src="https://static.app.985sy.com/attachment/android/images/2211/16690201032.jpg!gameDetail_new" />
                <img src="https://static.app.985sy.com/attachment/android/images/2211/16690201032.jpg!gameDetail_new" />
                <img src="https://static.app.985sy.com/attachment/android/images/2211/16690201032.jpg!gameDetail_new" /> */}
              </div>
            </div>
          </div>
          : null}
        </div>)}
        {data.length === 0 && loaded ? <div className="report-empty"><img src="/resources/game/report_empty.png" alt=""/>暂无举报详情~</div> : null}
      </div>
    </div>
  </div>;
};