import { useEffect, useState } from "react";
import Dialog from "../../../lib/Dialog";
import general from "../../../general";
import useActiveState from "../../../lib/useActiveState";
import { CloseOutlined, DownOutlined, EyeInvisibleOutlined, EyeOutlined, LeftOutlined } from "@ant-design/icons";
import { useLocalStorageState,useToggle } from "ahooks";
import { Popup } from 'antd-mobile'
import './css/ AlertLogin.css'

export default function Password(props) {

  const [data, setData] = useState({});
  const [focus, setFocus] = useState('');
  const [password, {toggle: togglePassword}] = useToggle(true);
  const [saving, setSaving] = useState(false);
  const [tk, setTk] = useActiveState('tk');
  const [user, setUser] = useActiveState('user');
  const [app] = useActiveState('app');
  const [tkUrl, setTkUrl] = useActiveState('tkUrl');
  const [tkStatus, setTkStatus] = useLocalStorageState('register-tk-status', 0);
  const [visiable, setVisiable] = useState(true)
  const [visibility, setVisibility] = useState('visible')

  const save = () => {
    if (!data.password) {
      Dialog.error('请输入密码');
      return;
    }
    Dialog.loading();
    general.axios.post('/setPassword', {
      headers: { username: localStorage.getItem('username'), token: localStorage.getItem('token') },
      member_id: localStorage.getItem('user_id'),
      password: data.password,
    })
      .finally(() => {
        Dialog.close();
      })
      .then(response => {
        let { status, data } = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        load_load()
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  };

  const load_load = () => {
    general.axios.post('/user/user/getMemberInfo')
      .then(response => {
        let { status, data } = response.data;
        if (!status.succeed) {

        } else {
          setData({...data,password:''})
          localStorage.setItem('yx_account',data.member_info.yx_account)
          localStorage.setItem('yx_token',data.member_info.yx_token)
          setUser({ isLogin: true, ...data.member_info });
          Dialog.success('设置成功', props.dismiss());
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  return (
    <div>
      <Popup className={"alert-login-index " + visibility}
        visible={visiable}
        bodyStyle={{
          borderTopLeftRadius: '13px',
          borderTopRightRadius: '13px',
          overflow: 'hidden',
        }}>
        {/* <LeftOutlined className="alert-back" onClick={() => props.login()}></LeftOutlined> */}
        <div className="alert-login-header">设置密码</div>
        <div className="alert-login-content">
          <div>
            <div className="input-item">
              <div className="label">密码</div>
              <div className="input">
                <input name="password" type={password ? 'password' : 'text'} value={data.password} placeholder="请输入6-8位密码"
                  onFocus={e => setFocus('password')} onChange={e => setData({ ...data, password: e.target.value })} />
                <div className="assist password" onClick={() => togglePassword()}>
                  {password ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                </div>
              </div>
            </div>
            <div className="register"></div>
          </div>
          <div className='operate' onClick={save}>确定</div>
          <div className='autologin'></div>
        </div>
      </Popup>
    </div>
  )
}
