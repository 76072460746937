import {Link, useHistory} from "react-router-dom";
import general from "../../general";
import useActiveState from "../../lib/useActiveState";
import {useLockFn} from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import {RightOutlined} from '@ant-design/icons';
import {useLocalStorageState} from "ahooks";
import { useState } from "react";
import PubSub from "pubsub-js";

export default props => {
  const history = useHistory();
  const [user, setUser] = useActiveState('user');
  const [noLoginDatePop, setNoLoginDatePop] = useLocalStorageState('nologin-date-pop', []); //未登录每日的状态
  const [isIndexActivityDatePop, setIsIndexActivityDatePop] = useLocalStorageState('index-activity', []);
  const [config] = useActiveState('config');
  const [app] = useActiveState('app');
  const [whether, setWhether] = useActiveState('whether');//是否进行过退出操作
  
  const avatar = useLockFn(async e => {
    let files = e.target.files;
    if (files.length < 1) {
      return;
    }
    Dialog.loading();
    let formData = new FormData();
    formData.append('file', files[0]);
    await general.axios.post('/user/user/uploadAvatar', formData)
        .finally(() => {
          Dialog.close();
        })
        .then(response => {
          let {status, data} = response.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            setUser({...user, icon_link: data.url});
            Dialog.success('上传成功 请等待审核');
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });
  const phone = e => {
    if (user.mobile) {
      e.preventDefault();
      Dialog.dialog({
        mask: true,
        title: '选择操作',
        btns: [
          {text: '解绑手机号', onPress: () => history.push('/user/phone-remove')},
          {text: '更换手机号', onPress: () => history.push('/user/phone-verify')},
          {text: '取消'},
        ]
      });
    }
  }
  const logout = () => {
    localStorage.removeItem('user_id');
    localStorage.removeItem('username');
    localStorage.removeItem('token');
    localStorage.removeItem('yx_account');
    localStorage.removeItem('yx_token');
    localStorage.setItem('logout', true);
    setWhether('true');
    // setNoLoginDatePop(null);
    setIsIndexActivityDatePop(null);
    history.goBack();
    setTimeout(() => {
      PubSub.publish('loginSubscribe',false)
    }, 1000);
  }

  const [isCheckAdult, setIsCheckAdult] = useState(false); //未成年温馨提示状态
  const [isCheckAuth, setIsCheckAuth] = useState(false); //实名认证弹框状态
  const [authData, setAuthData] = useState({}); //实名认证参数值

  //实名认证
  const certifiy = useLockFn(()=>{
    if(!authData.real_name) {
      Dialog.info('请输入姓名');
      return;
    }
    if(!authData.identity_card){
      Dialog.info('请输入身份证号');
      return;
    }
    Dialog.loading();
    general.axios.post('/user/user/editMemberInfo', {real_name:authData.real_name, identity_card:authData.identity_card})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        
        if (!status.succeed) {
          Dialog.info(status.error_desc);
        } else {
          Dialog.info("认证成功");
          setIsCheckAuth(false);
          // setUser({...user, isRealNameAuth: true});
          setUser({...user, ...{real_name: authData.real_name, identity_card: authData.identity_card, isRealNameAuth: true, isAdult: data.isAdult}});
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
    })

  const cardReal = e => {
    if (user.real_name || user.identity_card) {
      e.preventDefault();
      e.stopPropagation();
      Dialog.info('已认证');
    } else {
      setIsCheckAuth(true);
    }
  }

  return <div className="user-profile">
    <div className="group">
      <div className="item">
        <div className="name">
          个人头像
          {user.avatar_status === '0' ? <span>（审核中）</span> : null}
          {user.avatar_status === '-1' ? <span>（审核未通过）</span> : null}
        </div>
        <div className="operate">
          <Image src={user.avatar_status === '-1'? '/resources/user/avatar.png' :user.icon_link} alt="个人头像" className="avatar"/>
        </div>
        <input type="file" onChange={avatar}/>
      </div>
    </div>
    <div className="group">
      <div className="item">
        <div className="name">账号</div>
        <div className="operate">
          {user.member_name}
        </div>
      </div>
      <Link to="/user/nickname" className="item">
        <div className="name">昵称</div>
        <div className="operate">
          {user.nick_name ? user.nick_name : '去设置'}
          <RightOutlined className="icon" />
        </div>
      </Link>
      <Link to="/user/qq" className="item">
        <div className="name">QQ</div>
        <div className="operate">
          {user.qq ? user.qq : '去设置'}
          <RightOutlined className="icon" />
        </div>
      </Link>
      <Link to="/user/phone" className="item" onClick={phone}>
        <div className="name">手机绑定</div>
        <div className="operate">
          {user.mobile ? user.mobile : '去绑定'}
          <RightOutlined className="icon" />
        </div>
      </Link>
      {app.is_overseas && <div className="item" onClick={e=>{
        if (!user.email){
          history.push("/user/email")
        }
      }}>
        <div className="name">邮箱绑定</div>
        <div className="operate">
          {user.email ? user.email : '去绑定'}
          <RightOutlined className="icon" />
        </div>
      </div>}
    </div>
    <div className="group">
      {/* <Link to="/user/realname" className="item" onClick={e => {
        if (user.real_name || user.identity_card) {
          e.preventDefault();
          e.stopPropagation();
          Dialog.info('已认证');
        }
      }}>
        <div className="name">实名认证</div>
        <div className="operate" >
          {user.real_name || user.identity_card ? <>{user.real_name.replace(/(.*)(.)$/, '$1*')},{user.identity_card.replace(/^(\d{4})\d{10}(\d+)/, '$1********$2')}</> : '去认证'}
          <RightOutlined className="icon" />
        </div>
      </Link> */}
      <Link to="/user/password" className="item">
        <div className="name">登录密码</div>
        <div className="operate">
          修改密码
          <RightOutlined className="icon" />
        </div>
      </Link>
      <div onClick={cardReal} className="item">
        <div className="name">实名认证</div>
        <div className="operate" >
          {user.real_name || user.identity_card ? <>{user.real_name.replace(/(.*)(.)$/, '$1*')},{user.identity_card.replace(/^(\d{4})\d{10}(\d+)/, '$1********$2')}</> : '去认证'}
          <RightOutlined className="icon" />
        </div>
      </div>
      <Link to="/user/cancelaccount" className="item">
        <div className="name">账号管理</div>
        <div className="operate">
          账号注销
          <RightOutlined className="icon" />
        </div>
      </Link>
    </div>
    <div className="user-btn" onClick={() => Dialog.confirm({title: '确定退出登录吗？', yes: logout})}>退出登录</div>

    {/***实名认证弹框***/}
    {isCheckAuth?
    <div className="pay-check-auth">
        <div className="auth-index">
          <div className="close" onClick={() => setIsCheckAuth(false)}>
            <img src="/resources/user/close.png" />
          </div>
          <div className="title">
            <div className="title-img">
              <img src="/resources/user/auth-title.png" />
            </div>
            <div>
              <h3>实名认证</h3>
              <p>
              {config.authTopTips}
                {/* 根据国家新闻出版署最新规定，游戏用户需要进行实名认证，否则我们无法为您提供游戏及支付服务！ */}
              </p>
            </div>
          </div>

          <div className="auth-user-realname">
            <div className="auth-user-input">
              <div className="name">真实姓名</div>
              <input name="nick_name" type="text" placeholder="请输入姓名" autocomplete="off" value={authData.real_name} onChange={e => setAuthData({...authData, real_name: e.target.value})} />
            </div>
            <div className="auth-user-input">
              <div className="name">身份证号</div>
              <input name="number" type="text" placeholder="请输入身份证号" autocomplete="off" value={authData.identity_card} onChange={e => setAuthData({...authData, identity_card: e.target.value})} />
            </div>
            <span>
            {config.authBottomTips}
            {/* 您所提交的身份信息仅用于国家认证，我们将按照国家隐私条例严格保密信息，感谢您的支持！ */}
            </span>
            <div className="auth-user-btn" onClick={certifiy}>立即认证</div>
          </div>
        </div>
      </div> : null
      }
  </div>;
};