import {useEffect, useState} from "react";
import general from "../../../general";
import {CloseOutlined, LeftOutlined} from "@ant-design/icons";
import {prod, url} from "../../../env";
import {useLocalStorageState, useToggle, useLockFn} from "ahooks";
import useActiveState from "../../../lib/useActiveState";
import Dialog from "../../../lib/Dialog";
import URI from "urijs";
import MobileDetect from "mobile-detect";
import {NavLink} from "../../../lib/Navigation";
import useUrlState from "@ahooksjs/use-url-state";
import { Popup } from 'antd-mobile'
import './css/ AlertLogin.css'

const mobileDetect = new MobileDetect(navigator.userAgent);
function randomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10);
      break;
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
      break;
    default:
      return 0;
      break;
  }
}
let rand = randomNum(100000000, 999999999);

export default function Register(props) {

  const [app] = useActiveState('app');
  const [focus, setFocus] = useState('');
  const [password, { toggle: togglePassword }] = useToggle(false);
  const [repeatPassword, { toggle: toggleRepeatPassword }] = useToggle(true);
  const [data, setData] = useState(!prod ? {
    username: rand,
    password: rand,
    // repeatPassword: rand,
  } : {});
  const [config, setConfig] = useActiveState('config');
  const [agreement, setAgreement] = useState(true);
  const [registering, setRegistering] = useState(false);
  const [datas, setDatas] = useLocalStorageState('users', []);
  const [user, setUser] = useActiveState('user');
  const [agent] = useActiveState('agent');
  const [tk, setTk] = useActiveState('tk');
  const [tkUrl, setTkUrl] = useActiveState('tkUrl');
  const [tkStatus, setTkStatus] = useLocalStorageState('register-tk-status', 0);
  const [params, setParams] = useUrlState({}, { navigateMode: 'replace' });
  const [whether, setWhether] = useActiveState('whether');//是否进行过退出操作
  const [autoLogin, setAutoLogin] = useState(false)
  const [visiable,setVisiable] = useState(true)
  const [visibility,setVisibility] = useState('visible')
  const userAgreement = `${url}/userAgreement`;
  const privacyPolicy = `${url}/privacyPolicy`;

  useEffect(()=>{    
    const handleBack = PubSub.subscribe('alert-back',()=>{
      if (parseInt(props.registerAgreement) == 2){
        setVisibility('visible')
      }
    })

    return ()=>{
      PubSub.unsubscribe(handleBack)
    }
  },[data,props.registerAgreement])

  const register = () => {
    if (!data.username) {
      Dialog.error('请输入用户名');
      return;
    }
    if (!data.password) {
      Dialog.error('请输入密码');
      return;
    }
    // if (!data.repeatPassword) {
    //   Dialog.error('请确认密码');
    //   return;
    // }
    // if (data.password !== data.repeatPassword) {
    //   Dialog.error('两次输入的密码不一致');
    //   return;
    // }
    if (!agreement) {
      // Dialog.error('请阅读下方的协议');
      // Dialog.info('请先阅读并同意《用户协议》和《隐私政策》');
      setVisible(true)
      return;
    }
    if (registering) {
      return;
    }

    // if(agent == 'rr2f') {
    //   Dialog.info("当前无法完成注册，请联系客服进行处理！");
    //   return;
    // }

    data.regtype = '5';
    // data.tk = tk;
    // data.tk_url = tkUrl;

    let location = window.location.href;
    if (window.location.host == 'webbox.99maiyou.cn') {
      if (params.agent) {
        location = window.location.href
      } else {
        location = window.location.href + "?agent=" + agent;
      }
    }

    data.location_href = agent == 'rr2f' ? location : '';

    setRegistering(true);
    Dialog.loading();
    general.axios.post('/user/user/register', data)
      .finally(() => {
        setRegistering(false);
        Dialog.close();
      })
      .then(response => {
        let { status, data: result } = response.data;
        console.log()
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          localStorage.setItem('token', result.token);
          localStorage.setItem('user_id', result.user_id);
          localStorage.setItem('username', data.username);
          setDatas([...datas, { username: data.username, password: data.password }]);
          // Dialog.success('注册成功', () => history.push('/phone'));
          // setTk('');
          // setTkUrl('');
          // setTkStatus(1);
          setWhether('true');
          Dialog.success('注册成功');
          load_load()
          // setUser({isLogin: true, member_id: result.user_id});

          //临时测试记录--渠道号不一致
          if (agent == 'rr2f') {
            // temporaryAdjuct();
          }

        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const load_load = () => {
    general.axios.post('/user/user/getMemberInfo')
      .finally(() => {
      })
      .then(response => {
        let { status, data } = response.data;
        if (!status.succeed) {

        } else {
          localStorage.setItem('yx_account',data.member_info.yx_account)
          localStorage.setItem('yx_token',data.member_info.yx_token)
          setUser({ isLogin: true, ...data.member_info });
          props.dismiss()
          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['AccountPasswordRegisterSuccess', 'CLK', { age: data.member_info.age, sex: data.member_info.sex, level: data.member_info.vip_level, username: data.member_info.username, agent: agent }]
          });

          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['register', 'CLK', { age: data.member_info.age, sex: data.member_info.sex, level: data.member_info.vip_level, username: data.member_info.username, agent: agent }]
          });
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const updateAgreement = () => {
    let a = agreement == true ? false : (agreement == false ? true : false);
    setAgreement(a);
  }

  return (
    <div>
      <Popup className={"alert-login-index " + visibility}
        visible={visiable}
        bodyStyle={{
          borderTopLeftRadius: '13px',
          borderTopRightRadius: '13px',
          overflow: 'hidden',
        }}>
        <LeftOutlined className="alert-back" onClick={() => {
            props.login()
          }}></LeftOutlined>
        <div className="alert-login-header">账号注册</div>
        <div className="alert-login-content">
          <div>
            <div className="input-item">
              {/* <div className="label">账号</div> */}
              <div className="input auth-input">
                <input name="username" type="text" value={data.username} placeholder={app.is_overseas ? '請輸入帳號/Email/手機號' : "请输入账号"} autoComplete={false}
                  onFocus={e => setFocus('username')} onChange={e => setData({ ...data, username: e.target.value })} />
                {focus === 'username' && data.username ?
                  <CloseOutlined className="assist close" onClick={() => setData({ ...data, username: '' })} /> : null}
              </div>
            </div>
            <div className="input-item">
              {/* <div className="label">密码</div> */}
              <div className="input auth-input">
                <input name="password" type={password ? 'password' : 'text'} value={data.password} placeholder={app.is_overseas ? '請輸入密碼' : "请输入密码"}
                  onFocus={e => setFocus('password')} onChange={e => setData({ ...data, password: e.target.value })} />
                {focus === 'password' && data.password ?
                  <CloseOutlined className="assist close" onClick={() => setData(data => ({ ...data, password: '' }))} /> : null}
              </div>
            </div>
            <div className="register"></div>
          </div>
          <div className={'operate ' + (registering ? 'disable' : '')} onClick={register}>确认注册</div>
          <div className='autologin'>已有账号<span onClick={()=>props.login()}>去登录{'>'}</span></div>
          <div className="account-agreement">
            <span className={'agreement' + (agreement ? ' checked' : '')} onClick={updateAgreement} />
            <label>
              登录即代表您同意
              <NavLink onClick={()=>{
                props.handleRegisterAgreement(2)
                setVisibility('hidden')
                }} to={(() => {
                let url = URI(userAgreement)
                  .addQuery('title', '用户协议')
                  .addQuery('channel', agent)
                  .addQuery('device_type', (mobileDetect.os() == 'iOS' ? 10 : 20))
                  .toString();
                return url;
              })()}
                iframe={{ title: '《用户协议》' }}
                user={false}
              >《用户协议》</NavLink>
              <NavLink onClick={()=>{
                  props.handleRegisterAgreement(2)
                  setVisibility('hidden')
                }} to={(() => {
                  let url = URI(privacyPolicy)
                  .addQuery('title', '隐私政策')
                  .addQuery('channel', agent)
                  .addQuery('device_type', (mobileDetect.os() == 'iOS' ? 10 : 20))
                  .toString();
                return url;
              })()}
                iframe={{ title: '《隐私政策》' }}
                user={false}
              >《隐私政策》</NavLink>
              <br />
              接受免除或者限制责任、诉讼管辖约定等粗体标示条款
            </label>
          </div>
        </div>
      </Popup>
    </div>
  )
}
