import React, { useEffect, useRef, useState } from 'react'
import { Swiper, Tabs } from 'antd-mobile'
import './css/records.css'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Dialog from '../../../lib/Dialog';
import general from '../../../general';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const tabItems = [
    { key: "voucher", title: "代金券" },
    { key: "gift", title: "礼包" },
  ];

export default function Records(props) {

    const [activeIndex, setActiveIndex] = useState(0);
    const [voucher,setVoucher] = useState([])
    const [gift,setGift] = useState([])
    const swiperRef = useRef()
    const history = useHistory()

    const load = ()=>{
        general.axios.request('/getVoucherGiftList')
        .then(res=>{
            const {status,data} = res.data
            if (status.succeed){
                const {list:{voucher,gift}} = data
                setVoucher(voucher)
                setGift(gift)
            }else {
                Dialog.error(status.error_desc)
            }
        })
    }

    useEffect(()=>{
        if (props.active){
            load()
        }
    },[props.active])

  return (
    <div className='records-index'>
        <div className='records-container'>
            <div className='records-head'>
                <Tabs 
                    style={{
                        '--active-title-color':'#282828',
                        '--active-line-color':'#FFC000',
                        '--title-font-size':'14px',
                        '--content-padding':'0px'
                    }}
                    activeKey={tabItems[activeIndex].key}
                    onChange={key => {
                        const index = tabItems.findIndex(item => item.key == key)
                        setActiveIndex(index)
                        swiperRef.current?.swipeTo(index)
                    }}>
                    {tabItems.map((item,index)=>(
                        <Tabs.Tab title={item.title} key={item.key}></Tabs.Tab>
                    ))}
                </Tabs>
            </div>
            <div className='records-body'>
                <Swiper 
                    ref={swiperRef}
                    onIndexChange={index => {
                        setActiveIndex(index)
                    }}
                    defaultIndex={activeIndex}
                    indicator={()=>null}>{tabItems.map((item,index)=>(
                    <Swiper.Item key={index}>
                        <div className='records-list'>
                            { index == 0 ? 
                                voucher.map((item,index)=><div key={index} className='records-item'>
                                <img src="resources/task/代金券.png" alt="" />
                                <div className='records-item-content'>
                                    <div className="title">满{item.amount}可用</div>
                                    <div className="gamename">仅限【{item.name}】使用</div>
                                    <div className="time">{moment(item.start_time * 1000).format('YYYY.MM.DD')}-{moment(item.end_time * 1000).format('YYYY.MM.DD')} 有限期{((parseInt(item.end_time)-parseInt(item.start_time))/24/3600).toFixed(0)}天</div>
                                </div>
                                <div onClick={()=>{
                                    if (parseInt(item.is_used) == 1){
                                        return
                                    }
                                    history.push(`/game?id=${item.game_id}`)
                                }} className={"action " + (parseInt(item.is_used) == 1 ? 'disable' : '')}>{parseInt(item.is_used) == 1 ? "已使用" : "去使用"}</div>
                            </div>) : 
                            gift.map((item,index)=><div key={index} className='records-gift-item'>
                                <div className='gift-container'>
                                    <img src="resources/task/礼包.png" alt="" />
                                    <div className='records-item-content'>
                                        <div className="gamename">{item.packname}</div>
                                        <div className="time">{moment(item.starttime * 1000).format('YYYY.MM.DD')}-{moment(item.endtime * 1000).format('YYYY.MM.DD')} 有限期{((parseInt(item.endtime)-parseInt(item.starttime))/24/3600).toFixed(0)}天</div>
                                    </div>
                                </div>
                                <div className='gift-action'>
                                    <div className="gift-name">礼包码 {item.card}</div>
                                    <CopyToClipboard onCopy={() => Dialog.info('已复制礼包码')} text={item.card}>
                                        <div className="gift-copy">一键复制</div>
                                   </CopyToClipboard>
                                </div>
                            </div>)}
                            { 
                                index == 0 ? (voucher.length == 0 ? <div className="empty">
                                    <img className="icon" src="resources/hangup/placeholder.png" alt="" />
                                    <div className="desc">暂无代金券</div>
                                </div> : null ) : (gift.length == 0 ? <div className="empty">
                                    <img className="icon" src="resources/hangup/placeholder.png" alt="" />
                                    <div className="desc">暂无礼包</div>
                                </div> : null )
                            }
                        </div>
                    </Swiper.Item>
                ))}</Swiper>
            </div>
        </div>
    </div>
  )
}
