import React, {useEffect, useState} from "react";
import {useLockFn, useMount, useScroll} from "ahooks";
import Dialog from "../../lib/Dialog";
import general from "../../general";
import $ from "jquery";

export default ({active}) => {
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const load = useLockFn(async (parms = {}) => {
    Dialog.loading();
    await general.axios.post('/vip/getMoneySavingCardHistory', {pagination: {count: 20, page: parms.page || page}})
        .finally(() => {
          Dialog.close();
        })
        .then(response => {
          let {status, data} = response.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          setLoaded(true);
          parms.page && setPage(parms.page);
          let {paginated, dataList: list} = data;
          setDatas(datas => parms.page === 1 || !parms.page ? list : [...datas, ...list]);
          setMore(paginated.more && list.length > 0);
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });
  useMount(load);

  const scroll = useScroll();
  useEffect(() => {
    if (active) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 >= $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, active]);

  return <div className="card-record">
    <div className="thead">
      <div className="th">省钱卡</div>
      <div className="th">金额</div>
      <div className="th">有效期</div>
    </div>
    <div className="tbody">
      {datas.map(data => <div className="tr">
        <div className="td">{data.goods_type}</div>
        <div className="td">{data.amount}</div>
        <div className="td">{data.desc}</div>
      </div>)}
      {datas.length === 0 && loaded ? <div className="recovery-empty"><img src="/resources/save/card_record_empty.png" alt=""/>暂无购买记录</div> : null}
    </div>
  </div>;
};