import React, { useState, useEffect } from 'react'
import { Popup, Image } from 'antd-mobile'
import useActiveState from '../../lib/useActiveState'
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import Dialog from '../../lib/Dialog'
import './css/Mask.css'
import { inQQ, inWechat } from '../wechat/Index';

export default function DownMask() {

  const [app] = useActiveState('app');
  const [index,setIndex] = useState(0)

  useEffect(() => {
    if (index == 0) {
      setTimeout(() => {
        setIndex(1)
      }, 3000);
    }else if (index == 1) {
      setTimeout(() => {
        setIndex(2)
      }, 3000);
    }else if (index == 2) {
      setTimeout(() => {
        setIndex(3)
      }, 3000);
    }else {
      setTimeout(() => {
        setIndex(0)
      }, 3000);
    }
    
  }, [index])

  return (
    <div style={{
      position:'relative',
    }}>
      <Image height={178} src={`resources/mask/down${index+1}.gif`}/>
      <div className="index" style={{
        position: 'absolute',
        left: '0',
        top: '0',
        backgroundColor: '#3573f0',
        color: 'white',
        fontSize: '12px',
        borderTopLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        padding: '1px 3px',
      }}>
        第{index+1}步
      </div>
      <div style={{
        marginTop:'15px',fontSize:'13px',paddingBottom:'5px'
      }}>如遇到打开游戏需开启开发者模式，请按照以上步骤操作</div>
      <div style={{
        margin:'0 auto',
        textAlign:'center',
        color:'#3573f0',
        textDecoration:'underline'
      }} onClick={()=>{
        localStorage.setItem('down-cash',true)
      }}>我已开启,不再提醒</div>
    </div>
  )
}
