import {useEffect, useState} from "react";
import general from "../../general";
import {useLockFn, useScroll, useUpdateEffect} from "ahooks";
import UserPhoneVerify from "../util/UserPhoneVerify";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import {CSSTransition} from "react-transition-group";

export default props => {
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [confirm, setConfirm] = useState();
  const [confirmed, setConfirmed] = useState();
  const load = useLockFn(async () => {
    Dialog.loading();
    await general.axios.post('/recyclableAlts', {gameId: props.query.id})
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data: {list}} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            if (list.length > 0) {
              let amount = 0;
              list.forEach(item => {
                amount += item.recycledCoin;
              });
              setData({
                ...list[0].game,
                ...general.game,
                count: list.length,
                amount: amount,
              });
            } else {
              setData({});
            }
            setDatas(list);
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });
  useEffect(() => {
    load();
  }, []);

  const scroll = useScroll();
  useUpdateEffect(() => {
    if (props.active) {
      if (scroll.top < -80) {
        load();
      }
    }
  }, [scroll, props.active]);

  const [verify, setVerify] = useState();
  const recovery = useLockFn(async () => {
    let id = [];
    datas.forEach(item => {
      if (item.checked) {
        id.push(item.id);
      }
    });
    if (id.length < 1) {
      Dialog.error('请选择小号');
      return;
    }
    // if (!verify) {
    //   setVerify(true);
    //   return;
    // }

    if(!confirmed) {
      Dialog.info("请先选择同意“我已阅读”");
      return;
    }
    Dialog.loading();
    await general.axios.post('/recycleAlt', {id: id})
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            setConfirm(false);
            Dialog.success('回收成功', () => {
              setVerify(false);
              load();
            });
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });

  const validas = () => {
    if (datas.findIndex(item => item.checked) == -1) {
      Dialog.error('请选择小号');
      return
    }
    
    if (!verify) {
      setVerify(true);
      return;
    }
  }

  const agreeOpate = () => {
    let a = confirmed == true ? false : (confirmed == false ? true : false);
    setConfirmed(a);
  }

  return <div className="recovery-detail">
    <div className="recovery-info">
      <div className="icon"><Image src={data.img?.thumb} alt=""/></div>
      <div className="info">
        <div className="name">{data.name}</div>
        <div className="desc">
          满足回收小号:<span>{data.count || 0}</span>个
          可回收总金额:<span>{parseFloat(data.amount).toFixed(1) || 0}</span>金币
        </div>
      </div>
    </div>
    <div className="recovery-thead">
      <div className="th">小号昵称</div>
      <div className="th">累计金额</div>
      <div className="th">可回收金额</div>
      <div className="th">操作</div>
    </div>
    <div className="recovery-tbody">
      {datas.map((item, index) => <div className="tr">
        <div className="td">{item.alias}</div>
        <div className="td">{item.rechargedAmount}元</div>
        <div className="td">{item.recycledCoin}金币</div>
        <div className="td"><input type="checkbox" value="1" checked={item.checked} onChange={e => {
          setDatas(datas => {
            datas[index].checked = e.target.checked;
            return [...datas];
          });
        }}/></div>
      </div>)}
    </div>
    <div className="recovery-operate">
      <div className={'btn' + (datas.findIndex(item => item.checked) !== -1 ? ' active' : '')} onClick={validas}>确认回收</div>
    </div>
    {/* <UserPhoneVerify show={verify} type="05" close={() => setVerify(false)} onSuccess={recovery}/> */}
    <UserPhoneVerify show={verify} type="05" close={() => setVerify(false)} onSuccess={()=>{setConfirm(true)}}/>

    <CSSTransition in={confirm} timeout={250} unmountOnExit={true}>
        <div className="recovery-buyread">
          <div className="container" style={{backgroundImage: 'url(/resources/trade/confirm-bg.png)'}}>
            <div className="head">
              <div className="title">确认回收小号</div>
              <div className="info">你正在回收{data.count || 0}个小号,回收金额{data.amount || 0}金币</div>
            </div>
            <div className="main">
              <div className="title"><img src="/resources/trade/title-2.png" alt=""/>回收须知</div>
              <div className="body">
                <p>累计充值满100元以上小号，可按实际充值5%金额回收金币。</p>
                <p>回收后72小时内可赎回，逾期不可赎回。</p>
                <p>赎回小号仅支持现金赎回，需额外支付该小号充值金额的3%作为赎回手续费。(例: 若小号充值满100元，回收后可获得50金币。若用户需要赎回，则需支付5+3=8元)。</p>
                <p>游戏关服超过3个月的游戏不支持回收功能，例: 9月13日关服，最迟回收日期为12月13日，超过日期后将不可回收。</p>
                <p>充值仅计算2019年6月1日后小号实际充值部分(金币与免费代金券充值不计算在内)。</p>
                <p>如有任何疑问，可联系客服咨询。</p>
              </div>
            </div>
            <div className="operate">
              <div className="input">
                {/* <input type="radio" onChange={agreeOpate}/> */}
                <span className={'agreement' + (confirmed ? ' checked' : '')}  onClick={agreeOpate}/>
                我已阅读
              </div>
              <div className="btns">
                <div className="think" onClick={() => {
                  setConfirm(false);
                  setConfirmed(false);
                  setVerify(false);
                }}>
                  我再想想
                </div>
                <div className={'buy' + (!confirmed ? ' disable' : '') } onClick={recovery}>
                  确认回收
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
  </div>;
};