import {useEffect, useState} from "react";
import {useInterval} from "ahooks";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import URI from "urijs";

function randomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10);
      break;
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
      break;
    default:
      return 0;
      break;
  }
}

export default props => {
  useEffect(() => {
    if (props.query.title) {
      props.setTitle(props.query.title);
    }
  }, [props.query]);
  const [progress, setProgress] = useState(randomNum(25, 50));
  const [interval, setInterval] = useState(250);
  useInterval(() => {
    if (progress === 100) {
      setInterval(null);
    } else {
      setProgress(progress + randomNum(0, (100 - progress) / 2));
    }
  }, interval);
  // console.log(props.query.url);

  // console.log(props);
  return <div className={"view-iframe" + (props.query.gamefullscreen && props.query.gamefullscreen == 1  ? ' iframe-roam-screen': (props.query.url.indexOf('gamefullscreen') != '-1' ? '  iframe-roam-screen' : '' ))}>
    <div className={'progress ' + (progress === 100 ? 'loaded' : 'loading')} style={{width: progress + '%'}}/>
    <iframe src={props.query.url} onLoad={() => setProgress(100)}/>
  </div>;
};