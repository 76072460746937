import React, {useEffect, useState} from "react";
import UserPhoneVerify from "../util/UserPhoneVerify";
import {useLockFn} from "ahooks";
import general from "../../general";
import {useHistory} from "react-router-dom";
import {prod} from "../../env";
import Dialog from "../../lib/Dialog";
import ImagePicker from "../util/ImagePicker";
import {CSSTransition} from "react-transition-group";
import useActiveState from "../../lib/useActiveState";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

export default props => {
  const history = useHistory();
  const [user] = useActiveState('user');
  const [data, setData] = useState({
    game_uploadedshots: [],
    ...(!prod ? {
      server_name: '跨三B',
      sell_price: '917',
      title: '测试标题',
      content: '测试内容'
    } : {})
  });
  const [imgs, setImgs] = useState([]);
  const [games, setGames] = useState([]);
  const [eyeInvisible,setEyeInvisible] = useState(true)

  const gameLoad = useLockFn(async () => {
    Dialog.loading();
    await general.axios.post('/tradedGameList', {pagination: {count: 917917, page: 1}})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setGames(data.game_list || []);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });
  useEffect(() => {
    gameLoad();
  }, []);
  const [alts, setAlts] = useState([]);
  const altLoad = useLockFn(async () => {
    if (!data.game_id) {
      Dialog.error('请先选择游戏');
      return;
    }
    Dialog.loading();
    await general.axios.post('/base/game/getSelfXhUserNameList', {game_id: data.game_id, pagination: {count: 917917, page: 1}})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setAlts(data.xh_list);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });
  useEffect(() => {
    if (data.game_id) {
      altLoad();
    } else {
      setAlts([]);
    }
  }, [data.game_id]);

  const priceSell = (e) => {
    let value = e.target.value.replace(/[^\d]/, '')
    setData({...data, sell_price: value})
  }

  const [confirm, setConfirm] = useState(true);
  const [confirmed, setConfirmed] = useState(false);

  const [verify, setVerify] = useState();
  const submit = useLockFn(async () => {
    if(!data.game_id) {
      Dialog.info("请选择游戏");
      return;
    }
    if(!data.xh_username) {
      Dialog.info("请选择小号");
      return;
    }
    if(!data.server_name) {
      Dialog.info("请输入游戏区服");
      return;
    }
    if(!data.sell_price) {
      Dialog.info("请输入出售价");
      return;
    }
    if(!data.title) {
      Dialog.info("请输入标题");
      return;
    }

    if(data.title.length > 12) {
      Dialog.info("标题不能超过12个字符");
      return;
    }

    if(data.sell_price < 6) {
      Dialog.info("出售价格不能低于6元");
      return;
    }

    if(imgs.length < 3 || imgs.length > 5) {
      // Dialog.info("请上传3-5张游戏截图");
      Dialog.info("至少选择3张游戏截图");
      return;
    }
    if (!verify) {
      setVerify(true);
      return;
    }
    Dialog.loading();
    Dialog.info('开始图片上传');
    for (let i = 0; i < imgs.length; i++) {
      let formData = new FormData();
      formData.append('type', 'trade');
      formData.append('imageData', imgs[i].file);
      try {
        await general.axios.post('/user/trade/uploadImagePublicOss', formData)
          .then(res => {
            let {status, data: result} = res.data;
            if (!status.succeed) {
              Dialog.error(status.error_desc);
            } else {
              data.game_uploadedshots.push(result.imageUrl);
              setData({...data});
              Dialog.info('上传图片' + (data.game_uploadedshots.length) + '/' + imgs.length);
            }
          });
      } catch (e) {
        Dialog.error(e.message);
        return;
      }
    }
    let formData = new FormData();
    for (let name in data) {
      if (name === 'game_uploadedshots') {
        for (let i = 0; i < data[name].length; i++) {
          formData.append(name + '[]', data[name][i]);
        }
      } else {
        formData.append(name, data[name]);
      }
    }
    // Dialog.loading('正在提交');
    Dialog.info('正在提交');
    await general.axios.post('/user/trade/submitTradeDetail', formData)
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          Dialog.success('提交成功', () => {
            setVerify(false);
            history.goBack();
          });
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  const load = () => {
    general.axios.post('/user/trade/getTradeDetail', {trade_id: props.query.id})
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            setData({...data, game_id: data.game_info.game_id, xh_username: data.xh_username, server_name: data.server_name, sell_price: data.sell_price, title: data.title, content: data.content, second_level_pwd: data.second_level_pwd});
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  useEffect(() => {
    if(props.query.id) {
      setConfirm(false);
      load();
    }
  }, [props.query.id])

  const updateAgreement = () => {
    let a = confirmed == true ? false : (confirmed == false ? true : false);
    setConfirmed(a);
  }

  return <div className="trade-submit">
    <div className="trade-body">
      <div className="trade-input">
        <div className="name">选择游戏</div>
        <div className="value">
          {games.find(game => game.game_id === data.game_id)?.game_name || '选择 >'}
          <select onChange={e => setData({...data, game_id: e.target.value})}>
            <option value="">请选择</option>
            {games.map(game => <option value={game.game_id} selected={game.game_id === data.game_id}>{game.game_name}</option>)}
          </select>
        </div>
      </div>
      <div className="trade-input">
        <div className="name">选择小号</div>
        <div className="value">
          {alts.find(alt => alt.xh_username === data.xh_username)?.xh_alias || '选择 >'}
          <select onChange={e => setData({...data, xh_username: e.target.value})}>
            <option value="">请选择</option>
            {alts.map(alt => <option value={alt.xh_username} selected={alt.xh_username === data.xh_username}>{alt.xh_alias}</option>)}
          </select>
        </div>
      </div>
      <div className="trade-input">
        <div className="name">游戏区服</div>
        <div className="value"><input type="text" placeholder="请输入游戏区服" value={data.server_name} onChange={e => setData({...data, server_name: e.target.value})}/></div>
      </div>
      <div className="trade-input">
        <div className="name">出售价</div>
        <div className="value"><input type="number" placeholder="不低于6元" value={data.sell_price} onChange={priceSell}/></div>
      </div>
      <div className="trade-input">
        <div className="name">出售可得（手续费{user?.tradeRate * 100}%,最低5元）</div>
        <div className="value">
          {(() => {
            let value = data.sell_price * 10;
            // let fee = value * 0.05;
            let fee = value * user?.tradeRate;
            if (fee < 50) {
              fee = 50;
            }

            let number = 0;
            if(Number(value) >= Number(fee)) {
              number = value - fee || 0;
            }
            
            return number;
          })()}
          平台币
        </div>
      </div>
      <div className="trade-input">
        <div className="name">标题</div>
        <div className="value"><input type="text" placeholder="请输入标题(限12个字)" value={data.title} onChange={e => setData({...data, title: (e.target.value.length >= 12 ? e.target.value.slice(0,12) : e.target.value)})}/></div>
      </div>
      <div className="trade-input develop">
        <div className="name">商品描述（选填）</div>
        <div className="value">
          <textarea rows="3" placeholder="可描述角色等级、装备、属性，完善的描述可快速有效促成交易。" value={data.content} onChange={e => setData({...data, content: e.target.value})}/>
        </div>
      </div>
      <div className="trade-input develop">
        <div className="name">二级密码（有则必填）</div>
        <div className="value flex">
          <input type={eyeInvisible ? "password" : 'text'} 
                 placeholder="例如：仓库密码，此密码仅审核人员及最终买家可见。" 
                 value={data.second_level_pwd} 
                 onChange={e => setData({...data, second_level_pwd: e.target.value})}/>
          <div onClick={()=>setEyeInvisible(!eyeInvisible)}>
            {eyeInvisible ? <EyeInvisibleOutlined/> : <EyeOutlined/>}
          </div>
        </div>
      </div>
      <div className="trade-input develop">
        <div className="name">上传图片请上传3-5张游戏截图</div>
        <div className="value">
          <ImagePicker multiple={true} max={5} files={imgs} setFiles={setImgs}/>
        </div>
      </div>
      <div className="trade-tips">
        <p><span>温馨提示：</span>亲爱的玩家，为了快速审核交易信息，请上传以下截图：人物属性界面、VIP充值等级或特权界面、背包仓库或宠物属性界面。</p>
        <p><span>备注：</span>由于游戏排行榜会因为时间关系导致最终排行误差，为避免造成买卖双方纠纷，上传排行描述及相关截图的一律不通过审核！</p>
      </div>
      <div className="trade-operate">
        <div className="btn" onClick={submit}>确认出售</div>
      </div>
    </div>
    <CSSTransition in={confirm} timeout={250} appear={true} unmountOnExit={true}>
      <div className="trade-confirm">
        <div className="container" style={{backgroundImage: 'url(/resources/trade/confirm-bg.png)'}}>
          <div className="head">
            <div className="title">交易细则</div>
            <div className="info">为了您的消费权益，请仔细阅读</div>
          </div>
          <div className="main">
            <div className="title"><img src="/resources/trade/title-1.png" alt=""/>出售流程</div>
            <div className="process">
              <img src="/resources/trade/process.png" alt=""/>
              <ul className="clearfix">
                <li>上传商品</li>
                <li>审核冻结</li>
                <li>买家购买</li>
                <li>出售成功</li>
              </ul>
            </div>
            <div className="title"><img src="/resources/trade/title-2.png" alt=""/>卖家须知</div>
            <div className="body">
              <p>现金充值满6元才可交易</p>
              <p>提交出售申请后，待售小号在游戏中所有区服的角色都将被冻结待售，无法登录。</p>
              <p>审核不成功或您选择下架商品，可重新登录游戏获得小号。</p>
              <p>出售时显示累计金额为该小号在该游戏同一小号下多个区服的角色所有累计充值。</p>
              <p>小号出售成功后，该小号在本游戏中所有区服的角色会一同售出，但不影响该小号在其他游戏中的角色。</p>
              <p>出售成功将收取{user?.tradeRate * 100}%的手续费（最低5元），剩余收益以平台币形式转至您的账号下。平台币不可提现，仅能用于游戏充值。</p>
              <p>交易完成后，不支持找回。</p>
              <p>提交的小号在审核通过之后，若7天之内未完成交易，则自动下架退回。</p>
              <p>当游戏发布停服公告或游戏问题临时下架后，该游戏的小号不论是否上架都将无法交易，且该游戏已经上架的小号将自动下架。</p>
              <p>卖家须保证所有提供信息属实，因虚假信息导致的纠纷，由卖家承担责任。</p>
              <p>卖家须知：因卖家出售过程中操作失误产生的一切损失，由卖家自行承担，平台概不负责。</p>
            </div>
          </div>
          <div className="operate">
            <div className="input">
              {/* <input type="radio" onChange={() => updateAgreement()}/> */}
              <span className={'agreement' + (confirmed ? ' checked' : '')}  onClick={updateAgreement}/>
              我已阅读</div>
            <div className={'buy' + (!confirmed ? ' disable' : '')} onClick={() => confirmed && setConfirm(false)}>我记住了</div>
          </div>
        </div>
      </div>
    </CSSTransition>
    <UserPhoneVerify show={verify} type="06" close={() => setVerify(false)} onSuccess={submit}/>
  </div>;
};