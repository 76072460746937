import React, {useEffect, useState, useRef, useLayoutEffect} from "react";
import moment from "moment";
import {NavLink} from "../../../lib/Navigation";
import {Link} from "react-router-dom";
import {useSize} from "ahooks";
import {QuestionCircleOutlined, SyncOutlined} from "@ant-design/icons";
import Dialog from "../../../lib/Dialog";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import general from "../../../general";
import Image from "../../../lib/Image";
import {CSSTransition} from "react-transition-group";
import {useUpdate} from "ahooks";
import useUrlState from "@ahooksjs/use-url-state";
import useActiveState from "../../../lib/useActiveState";
import Manager from "../../components/Manager";


export default props => {
  const update = useUpdate();
  // const [game, setGame] = useState(general.game);
  const [game, setGame] = useState([]);
  const [router, setRouter] = useState(0);
  const [gifts, setGifts] = useState([]);
  const [czb, setCzb] = useState([]);
  const [vipGift, setVipGift] = useState([]);
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});
  const [routeStateOne, setRouteStateOne] = useState([]);
  const [routeStateTwo, setRouteStateTwo] = useState([]);
  const [routeStateThree, setRouteStateThree] = useState([]);

  useEffect(() => {
    setCzb(game?.filter(gift => (
      parseInt(gift.recharge_amount) > 0)
    ));
    setVipGift(game?.filter(gift => (
      gift.vip_level > 0)
    ));
    setGifts(game?.filter(gift => (
            (router === 0 && parseInt(gift.recharge_amount) === 0 && gift.vip_level == '')
            || (router === 1 && parseInt(gift.recharge_amount) > 0 && gift.vip_level == '')
            || (router === 2 && gift.vip_level > 0)
            )
            
        )
    );
    // props.setOperate(<NavLink to="/user/gift" className="operate">我的礼包</NavLink>);
  }, [game, router]);

  useEffect(() => {
    setRouteStateOne(game?.filter(gift => (
      parseInt(gift.recharge_amount) === 0 && gift.vip_level == '')
    ));
    setRouteStateTwo(game?.filter(gift => (
      parseInt(gift.recharge_amount) > 0 && gift.vip_level == '')
    ));
    setRouteStateThree(game?.filter(gift => (
      gift.vip_level > 0)
    ));
  }, [game]);

  useEffect(() => {
    if(routeStateOne.length > 0) {
      setRouter(0);
    } else if(routeStateTwo.length > 0) {
      setRouter(1);
    } else if(routeStateThree.length > 0) {
      setRouter(2);
    } else {
      setRouter(0);
    }
  }, [routeStateOne, routeStateTwo, routeStateThree]);

  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let data = {};

    data.game_id = props.game.game_id;
    // if (props.query.id) {
    //   data.game_id = props.query.id;
    // } else if(params.id){
    //   data.game_id = params.id;
    // } else {
    //   if(game?.game_id) {
    //     data.game_id = props.game.game_id;
    //   } else {
    //     Dialog.error('缺少参数', 1, () => history.goBack);
    //     return;
    //   }
    // }

    if(!data.game_id) {
      Dialog.error('缺少参数', 1, () => history.goBack);
      return;
    }

    general.axios.post('/base/packs/getPackList', data)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setInit(true);
          setGame(data);


          // data.map(item => {
          //   if(parseInt(item.recharge_amount) == 0 && item.vip_level == '') {
          //     setRouter(0);
          //     setRouteState(true);
          //   } 
          // });
          // if(!routeState) {
          //   data?.map(gift => {
          //     if(parseInt(gift.recharge_amount) > 0 && gift.vip_level == '') {
          //       setRouter(1);
          //       setRouteState(true);
          //     } 
          //   })
          // }
          // if(!routeState) {
          //   data?.map(gift => {
          //     if(gift.vip_level > 0) {
          //       setRouter(2);
          //       setRouteState(true);
          //     }
          //   })
          // }
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);

  useEffect(() => {
    // props.setOperate(<NavLink to="/user/gift" className="operate">我的礼包</NavLink>);
  }, [props.active]);

  const [giftMoreState, setGiftMoreState] = useState(false);

  const [rebateMore, setRebateMore] = useState(true);
  const [rebateMored, setRebateMored] = useState(false);
  const rebateBodyRef = useRef();
  const rebateEntityRef = useRef();
  const rebateBodySize = useSize(rebateBodyRef);
  const rebateEntitySize = useSize(rebateEntityRef);
  useEffect(() => {
    if(rebateEntitySize.height && rebateBodySize.height) {
      !rebateMored && setRebateMore(rebateEntitySize.height > rebateBodySize.height);
    }
  }, [rebateBodySize, rebateEntitySize]);

  const [teseState, setTeseState] = useState(false);
  useEffect(() => {
    if(game.game_feature_list) {
      if(game.game_feature_list[1].content.trim()) {
        setTeseState(true);
      }
    }
  }, [game.game_feature_list]);

  const [gift, setGift] = useState([]);
  const [giftShow, setGiftShow] = useState();
  const [giftCode, setGiftCode] = useState();
  const [giftDescInfoImage, setGiftDescInfoImage] = useState();
  const [giftBag, setGiftBag] = useState([]);
  const [remindState, setRemindState] = useState(false);
  const [user, setUser] = useActiveState('user');
  const [errorMessage, setErrorMessage] = useState();


  const giftShowNewWindow = item => {
    Dialog.loading();

    general.axios.post('/base/gift/getGiftDetail', {gift_id: item.packid})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        let descData = data.gift_info;
        setGift(data.gift_info);
        setGiftDescInfoImage(descData.new_image.thumb);
        setGiftShow(true);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const giftReceive = gift => {

    // console.log(gift);
    // console.log(user);
    // if(gift.gift_type == '4' && user.vip_level == '') {
    //   setGiftShow(false);
    //   setRemindState(true);
    //   return;
    // }
    Dialog.loading();
    general.axios.post('/queryGameAccount', {maiyou_gameid: game.maiyou_gameid})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        if(data.isExists) {
          Dialog.loading();
          general.axios.post('/base/game/getReceiveGift', {gift_id: gift.gift_id})
            .finally(() => {
              Dialog.close();
            })
            .then(res => {
              let {status, data} = res.data;

              if (!status.succeed) {
                if(status.error_code == null || status.error_code == 2001) {
                  setGiftShow(false);
                  setRemindState(true);
                  setErrorMessage(status.error_desc);
                } else {
                  Dialog.error(status.error_desc);
                }
              } else {
                setGiftShow(false);
                setGiftCode(data);
                
                // let info = gifts;
                // gifts[giftBag].is_received = true;
                // setGifts(info);
                // update();
                load();
              }
            })
            .catch(err => {
              console.log("123456");
              if(err.error_code == null) {
                setGiftShow(false);
                setRemindState(true);
                setErrorMessage(err.message);
              } else {
                Dialog.error(err.message);
              }
            });
        } else {
        //   setTrumpetState(true);
        }
      })
      .catch(err => {
        console.log("123");
        Dialog.error(err.message);
      });
  };

  return <div><div className="gift-index">
    <div className="gift-list">
      <div className="head">
        <div className="items clearfix">
          <div className={'item ' + (router == 0 ? 'active' : '')} onClick={() => {setRouter(0);Manager.push({event_id:'D-3-1'})}}>免费礼包</div>
          <div className={'item ' + (router == 1 ? 'active' : '')} onClick={() => {setRouter(1);Manager.push({event_id:'D-3-2'})}}>充值礼包</div>
          <div className={'item ' + (router == 2 ? 'active' : '')} onClick={() => {setRouter(2);Manager.push({event_id:'D-3-3'})}}>会员礼包</div>
          {/* {
            czb.length > 0 ? 
            <div className={'item ' + (router == 1 ? 'active' : '')} onClick={() => setRouter(1)}>充值礼包</div>
            : null
          }
          {
            vipGift.length > 0 ?
            <div className={'item ' + (router == 2 ? 'active' : '')} onClick={() => setRouter(2)}>会员礼包</div>
            : null
          } */}
        </div>
      </div>
      <div className={"body " + (giftMoreState ? "data-more" : "")}>
        {gifts.length === 0 && init ? <div className="empty"><img src="/resources/empty.png" alt=""/>暂无礼包</div> : null}
        {gifts.map((gift, index) => <div className={"item "} onClick={() => {
        }}>
          <div className="info">
            <div className="name">
              {gift.packname}
              {gift.vip_level > 0 ? 
                <div className="">
                  <div className="exclusive-new" style={{backgroundImage:'url(/resources/game/vip-exclusive-new.png)'}}>
                    {gift.vip_level_desc}
                  </div>
                </div>
              : null}
            </div>
            <div className="desc">
              {gift.packcontent}
              <br/>
              有效期：{moment(gift.starttime * 1000).format('YYYY-MM-DD')} 至 {moment(gift.endtime * 1000).format('YYYY-MM-DD')}
            </div>
          </div>
          {gift.is_received ? 
            <CopyToClipboard className="operate" text={gift.unicode_card ? gift.unicode_card : gift.pack_card} onCopy={() => Dialog.info('已复制')}>
              <div>复制</div>
            </CopyToClipboard>
          :
            <NavLink className="operate" onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              giftShowNewWindow(gift);
              setGiftBag(index);
            }}>领取</NavLink>
          }
        </div>)}
      </div>
    </div>

    {remindState ? 
      <div className="vip-remind-index">
        <div className="remind-site">
          <div className="remind-main">
            <div className="close" onClick={()=>setRemindState(false)}>
              <img src="/resources/user/close.png" />
            </div>
              <div className="remind-title">
              温馨提示
              </div>
              <div className="info">
                <p>{errorMessage}</p>
              </div>
              {router == 2 ?
              <div className="remind-btn">
                <NavLink to="/save/newvip" className="item btn-confirm" onClick={()=>setRemindState(false)}>
                  开通会员
                </NavLink>
              </div>
              : <div className="remind-btn">
              <div className="item btn-confirm" onClick={()=>setRemindState(false)}>
                我知道了
              </div>
            </div>}
          </div>
        </div>
      </div>
    : null}
  </div>

  <CSSTransition in={giftShow} timeout={250} unmountOnExit={true}>
        <div className="gift-detail">
          <div className="gift-mask" onClick={() => setGiftShow(false)}/>
          <div className="gift-container">
            <div className="gift-top">
              <div className="icon">
              <Image src={giftDescInfoImage}/>
              </div>
              <div className="text">
                <div className="name">{gift.gift_name}</div>
                <div className="time">有效期：{moment(gift.gift_dealine * 1000).format('YYYY-MM-DD')}</div>
              </div>
            </div>
            <div className="gift-main">
              <div className="gift-desc" style={{borderBottom:"1px solid #ededed",padding:"0 0 10px 0"}}>
                <div className="title">礼包说明</div>
                <div className="main">{gift.gift_desc}</div>
              </div>
              <div className="gift-desc" style={{borderBottom:"1px solid #ededed",padding:"10px 0"}}>
                <div className="title">使用说明</div>
                <div className="main">{gift.gift_introduce}</div>
              </div>
            </div>
            <NavLink className="gift-bottom" onClick={()=>giftReceive(gift)}>领取</NavLink>
          </div>
        </div>
      </CSSTransition>
    {giftCode ? <div className="dialog active">
      <div className="dialog-mask"/>
      <div className="dialog-content">
        <div className="dialog-modal">
          <div className="modal-body">
            <div className="modal-title">领取成功</div>
            <div className="modal-content">{giftCode}</div>
          </div>
          <CopyToClipboard text={giftCode} onCopy={() => Dialog.info('已复制')}>
          <div className="modal-btns" onClick={() => setGiftCode(null)}>
            <div className="modal-btn">
                <div >复制</div>
            </div>
          </div>
          </CopyToClipboard>
        </div>
      </div>
    </div> : null}

  </div>;
};