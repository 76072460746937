import React, {useEffect, useState} from "react";
import general from "../../../general";
import {Link} from "react-router-dom";
import moment from "moment";
import $ from "jquery";
import {PlayCircleOutlined} from '@ant-design/icons';
import {useScroll} from "ahooks";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import {NavLink} from "../../../lib/Navigation";
import useActiveState from '../../../lib/useActiveState';
import Subtitle from "../../components/Subtitle";

export default props => {
  const {aplus_queue} = window;
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [games, setGames] = useState([]);
  const [more, setMore] = useState(true);
  const [user] = useActiveState('user');
  const [agent] = useActiveState('agent');
  const [randomColorInfo] = useActiveState('randomColorInfo');
  
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (!init) {
      Dialog.loading();
    }
    let tmp = {
      pagination: {page: page},
    };
    if (parms.page) {
      tmp.pagination = {page: parms.page};
    }
    general.axios.post('/reservedGames', tmp)
        .finally(() => {
          setLoading(false);
          Dialog.close();
          parms.finishPullUp && parms.finishPullUp();
          parms.finishPullDown && parms.finishPullDown();
        })
        .then(response => {
          setInit(true);
          let result = response.data.data;
          if (parms.page) {
            setPage(parms.page);
          }

          result.game_list.forEach(d => {
            d.infoColor = randomColorInfo[parseInt(Math.random() * 4)]
          })
          setGames(games => parms.page === 1 || !parms.page ? result.game_list : [...games, ...result.game_list]);
          setMore(result.paginated.more);
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  useEffect(() => {
    if (props.active) {
      load();
    }
  }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 > $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, props.active]);

  const clickGameOfTheNewGame = (game) => {
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['ClickGameOfTheNewGame', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), tabname: '新游预约', gamename:game.game_name, agent: agent}]
    });
  }

  const reserve = (game) => {    
    general.axios.post(game.is_reserved ? '/cancelReserveGame' : '/reserveGame', {id: game.maiyou_gameid})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let result = res.data;
        if (!result.status.succeed) {
          Dialog.error(result.status.error_desc);
        } else {
          let message = game.is_reserved ? '取消成功' : '预约成功';
          Dialog.success(message);
          load()
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  };


  return <div className="new-reserve">
  {games.map(game => <div className="new-reserve-item">
    <Link to={'/game?id=' + game.game_id} className="subject" onClick={()=>clickGameOfTheNewGame(game)}>
      <div className="icon">
        <Image src={game.game_image.thumb}/>
        {
          game?.lablePosition == 2 && game.top_lable != '' ?
          <div className="iconlable jx" style={{backgroundImage: 'linear-gradient('+game?.lableColor.t+', '+game?.lableColor.b+')'}}>
            {game.top_lable}
          </div>
          : null
        }
      </div>
      <div className="text">
        <div className="name">
          <div className={"named " + (game.nameRemark ? " name-new" : "")}>{game.game_name}</div>
          {/* {game.nameRemark != '' ? <div className="nameRemark-new">{game.nameRemark}</div> : null} */}
          {/* <div className="date">{moment(game.starting_time * 1000).format('MM-DD HH:mm')}首发</div> */}
          <div className="date">{game.label}</div>
        </div>
        <div className="info1">
          
          {game.game_classify_type.trim()}
          <Subtitle>{game.nameRemark}</Subtitle>
          {/* <div className="reserve">{game.reserved_total}人预约</div> */}
          {/* <div className="date">{game.label}</div> */}
        </div>
        {/* <div className="info2">
          {game.game_desc ? <>{game.game_desc?.split('+').map(item => <div className="tag">{item}</div>)}</> : <><span style={{color:game.infoColor}}>{game.introduction}</span></>}
        </div> */}
        <div className="info3">
          {
            game.bottom_lable ? 
            <>
            {Object.keys(game.bottom_lable).length > 0 ? 
            <div >
              <span className="first-text" style={{background:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.left_content}</span><span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
            </div> : null }
            </>
            : null
          }
          {/* {game.exclusive_game_describe?.length > 0 ? <div className="speed">{game.exclusive_game_describe}</div> : null}
          {game.is_support_accele ? <div className="speed">可加速</div> : null}
          {game.is_easy_play ? <div className="speed">省心玩</div> : null} */}
          {game.introduction ? <span style={{color:'#888'}}>{game.introduction}</span> : null}          
          </div>
      </div>
      
      <div className={"btn " + (game.is_reserved ? 'disable' : '')} onClick={e=>{
        // e.preventDefault()
        // e.stopPropagation()
        // reserve(game)
      }}>{game.is_reserved ? "已预约" : "预约"}</div>
    </Link>
    <div className="medias clearfix">
      <Link to={'/video?url=' + game.video_url} className="media">
        <div className="container">
          <Image src={game.game_ur_list[0]} alt=""/>
          <PlayCircleOutlined className="play"/>
        </div>
      </Link>
      {[game.game_ur_list[1],game.game_ur_list[2],game.game_ur_list[3],game.game_ur_list[4]].map((img, index, imgs) => img ? <Link className="media" to={'/img?imgs=' + imgs + '&img=' + index}>
        <Image src={img} alt=""/>
      </Link> : null)}
    </div>
  </div>)}
  {games.length === 0 && init ? <div className="reserve-empty"><img src="/resources/game/report_empty.png" alt=""/>暂时还未预约游戏哦~</div> : null}
  {games.length > 6 && !more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
</div>;
};