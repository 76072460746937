import {Link, useHistory} from "react-router-dom";
import general from "../../general";
import useActiveState from "../../lib/useActiveState";
import {useLockFn} from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import {RightOutlined} from '@ant-design/icons';
import {useLocalStorageState} from "ahooks";
import { useState } from "react";
import MobileDetect from "mobile-detect";
import URI from "urijs";
import {NavLink} from "../../lib/Navigation";

const mobileDetect = new MobileDetect(navigator.userAgent);

export default props => {
  const history = useHistory();
  let [agent, setAgent] = useActiveState('agent');
  let [app, setApp] = useActiveState('app');
  const [user, setUser] = useActiveState('user');
  const [config] = useActiveState('config');
  const [agreement, setAgreement] = useState(false);

  const [isCheckAuth, setIsCheckAuth] = useState(false);
  const [data, setData] = useState({});
  const [loging, setLoging] = useState(false);

  //选中
  const updateAgreement = () => {
    let a = agreement == true ? false : (agreement == false ? true : false);
    setAgreement(a);
  }

  const confirm = () => {
    if (!data.password) {
      Dialog.error('请输入密码');
      return;
    }

    // if(localStorage.getItem('passwords') !== data.password) {
    //   Dialog.error("密码错误");
    //   return;
    // }

    Dialog.loading();

    general.axios.post('/user/user/login', data)
      .finally(() => {
        setLoging(false);
        Dialog.close();
      })
      .then(res => {
        let {status, data: result} = res.data;
        if (!status.succeed) {
          Dialog.error("密码错误");
        } else {
          setIsCheckAuth(false);
          history.push('/user/assetaccount');
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const nextOperate = () => {
    if(!agreement) {
      Dialog.info("请先阅读并同意《咪噜游戏账号注销须知》");
      return;
    }
    setIsCheckAuth(true);
  }

  return <div className="user-cancelaccount">
    <div className="group">
      <div className="item">
        <div className="title">
          请确认您的账号是否满足以下注销条件：
        </div>
        <div className="desc one-text">
          <h2>
            账号处于安全状态
          </h2>
          <p>
            最近一个月内无绑定手机、修改手机号、无密码找回、修改密码等操作账号为正常使用中且一年内无任何账号被限制的记录。
          </p>
        </div>
        <div className="desc two-text">
          <h2>
            账号财产已结清
          </h2>
          <p>
            没有资产，包括未结清的平台币、金币，VIP、省钱卡服务时间未结束，无进行中的账号交易（优惠券不计算在内）。
          </p>
        </div>
      </div>
    </div>
    <div className="account-agreement">
      <label>
        <span className={'agreement' + (agreement ? ' checked' : '')}  onClick={updateAgreement}/>
        我已阅读并同意
        <NavLink to={(() => {
            let url = URI('//api3.app.99maiyou.com/accountCancel/notice')
                .addQuery('title', app.appname + '账号注销须知')
                .addQuery('channel', agent)
                .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: app.appname + '账号注销须知'}}
            user={false}
          >《{app.appname}账号注销须知》</NavLink>
        <br/>
      </label>
    </div>

    <div className="user-btn" onClick={nextOperate}>下一步</div>

    {/***验证密码***/}
    {isCheckAuth ?
      <div className="check-account">
        <div className="auth-index">
          <div className="close" onClick={() => setIsCheckAuth(false)}>
            <img src="/resources/user/close.png" />
          </div>
          <div className="">
            <div className="title">
              请填写登录密码验证
            </div>
            <div className="account-info">
              账号：{user.member_name}
            </div>
            <div className="check-input">
              <p>
              <input name="password" type='text' value={data.password} placeholder="请在此输入密码" autoComplete="off" onChange={e => setData({...data, password: e.target.value})}/>
              </p>
            </div>
          </div>
          <div className="user-btn" onClick={confirm}>确定</div>
        </div>
      </div> : null
    }
  </div>;
};