import React, {useEffect, useState} from "react";
import general from "../../general";
import {Link} from "react-router-dom";
import $ from "jquery";
import {useScroll, useMount, useLockFn} from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";

export default ({active, type}) => {
  const [page, setPage] = useState(1);
  const [games, setGames] = useState([]);
  const [more, setMore] = useState(true);
  const [obsolete, setObsolete] = useState();
  const load = useLockFn(async (parms = {}) => {
    Dialog.loading();
    let tmp = {
      type: 2,
      game_species_type: type,
      pagination: {count: 20, page: page},
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    await general.axios.post('/base/game/getGameList', tmp)
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setObsolete(false);
        parms.page && setPage(parms.page);
        let {game_list: list, paginated} = data;
        setGames(games => parms.page === 1 || !parms.page ? list : [...games, ...list]);
        setMore(paginated.more && list.length > 0);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  useMount(() => load());
  useEffect(() => {
    if (active) {
      load({page: 1});
    } else {
      setObsolete(true);
    }
  }, [type]);
  useEffect(() => {
    if (active && obsolete) {
      load({page: 1});
    }
  }, [active, obsolete]);

  const scroll = useScroll();
  useEffect(() => {
    if (active) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 > $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, active]);

  return <div className="game-hot">
    {games.length > 0 ? <>
      {/* <div className="game-hot-top" style={{backgroundImage: 'url(/resources/game/hot.png)'}}>
        {games.map((game, index) => index < 3 ? <Link to={'/game?id=' + game.game_id} className="game-hot-top-item">
          <div className="icon" style={{backgroundImage: 'url(/resources/game/hot' + (index + 1) + '.png)'}}>
            <Image src={game.game_image.thumb}/>
          </div>
          <div className="name">{game.game_name}</div>
          <div className="info">{game.game_classify_type.trim()}</div>
          <div className="btn">下载</div>
        </Link> : null)}
      </div> */}
      <div className="game-hot-list">
        {games.map((game, index) => <Link to={'/game?id=' + game.game_id} className="game-hot-list-item">
            <div className="index">
            {index < 3 ? <img src={'/resources/index/game-new-' + (index + 1) + '.png'}/> : index + 1}
          </div>
          {/* <div className="index">
            {index < 3 ? <img src={'/resources/index/new-' + (index + 1) + '.png'}/> : index + 1}
          </div> */}
          <div className="icon">
            <Image src={game.game_image.thumb}/>
            {/* {game.discount < 1 ? <div className="discount">{game.discount * 10}折</div> : null} */}
          </div>
          <div className="subject">
            <div className="text">
              <div className="name">{game.game_name}</div>
              <div className="info1">
                {game.game_classify_type.trim()}
                {game.game_species_type != 3 ? <>&nbsp;|&nbsp;{game.howManyPlay}人在玩</> : null}
              </div>
              <div className="info2">
                {game.game_desc ? game.game_desc.split('+').map(item => <div
                  className="tag">{item}</div>) : game.introduction}
              </div>
            </div>
            {/* <div className="btn">{game.game_species_type != 3 ? '下载' : '开始'}</div> */}
            {game.discount < 1 ? <div className="discount" ><span>{game.discount * 10}</span>折</div> : null}
          </div>
        </Link> )}
      </div>
    </> : null}
    {games.length > 0 && !more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
  </div>;
};