import React, {useState} from "react";
import {CloseOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import {prod} from "../../env";
import {useInterval, useLockFn} from "ahooks";
import general from "../../general";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";

export default props => {
  const history = useHistory();
  const [user, setUser] = useActiveState('user');
  const [data, setData] = useState(!prod ? {mobile: '12345678901', code: '123456'} : {});
  const [focus, setFocus] = useState('');

  const [coding, setCoding] = useState(false);
  const [codeTime, setCodeTime] = useState(0);
  useInterval(() => {
    if (codeTime) {
      setCodeTime(codeTime - 1);
    }
  }, 1000);
  const code = useLockFn(async () => {
    if (!data.mobile) {
      Dialog.error('请输入手机号');
      return;
    }
    if (coding || codeTime) {
      return;
    }
    setCoding(true);
    Dialog.loading();
    await general.axios.post('/base/tool/getVerifyCode', {
      mobile: data.mobile,
      code: 'code',
      type: '07',
    })
      .finally(() => {
        setCoding(false);
        Dialog.close();
      })
      .then(res => {
        let {status} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setCodeTime(60);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  const submit = useLockFn(async () => {
    if (!data.mobile) {
      Dialog.error('请输入手机号');
      return;
    }
    if (!data.code) {
      Dialog.error('请输入验证码');
      return;
    }
    Dialog.loading();
    await general.axios.post('/user/user/bindPhone', data)
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          Dialog.success('操作成功', () => {
            setUser({...user, mobile: null});
            history.goBack();
          });
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  return <div className="user-phoneremove">
    <div className="user-phoneremove-tips">输入绑定的手机号码和验证码，点击确定解绑手机号</div>
    <div className="user-input">
      <div className="name">手机号</div>
      <input name="mobile" type="text" value={data.mobile} placeholder={'请输入' + user.mobile + '手机号'} autoComplete="off" onFocus={() => setFocus('mobile')} onChange={e => setData({...data, mobile: e.target.value})}/>
      {focus === 'mobile' && data.mobile ? <CloseOutlined className="close" onClick={() => setData({...data, mobile: ''})}/> : null}
    </div>
    <div className="user-input">
      <div className="name">验证码</div>
      <input name="code" type="text" value={data.code} placeholder="请输入验证码" autoComplete="off" onFocus={() => setFocus('code')} onChange={e => setData({...data, code: e.target.value})}/>
      <div className={'btn ' + (coding || codeTime ? 'disable' : '')} onClick={code}>
        {coding ? '发送中' : (codeTime ? (codeTime + '秒后重发') : '发送验证码')}
      </div>
    </div>
    <div className="user-btn" onClick={submit}>确认解绑</div>
  </div>;
};