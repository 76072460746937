import {useEffect, useState} from "react";
import general from "../../general";
import moment from "moment";
import {NavLink} from "../../lib/Navigation";
import Dialog from "../../lib/Dialog";
import {Link, useHistory} from "react-router-dom";
import useActiveState from "../../lib/useActiveState";
import { useLockFn, useMount } from "ahooks";
import { EXITED } from "react-transition-group/transition";

export default props => {
  useEffect(() => {
    props.setOperate(<Link to="/user/bill?type=2" className="shop-index-guide">金币明细</Link>);
  }, []);

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState({});
  const [user] = useActiveState('user');
  const [amount, setAmount] = useState();
  const [init, setInit] = useState();
  const [page, setPage] = useState(1);
  const [obainState, setObainState] = useState();
  const [exchangeState, setExchangeState] = useState(false);
  const [balance, setBalance] = useState(0);
  const [balanceId, setBalanceId] = useState(0);

  const load = useLockFn(() => {
    Dialog.loading();
    general.axios.post('/GoldCoinMall/getIndexData', {})
      .finally(() => {
        Dialog.close();
      })
      .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setInit(true);
        setData(data);
        setAmount(data.balance);
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  })
  
  useMount(() => {
    load();
  })

  const exchange = item => {
    let balance_number = user.vip_level ? item.vipBalance : item.generalBalance;
    setBalance(balance_number);
    setBalanceId(item.id);
    setExchangeState(true);
  }

  const confirm = ()=>{
    Dialog.loading();
    general.axios.post('/GoldCoinMall/receive', {id:balanceId})
    .finally(() => {
      Dialog.close();
    })
    .then(response => {
      let {status, data} = response.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }

      Dialog.info("兑换成功");
      load();
      // let am = amount - data.amount;
      // setAmount(am)
      setExchangeState(false);
    })
    .catch(err => {
        Dialog.error(err.message);
      });
  }

  const refresh = useLockFn(()=>{
      load();
  })

  return <div className="shop-index" style={{backgroundImage: 'url(/resources/welfare/shop_bg_new.png)'}}>
    <div className="shop-user-head">
        <div className="items">
            <div className="item">
                <div className="coin-info">
                    <div className="icon">
                        <img src="/resources/welfare/shop-coin.png" />
                    </div>
                    <div className="text">
                        我的金币:{amount}
                    </div>
                    <div className="icon icon2" onClick={refresh}>
                        <img src="/resources/welfare/refresh.png" />
                    </div>
                </div>
                <div className="coin-desc ">
                    <span><Link to="/user/bill?type=2" className="btn"></Link></span>&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;<span onClick={()=>setObainState(true)}> 如何获得金币 </span>
                    {/* <span onClick={()=>setObainState(true)}> 如何获得金币 </span> */}
                </div>
            </div>
        </div>
    </div>

    <div className="shop-details">
        {
            data.list?.map(item =>
                <div className="item" style={{backgroundImage: 'url(/resources/welfare/coupon_bg_new.png)'}}>
                    <div className="info">
                        <div className="amount">
                            <div className="mj">{item.label}</div>
                            {item.amount}
                            <span className="tj">({item.useDesc})</span>
                        </div>
                        <div className="desc">
                        {item.desc}
                        </div>
                        <div className="time">
                        有效期：{item.days}天
                        </div>
                    </div>
                    <div className="operate">
                        <div className="">
                        非会员：{item.generalBalance}金币
                        </div>
                        <div className="">
                        会员价：{item.vipBalance}金币
                        </div>
                        <div className="operate-btn" onClick={()=>exchange(item)}>
                            兑换
                        </div>
                    </div>
                </div>
            )
        }
    </div>
    <div className="view-end">别撩啦！已经到底啦~</div>

    {
    exchangeState ? 
    <div className="exchange-index">
        <div className="exchange-site">
          <div className="exchange-main">
            <div className="close" onClick={() => setExchangeState(false)}>
              <img src="/resources/user/close.png" />
            </div>
              <div className="exchange-title">
              确认兑换
              </div>
              <div className="info">
                <p>你需要兑换此商品吗</p>
                <p className="des2">需要消耗{balance}金币</p>
              </div>
              <div className="exchange-btn">
                <div className="item btn-about" onClick={() => setExchangeState(false)}>
                  我再想想
                </div>
                <div className="item btn-confirm" onClick={confirm}>
                  确认兑换
                </div>
              </div>
          </div>
            
        </div>
    </div>
    : null }

    {/***如何获取金币 --弹框 */}
    {obainState ?
    <div className="obain-index">
      <div className="obain-site">
          <div className="obain-head" style={{backgroundImage: 'url(/resources/welfare/obtain.png)'}}>
            获取金币的几种方式
          </div>
          <div className="obain-main">
            <div className="text">
              <p>1.每日签到；</p>
              <p>2.新手任务/每日任务/成就任务/试玩任务；</p>
              <p>3.邀请好友赚金币；</p>
              <p>4.平台运营活动。</p>
            </div>
            <div className="obain-btn"  onClick={()=>setObainState(false)}>
              好的
            </div>
          </div>
      </div>
    </div>
    :null}
  </div>;
};