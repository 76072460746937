import React, {useEffect} from "react";
import general from "../../general";
import $ from "jquery";
import {useLocalStorageState, useLockFn, useReactive, useScroll} from "ahooks";
import KeepScroll from "../../lib/KeepScroll";
import moment from "moment";
import useActiveState from "../../lib/useActiveState";
import {AndroidOutlined, AppleOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";

const types = [
  {
    id: 'all',
    text: '全部',
  },
  {
    id: 'buy',
    text: '已购买',
  },
  {
    id: 'submit',
    text: '已提交',
  },
  {
    id: 'sold',
    text: '已出售',
  },
];

export default props => {
  const [user] = useActiveState('user');
  const [typeIndex, setTypeIndex] = useLocalStorageState('trade-type', 0);
  const datas = useReactive(types.map(item => ({
    id: item.id,
    text: item.text,
    init: false,
    loading: null,
    page: 1,
    more: null,
    datas: []
  })));
  const load = useLockFn(async (parms = {}) => {
    let data = datas[typeIndex];
    // console.log(data);
    if (data.loading) {
      return;
    }
    data.loading = true;
    Dialog.loading();
    let tmp = {
      pagination: {count: 20, page: data.page},
    };
    if (data.id === 'buy') {
      tmp.trade_type = '1';
      tmp.member_id = "";
      tmp.game_id = "";
      tmp.username="";
      tmp.buy_member_id = localStorage.getItem("user_id");
    }
    if (data.id === 'all') {
      tmp.trade_type = '0,1,2,3';
      tmp.buy_member_id = localStorage.getItem("user_id");
    }
    if (data.id === 'submit') {
      tmp.trade_type = '0,2,3';
    }
    if (data.id === 'sold') {
      tmp.trade_type = '1';
    }
    console.log(tmp);
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    await general.axios.post('/user/trade/getTradeList', tmp)
        .finally(() => {
          data.loading = false;
          Dialog.close();
        })
        .then(res => {
          let {status, data: result} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          data.init = true;
          if (parms.page) {
            data.page = parms.page;
          }
          // console.log(result.account_list);
          // console.log("123");
          data.datas = parms.page === 1 || !parms.page ? result.account_list : [...data.datas, ...result.account_list];
          data.more = result.paginated.more && result.account_list.length > 0;
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  })
  useEffect(() => {
    if (props.active && !datas[typeIndex].init) {
      load();
    }
  }, [props.active, typeIndex]);
  useEffect(() => {
    if (props.active) {
      load({page: 1});
    }
  }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    let data = datas[typeIndex];
    if (props.active && data.init) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 >= $(document).height() && data.init && data.more) {
        load({page: data.page + 1});
      }
    }
  }, [scroll, props.active]);

  const offShelf = useLockFn(async trade => {
    Dialog.loading();
    await general.axios.post('/user/trade/opetateTrade', {
      trade_id: trade.trade_id,
      trade_status: '-1',
      username: undefined,
    })
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            Dialog.success('下架成功', () => load({page: 1}));
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        })
  });

  // console.log(datas.datas);

  return <div className="trade-record">
    <div className="record-head">
      {types.map((item, index) => <div className={'item' + (index === typeIndex ? ' active' : '')} onClick={() => setTypeIndex(index)}>{item.text}</div>)}
    </div>
    <div className="record-body">
      {datas.map((type, index) =>
          <div className={'type-item type-item-' + type.id + (index === typeIndex ? ' active' : '')}>
            <KeepScroll active={index === typeIndex}>
              {type.id === 'submit' ? <div className="record-tips">温馨提示：左滑即可下架该交易</div> : null}
              <div className="items">
                {type.datas.map(trade => <Link to={'/trade/detail?id=' + trade.trade_id} className={'item' + (trade.operable ? ' operable' : '')} onTouchMove={e => {
                  if (type.id !== 'submit') {
                    return false;
                  }
                  if (!trade.start) {
                    trade.start = e.changedTouches[0].pageX
                  } else {
                    let diff = e.changedTouches[0].pageX - trade.start;
                    if (diff > 0) {
                      trade.operable = false;
                    } else if (diff < 0) {
                      trade.operable = true;
                    }
                  }
                }}>
                  <div className="body">
                    <div className="main">
                      <div className="icon"><Image src={trade.account_icon}/></div>
                      <div className="info">
                        <div className="name">
                          {/* <div className={'type type-' + trade.game_species_type}/>
                          {trade.game_name} */}
                          <div className={" " + (trade.nameRemark ? " name-new" : " original")}>
                            {/* <div className={'type type-' + trade.game_species_type}/> */}
                            <div className="gamename">
                              {trade.game_name}
                            </div>
                          </div>
                          {trade?.nameRemark != '' ? <div className="nameRemark-new-info">{trade.nameRemark}</div> : null}
                        </div>
                        {/* {
                          trade.nameRemark ?
                          <span className="nameRemark">
                            {trade?.nameRemark}
                          </span>
                          : null
                        } */}
                        <div className={"title "}>
                          {/* {trade.game_device_type != 1 ? <AppleOutlined className="ios"/> : null} */}
                          {/* {trade.game_device_type != 2 ? <AndroidOutlined className="android"/> : null} */}
                          {trade.title}
                        </div>
                      </div>
                    </div>
                    <div className="data">
                      {typeIndex == 0 || typeIndex == 2 ? 
                      <div className="time">上架时间：{moment(trade.trade_time * 1000).format('YYYY-MM-DD HH:mm:ss')}</div>
                      : 
                      <div className="time">上架时间：{moment(trade.sell_time * 1000).format('YYYY-MM-DD HH:mm:ss')}</div>
                      }
                      <div className="prices">
                        <div className="amount">{trade.total_amount}</div>
                        <div className="price">{trade.trade_price}</div>
                      </div>
                    </div>
                  </div>
                  <div className="operate" onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    Dialog.dialog({
                          mask: trade,
                          title: '温馨提示',
                          content: '确定下架吗？',
                          btns: [
                            {text: '取消'},
                            {text: '确定', onPress: () => offShelf(trade)}
                          ]
                        }
                    );
                  }}>下架
                  </div>
                </Link>)}
              </div>
              {type.datas.length === 0 && type.init ? <div className="recovery-empty"><img src="/resources/recovery/empty.png" alt=""/>暂无数据</div> : null}
            </KeepScroll>
          </div>)}
    </div>
  </div>;
};