import React from 'react'
import general from '../../../general'
import useActiveState from '../../../lib/useActiveState'
import Manager from '../Manager';

export default function AllSearch(props) {

  const [homeSearch,setHomeSearch] = useActiveState('homeSearch');

  const handleSearch = (kw)=>{
    Manager.push({ event_id:props.item.event_id })
    setHomeSearch(kw)
    general.history.push('/search')
  }

  return (
    <div className='index-all-search'>
      <div className='index-all-search-header-icon'>
        <img src={props.item.title_image} alt="" />
      </div>
      <div className='index-all-search-container'>
        {props.item?.list_y?.map(item=>{
          return (
            <div key={item} className="index-all-search-container-item" onClick={()=>handleSearch(item)}>
              <div className='key'>{item}</div>
              <img width={12} height={12} src="resources/index/search.png" alt="" />
            </div>
          )
        })}
      </div>
    </div>
  )
}
