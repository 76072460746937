import {Player} from 'video-react';
import {useLocalStorageState} from "ahooks";

export default props => {
  const [muted, setMuted] = useLocalStorageState('video-muted', false);

  return <div className="view-video">
    <Player autoPlay={true} playsInline={true} muted={muted} onVolumeChange={e => setMuted(e.target.muted)} fluid={false} width="100%" height="100%">
      <source src={props.query.url}/>
    </Player>
  </div>;
};