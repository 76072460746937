import { Mask } from 'antd-mobile'
import React, { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component'
import general from '../../../general'
import Dialog from '../../../lib/Dialog'

export default function ReceiveAlert(props) {

  const [visible,setVisible] = useState(props.visible)
  const [count,setCount] = useState(0)
  const receive_pack = props.receiveData?.receive_pack
  const receive_voucher = props.receiveData?.receive_voucher

  useEffect(()=>{
    if(receive_pack){
      setCount((e)=>e+1)
    }
    if(receive_voucher){
      setCount((e)=>e+1)
    }
  },[receive_pack,receive_voucher])

  const close = () => {
    if(props.action){
      props.action()
    }
  }

  return (
    <div className='receive-alert'>
      <Mask visible={visible} onMaskClick={() => setVisible(false)}>
        <div className="alert">
          <div className="container">
            <div className="title">成功领取{count}个648福利</div>
            {receive_voucher && <div className="item">
              <img src="resources/game/sign/voucher.png" className='icon' height={46} alt="" />
              <div className="content">
                <div className="key">{receive_voucher.voucher_title}</div>
                <div className="desc">有效期至{receive_voucher.date}</div>
              </div>
            </div>}

            {receive_pack && <div className="item">
              <img src="resources/game/sign/gift.png" className='icon' height={46} alt="" />
              <div className="content">
                <div className="key">{receive_pack.packname}</div>
                <div className="desc">
                  <div className='pack'>礼包码：{receive_pack.packcard}</div>
                  <CopyToClipboard text={receive_pack.packcard} onCopy={()=>{Dialog.info('复制成功')}}>
                    <div className="copy">复制</div>
                  </CopyToClipboard>
                </div>
              </div>
            </div>}
            <div className="open" onClick={()=>{
              close()
              setTimeout(()=>{
                general.history.push('/game?id=' + props.receiveData.game_id)
              },50)
            }}>打开游戏</div>
          </div>
          <img className='close' onClick={close} src="resources/game/sign/close.png" alt="" />
        </div>
      </Mask>
    </div>
  )
}
