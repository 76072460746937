import {Link, useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import List from "./List";
import general from "../../general";
import {NavLink} from "../../lib/Navigation";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import { useScroll, useMount } from "ahooks";
import useActiveState from "../../lib/useActiveState";
import Manager from "../components/Manager";

const menus = [
  {
    id: 'submit',
    text: '我要卖号',
    url: '/trade/submit',
    user: true,
  },
  {
    id: 'recovery',
    text: '小号回收',
    url: '/recovery',
    user: true,
  },
  {
    id: 'record',
    text: '交易记录',
    url: '/trade/record',
    user: true,
  },
  {
    id: 'notice',
    text: '交易须知',
    url: '/trade/notice',
    user: true,
  },
  // {
  //   id: 'service',
  //   text: '联系客服',
  //   url: '/service',
  // }
];

export default props => {
  const {aplus_queue} = window;
  useEffect(() => {
    // props.setOperate(<Link to="/trade/notice" className="trade-index-notice">交易须知</Link>);
  }, []);
  const history = useHistory();
  const [trades, setTrades] = useState([]);
  const [opacity, setOpacity] = useState();
  const [titleInfo, setTitleInfo] = useState([]);
  const [user] = useActiveState('user');
  const [agent] = useActiveState('agent');
  const [app] = useActiveState('app');
  const [init, setInit] = useState();
  const [index, setIndex] = useState(0);
  const [title_items, setTitle_items] = useState([]);
  const [gfyx_top, set_gfyx_top] = useState(0);
  const [czjl_top, set_czjl_top] = useState(0);

  // const load = () => {
  //   Dialog.loading();
  //   general.axios.post('/user/trade/getTradeList', {
  //     member_id: undefined,
  //     username: undefined,
  //     game_device_type: 2,
  //   })
  //     .finally(() => {
  //       Dialog.close();
  //     })
  //     .then(response => {
  //       let {status, data} = response.data;
  //       if (!status.succeed) {
  //         Dialog.error(status.error_desc);
  //         return;
  //       }
  //       setInit(true);
  //       setTrades(data.featured_list);
  //       setTitleInfo(data.title_info);
  //     })
  //     .catch(err => {
  //         Dialog.error(err.message);
  //       });
  // }
  // useEffect(() => {
  //   if(props.active && !init) {
  //     load();
  //   }
  // }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    let opacity;
    if (props.active) {
      if (scroll.top) {
        opacity = Math.abs(scroll.top) / 200;
        if (opacity > 1) {
          opacity = 1;
        }
      } else {
        opacity = 0;
      }
    }
    setOpacity(opacity);

    if (props.active) {
      // if (scroll.top < -80) {
      //   load();
      // }
    }
  }, [scroll, props.active]);

  //跳转
  const bindHred = (item,index) => {
    Manager.push({event_id:`A-4-${index+2}`})
    if(item.id == 'submit' || item.id == 'recovery') {
      if (app.is_overseas){
        if (!user.email && !user.mobile){
          Dialog.confirm({
            title: '没有绑定邮箱，现在去绑定？',
            yes: () => {
              history.push('/user/email');
            }
          });
        }else{
          history.push(item.url);
        }
      }else{
        if (!user.mobile) {
          Dialog.confirm({
            title: '没有绑定手机号，现在去绑定？',
            yes: () => {
              history.push('/user/phone');
            }
          });
        } else {
          history.push(item.url);
        }
      }
    } else {
      history.push(item.url);
    }
  }

  useEffect(() => {
    if(props.active) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['BrowseAccountTransactionPage', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), agent: agent}]
      });
    }
  }, [props.active]);

  useEffect(()=>{
    const top = scroll.top
    if(index === 0){
      set_gfyx_top(top)
      if (gfyx_top <= 110){
        set_czjl_top(top)
      }
    }else{
      set_czjl_top(top)
      if (czjl_top <= 100){
        set_gfyx_top(top)
      }
    }
    // console.log(scroll,gfyx_top,czjl_top);
  },[scroll])

  return <div className="trade-index">
    <div className="trade-head width">
      <div className="background" style={{opacity: opacity !== undefined ? opacity : 1}}/>
      <div className="container">
        账号交易
        <NavLink onClick={()=>Manager.push({event_id:'A-4-1'})} user={false} className="service" to="/service">
          <img className="icon" src="resources/index/index-service-1.png" alt="" />
        </NavLink>
      </div>
    </div>
    <div className="trade-menu trade-screen-menu" style={{paddingTop:(app.is_overseas ? '5px' : 'calc(env(safe-area-inset-top) + 50px)')}}>
      {menus.map((item,index) => <NavLink key={index} user={item.user} className="item" onClick={()=>{bindHred(item,index)}}>
        <img src={'/resources/trade/' + item.id + '.png'} alt=""/>
        {item.text}
        
        {item.id == 'submit' ? 
        <div className="tips">
          <img src="/resources/trade/mxz.gif" />
        </div>
        : null}
      </NavLink>)}
    </div>
    {/* {props.active ?  */}
    <div className="trade-body">
      
      {title_items?.length > 0 ? <div className="trade-header" style={{top:(app.is_overseas ? '0px' : 'calc(env(safe-area-inset-top) + 40px)')}}>
        <div className={index === 0 ? 'active' : ''} onClick={()=>{
          Manager.push({event_id:'A-4-6'})
          setIndex(0);
          window.scrollTo(0,gfyx_top)
        }}>{title_items[0].name}</div>
        <div className={index === 1 ? 'active' : ''} onClick={()=>{
          Manager.push({event_id:'A-4-7'})
          setIndex(1);
          window.scrollTo(0,czjl_top)
        }}>{title_items[1].name}</div>
      </div> : null
      }

      {/* {index ==1 ? 
      <List active={index == 1} good={true} source='czjl' handleTitle={title=>setTitle_items(title)}/>: 
      <div>
        <List active={index == 0} good={false} source='' handleTitle={title=>setTitle_items(title)}/>
      </div>} */}

      <div>
        <div style={{display:index===1 ? 'none' : ''}}>
          <List active={index == 0 && props.active} good={false} source='' handleTitle={title=>setTitle_items(title)}/> 
        </div>
        <div style={{display:index===0 ? 'none' : ''}}>
          <List active={index == 1 && props.active} good={true} source='czjl' handleTitle={title=>setTitle_items(title)}/>
        </div>
      </div>

      {/* <div className="trade-index-good">
        <div className="title">
          <div className="text">{titleInfo.title}<img src={titleInfo.image} alt=""/></div>
          <Link to="/trade/good?source=czjl" className="more">更多</Link>
        </div>
        {trades? 
        <div className="items">
          {trades.map(trade => <Link to={'/trade/detail?id=' + trade.trade_id} className="item">
            <div className="icon"><Image src={trade.game_icon}/></div>
            <div className="info">
              <div className="title">{trade.game_name}</div>
              <div className="name">#{trade.title}#</div>
              <div className="price"><span>￥</span>{trade.trade_price}</div>
            </div>
          </Link>)}
        </div>
        : null}
      </div> */}
      {/* <List active={props.active}/> */}
    </div>
    
  </div>;
};