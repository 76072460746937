export default function osName() {
  const u = navigator.userAgent
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // 判断是否是 android终端
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // 判断是否是 iOS终端
  const isHarmony = u.indexOf('HarmonyOS') > -1 || u.indexOf('ArkWeb') > -1 // 判断是否是 鸿蒙终端
  if (isAndroid) {
    return 'Android'
  } else if (isIOS) {
    return 'iOS'
  } else if (isHarmony) {
    return 'Harmony'
  } else {
    return 'PC'
  }
}
