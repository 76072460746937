import React, {useEffect, useState} from "react";
import general from "../../general";
import {Link} from "react-router-dom";
import {useUpdateEffect, useLockFn} from "ahooks";
import {Swiper, SwiperSlide} from 'swiper/react';
import {HeartOutlined, MessageOutlined, ShareAltOutlined, DownloadOutlined, HeartFilled, PlayCircleOutlined} from "@ant-design/icons";
import moment from "moment";
import {NavLink} from "../../lib/Navigation";
import useActiveState from "../../lib/useActiveState";
import {setState} from "../../lib/reducer";
import {useDispatch} from "react-redux";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";

const players = {};

export default props => {
  // console.log(props);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setState({theme: props.active ? '' : undefined}));
  }, [props.active]);
  const [config] = useActiveState('config');
  const [user] = useActiveState('user');
  const [barrage, setBarrage] = useState(false);
  useUpdateEffect(() => {
    Dialog.info(barrage ? '弹幕已打开' : '弹幕已关闭');
  }, [barrage]);
  const [init, setInit] = useState(false);
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const load = useLockFn(async (parms = {}) => {
    Dialog.loading();
    let tmp = {
      member_id: undefined,
      username: undefined,
      pagination: {count: 20, page: page},
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios.post('/allGameVideoList', tmp)
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          setInit(true);
          if (parms.page) {
            setPage(parms.page);
          }
          let {paginated, game_list: list} = data;
          setDatas(datas => parms.page === 1 || !parms.page ? list : [...datas, ...list]);
          setMore(paginated.more && list.length > 0);
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active, init]);

  const [swiper, setSwiper] = useState(null);
  useEffect(() => {
    props.active && swiper && swiper.update();
  }, [props.active, swiper]);
  const [current, setCurrent] = useState(0);
  useEffect(() => {
      // console.log(players);
    for (const index in players) {
      if (parseInt(index) === current && props.active) {
        players[index] != null ? players[index].play().catch(err => {
        }) : '';
      } else {
        players[index] && players[index].pause();
      }
      if (Math.abs(parseInt(index) - current) > 3) {
        delete players[index];
      }
    }
    if (init && current + 5 >= datas.length && more) {
      load({page: page + 1});
    }
  }, [props.active, current]);

  const like = useLockFn(async index => {
    Dialog.loading();
    await general.axios.post('/likeVideo', {id: datas[index].maiyou_gameid})
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            datas[index].videoIsLiked = !datas[index].videoIsLiked;
            if (datas[index].videoIsLiked) {
              datas[index].videoLikeNum++;
            } else {
              datas[index].videoLikeNum--;
            }
            setDatas([...datas]);
            Dialog.info('操作成功');
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });

  const download = useLockFn(async game => {
    Dialog.loading();
    await general.axios.get(game.gama_url.ios_url.replace('http', 'https'))
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            window.location.href = data.url;
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });

  return <div className="index-video width">
    <div className={'video-operate ' + (props.active && props.operate ? 'active' : '')}>
      <div className="item" onClick={() => setTimeout(() => setBarrage(!barrage), 75)}>
        {barrage ? '关闭弹幕' : '打开弹幕'}
      </div>
      <Link to="/reservedGames" className="item">足迹</Link>
    </div>
    <Swiper className="video-body" direction="vertical" slidesPerView={1} onSwiper={setSwiper} onSlideChange={swiper => setCurrent(swiper.activeIndex)}>
      {datas.map((data, index) => <SwiperSlide className="video-item">
        <div className="body">
          <video
              src={data.video_url}
              webkit-playsinline="true"
              playsInline="true"
              ref={player => players[index] = player}
              onPlay={() => {
                datas[index].play = true;
                setDatas([...datas]);
              }}
              onPause={() => {
                datas[index].play = false;
                setDatas([...datas]);
              }}
              onClick={() => players[index].pause()}
          />
          {!data.play ? <div className="play" style={{backgroundImage: 'url(' + (data.play === undefined ? data.video_img_url : '') + ')'}} onClick={() => players[index].play()}><PlayCircleOutlined/></div> : null}
          <div className="operate">
            <NavLink className="item" onClick={() => like(index)}>
              {data.videoIsLiked ? <HeartFilled className="icon active"/> : <HeartOutlined className="icon"/>}
              {data.videoLikeNum}
            </NavLink>
            <NavLink to={'/game?id=' + data.game_id + '&page=3'} className="item"><MessageOutlined className="icon"/>{data.game_comment_num}</NavLink>
            {/* <NavLink to={'/invite=' + user.member_name + '&agent=' + config.agent + '&time=' + Number(moment().valueOf() * 1000)} iframe={{title: '邀请好友'}} token={false} className="item"><ShareAltOutlined className="icon"/>{data.videoShareNum}</NavLink> */}
            <NavLink to={'/invite'} className="item"><ShareAltOutlined className="icon"/>{data.videoShareNum}</NavLink>
          </div>
        </div>
        <Link to={'/game?id=' + data.game_id} className="game">
          <div className="icon"><Image src={data.game_image.thumb} alt=""/></div>
          <div className="info">
            <div className="name">{data.game_name}</div>
            <div className="desc">
              {/* {data.game_download_num}次下载  */}
              {data.game_classify_type}
              {data.game_species_type != 3 ? <>&nbsp;|&nbsp;{data.howManyPlay}人在玩</> : null}
            </div>
            <div className="tags">{data.game_desc?.split('+')?.map(item => <div className="tag">{item}</div>)}</div>
          </div>
          <div className="btn" onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            download(data);
          }}><DownloadOutlined className="icon"/>{data.gama_size.ios_size}</div>
        </Link>
      </SwiperSlide>)}
    </Swiper>
  </div>;
};