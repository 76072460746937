import { useEffect, useState } from "react";
import general from "../../../general";
import { CloseOutlined, LeftOutlined, EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { prod, url } from "../../../env";
import { useLocalStorageState, useToggle, useLockFn } from "ahooks";
import useActiveState from "../../../lib/useActiveState";
import Dialog from "../../../lib/Dialog";
import URI from "urijs";
import MobileDetect from "mobile-detect";
import { NavLink } from "../../../lib/Navigation";
import useUrlState from "@ahooksjs/use-url-state";
import { Popup } from 'antd-mobile'
import { useInterval } from "ahooks";

import './css/ AlertLogin.css'
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function Forgot(props) {

  const [data, setData] = useState({});
  const [focus, setFocus] = useState('');
  const [newpassword, { toggle: toggleNewpassword }] = useToggle(true);
  const [app] = useActiveState('app');
  const [coding, setCoding] = useState(false);
  const [codeTime, setCodeTime] = useState(0);
  const [areaNumData, setAreaNumData] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState(true)
  const [visiable, setVisiable] = useState(true)
  const [visibility, setVisibility] = useState('visible')

  useEffect(() => {
    setAreaNumData(general.areaNumber);
  }, [props.active]);

  useEffect(()=>{
    PubSub.subscribe('alert-forgot',()=>{
      setVisibility('visible')
    })
  },[])

  useInterval(() => {
    if (codeTime) {
      setCodeTime(codeTime - 1);
    }
  }, 1000);
  const code = () => {
    if (email){
      if (!data.email) {
        Dialog.error('请输入邮箱');
        return;
      }
      if (coding || codeTime) {
        return;
      }
      setCoding(true);
      Dialog.loading();
      general.axios.post('/sendEmailCode', {
        email: data.email,
        type: 'rePassword',
      })
        .finally(() => {
          setCoding(false);
          Dialog.close();
        })
        .then(response => {
          let result = response.data;
          if (!result.status.succeed) {
            Dialog.error(result.status.error_desc);
          } else {
            setCodeTime(60);
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
    }else {
      if (!data.mobile) {
        Dialog.error('请输入手机号');
        return;
      }
      if (coding || codeTime) {
        return;
      }
      setCoding(true);
      Dialog.loading();
      general.axios.post('/base/tool/getVerifyCode', {
        mobile: (areaNumData ? areaNumData : '86') + '-' + data.mobile,
        code: 'code',
        type: '03',
      })
        .finally(() => {
          setCoding(false);
          Dialog.close();
        })
        .then(response => {
          let result = response.data;
          if (!result.status.succeed) {
            Dialog.error(result.status.error_desc);
          } else {
            setCodeTime(60);
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
    }
  }

  const submit = () => {
    if (email){
      if (submitting) {
        return;
      }
      if (!data.email) {
        Dialog.error('请输入邮箱');
        return;
      }
      if (!data.code) {
        Dialog.error('请输入验证码');
        return;
      }
      if (!data.newpassword) {
        Dialog.error('请输入新密码');
        return;
      }
      data.type = 'rePassword'
      setSubmitting(true);
      Dialog.loading();
      general.axios.post('/emailSetPassword', data)
        .finally(() => {
          setSubmitting(false);
          Dialog.close();
        })
        .then(response => {
          let { status } = response.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            Dialog.success('操作成功',()=>props.login());
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
    }else {
      if (submitting) {
        return;
      }
      if (!data.mobile) {
        Dialog.error('请输入手机号');
        return;
      }
      if (!data.code) {
        Dialog.error('请输入验证码');
        return;
      }
      if (!data.newpassword) {
        Dialog.error('请输入新密码');
        return;
      }
      setSubmitting(true);
      Dialog.loading();
      general.axios.post('/user/user/forgetPwd', data)
        .finally(() => {
          setSubmitting(false);
          Dialog.close();
        })
        .then(response => {
          let { status } = response.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            Dialog.success('操作成功',()=>props.login());
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
    }
    
  }

  const callback = (e) => {
    setVisibility('visible');
    setAreaNumData(e);
    props.handleRegisterAgreement(false)
  }

  useEffect(() => {
    const handleBack = PubSub.subscribe('alert-back', () => {
      if (parseInt(props.registerAgreement) == 3){
        setVisibility('visible')
      }
    })
  }, [data,props.registerAgreement])

  return (
    <div>
      <Popup className={"alert-login-index " + visibility}
        visible={visiable}
        bodyStyle={{
          borderTopLeftRadius: '13px',
          borderTopRightRadius: '13px',
          overflow: 'hidden',
        }}>
        <LeftOutlined className="alert-back" onClick={() => {
          props.login()
        }}></LeftOutlined>
        <div className="alert-login-header">忘记密码</div>
        <div className="alert-login-content">
          <div>
            <div className="input-item">
              {email && app.is_overseas ? <div className="label">邮箱</div> : <div className="label">
                <Link onClick={() => {
                  setVisibility('hidden')
                  props.handleRegisterAgreement(3)
                }} to={{
                  pathname: "/account/country",
                  forgot:true,
                  callback: callback
                }} className="area-num-text">
                  <img src="/resources/account/jia.png" />
                  <span className="value">
                    {areaNumData ? areaNumData : '86'}
                  </span>
                  <img src="/resources/account/sel-down.png" />
                </Link>
              </div>}
              <div className="input">
                <input type="text" 
                  value={email ? data.email : data.mobile} 
                  placeholder={email && app.is_overseas ? '请输入邮箱' : "请输入手机号"} 
                  autoComplete={false} 
                  onFocus={e => setFocus('mobile')} 
                  onChange={e => {
                    if (email){
                      setData({ ...data, email: e.target.value })
                    }else{
                      setData({ ...data, mobile: e.target.value })
                    }
                  }} />
                {app.is_overseas && <div className="change-email" onClick={()=>{
                  setEmail(e=>!e)
                  setData({...data,email:'',mobile:'',code:''})
                }}>{email ? '切换到手机号' : '切换到邮箱'}</div>}
                {/* {focus === 'mobile' && data.mobile ? <CloseOutlined className="assist close" onClick={() => setData({ ...data, mobile: '' })} /> : null} */}
              </div>
            </div>
            <div className="input-item">
              <div className="label">验证码</div>
              <div className="input">
                <input type="tel" value={data.code} placeholder="请输入验证码" autoComplete={false} onFocus={e => setFocus('code')} onChange={e => setData({ ...data, code: e.target.value })} />
                {focus === 'code' && data.code ? <CloseOutlined className="assist close" onClick={() => setData({ ...data, code: '' })} /> : null}
              </div>
              <div className={'code-btn ' + (coding || codeTime ? 'disable' : '')} onClick={code}>
                {coding ? '发送中' : (codeTime ? (codeTime + '秒') : '发送验证码')}
              </div>
            </div>
            <div className="input-item">
              <div className="label">新密码</div>
              <div className="input">
                <input name="newpassword" type={newpassword ? 'password' : 'text'} value={data.newpassword} placeholder="请输入新密码" onFocus={e => setFocus('newpassword')} onChange={e => setData({ ...data, newpassword: e.target.value })} />
                <div className="assist password" onClick={() => toggleNewpassword()}>
                  {newpassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                </div>
                {/* {focus === 'newpassword' && data.newpassword ? <CloseOutlined className="assist close" onClick={() => setData(data => ({ ...data, newpassword: '' }))} /> : null} */}
              </div>
            </div>
            <div className="register"></div>
          </div>
          <div className={'operate ' + (submitting ? 'disable' : '')} onClick={submit}>提交</div>
          <div className='autologin'></div>
        </div>
      </Popup>
    </div>
  )
}
