import {useState, useEffect, useCallback} from "react";
import KeepScroll from "../../lib/KeepScroll";
import {Link,useHistory} from "react-router-dom";
import {useLocalStorageState, useScroll, useThrottleFn, useLockFn, useMount, useReactive, useUpdate} from "ahooks";
import Dialog from "../../lib/Dialog";
import general, {redux} from "../../general";
import { url } from "../../env";
import {Player} from 'video-react';
import Image from "../../lib/Image";
import moment from "moment";
import {NavLink} from "../../lib/Navigation";
import {CommentOutlined, LikeOutlined, PlayCircleOutlined} from '@ant-design/icons';
import $ from "jquery";
import useActiveState from "../../lib/useActiveState";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import TrumpetRecycl from "../util/TrumpetRecycl";
import {CSSTransition} from "react-transition-group";

export default props => {
    const history = useHistory();
    const update = useUpdate();
    const [loading, setLoading] = useState();
    const [init, setInit] = useState();
    const [game, setGame] = useState();
    const [gifts, setGifts] = useState([]);
    const [comments, setComments] = useState([]);
    const [user] = useActiveState('user');
    // const [router, setRouter] = useState(0);
    const [trumpetState, setTrumpetState] = useState(false);//是否有小号状态--领取礼包


    const [giftShow, setGiftShow] = useState();
    const [gift, setGift] = useState({});
    const [giftCode, setGiftCode] = useState();
    const [giftDescInfo, setGiftDescInfo] = useState({});
    const [giftDescInfoImage, setGiftDescInfoImage] = useState({});

    const load = () => {
        if (loading) {
            return;
        }
        let data = {};
        if (props.valueInfo) {
            data.game_id = props.valueInfo;
        }

        setLoading(true);
        Dialog.loading();
        general.axios.post('/base/OneGame/getGameDetail', data)
            .finally(() => {
                setLoading(false);
                Dialog.close();
            })
            .then(res => {
                let {status, data} = res.data;
                if (!status.succeed) {
                    Dialog.error(status.error_desc);
                    return;
                } else {
                    setInit(true);
                    let game = {...data, ...data.game_info};
                    setGame(game);

                    if (game.gift_bag_list) {
                        setGifts(game.gift_bag_list);
                    }
                    setComments(data.comments)
                }
                
            })
            .catch(err => {
                Dialog.error(err.message);
            });
    }

    useEffect(() => {
        if (props.active && !init) {
          load();
        }
    }, [props.active]);


  // useEffect(() => {
  //   load();
  // }, [user]);
  
  const {run: setNewOpacity} = useThrottleFn(value => props.setNewOpacity(value), {wait: 10});
  const scroll = useScroll();
  const [videoState, setVideoState] = useState(true);

  useEffect(() => {
    let opacity;
    if (props.active) {
      if (scroll.top) {
        opacity = Math.abs(scroll.top) / 200;
        if (opacity > 1) {
          opacity = 1;
        }
      } else {
        opacity = 0;
      }
    }
    setNewOpacity(opacity);

    if (props.active) {
      if (scroll.top < -80) {    
        load();
      }
    }
  }, [scroll, props.active]);

  const [player, setPlayer] = useState();
  const [muted, setMuted] = useLocalStorageState('game-video-muteds', true);
  const video = useReactive({});
  const onPlaying = () => {
    video.play = true;
    video.eventPause = false;
    video.activePause = false;
    setVideoState(false)
  }
  const onPause = () => {
    video.play = false;
    video.eventPause = true;
    setVideoState(true);
    // Dialog.info(videoState);
    // Dialog.info(video.play);
  }
  useEffect(() => {
    if (player) {
      if (props.active && video.activePause) {
        player.play();
      }
      if (!props.active) {
        if (video.play) {
          player.pause();
          video.play = true;
          setVideoState(false);
        } else {
          player.pause();
          setVideoState(true);
        }
        if (video.play) {
          video.activePause = true;
        }
      }
    }

    
  }, [props.active, player]);

  // console.log(player);

  const imgRef = useCallback(node => {
    if (node) {
      node.addEventListener('touchstart', e => {
        e.stopPropagation();
      }, {passive: false});
      node.addEventListener('touchmove', e => {
        e.stopPropagation();
      }, {passive: false});
      node.addEventListener('touchend', e => {
        e.stopPropagation();
      }, {passive: false});
    }
  }, []);

  const ref = useCallback(el => {
    if (el) {
      if ($(el).height() > $(el).parents('.comment-content').height()) {
        $(el).parents('.comment-content').addClass('switchable');
      }
    }
  }, []);
  const toggle = e => {
    // e.preventDefault();
    // $(e.target).parents('.comment-content').toggleClass('open');
    history.push("/game?id="+ game.game_id);
  }


  const giftReceive = gift => {
    Dialog.loading();
    general.axios.post('https://api3.app.99maiyou.com/queryGameAccount', {maiyou_gameid: game.maiyou_gameid})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        if(data.isExists) {
          Dialog.loading();
          general.axios.post('/base/game/getReceiveGift', {gift_id: gift.gift_id})
            .finally(() => {
              Dialog.close();
            })
            .then(res => {
              let {status, data} = res.data;
              if (!status.succeed) {
                Dialog.error(status.error_desc);
                return;
              } else {
                setGiftShow(false);
                setGiftCode(data);
                load();
              }
            })
            .catch(err => {
              Dialog.error(err.message);
            });
        } else {
          // setGiftShow(false);
          setTrumpetState(true);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });


    // Dialog.loading();
    // general.axios.post('/base/game/getReceiveGift', {gift_id: gift.gift_id})
    //   .finally(() => {
    //     Dialog.close();
    //   })
    //   .then(res => {
    //     let {status, data} = res.data;
    //     if (!status.succeed) {
    //       Dialog.error(status.error_desc);
    //     } else {
    //       setGiftShow(false);
    //       setGiftCode(data);
    //     }
    //   })
    //   .catch(err => {
    //     Dialog.error(err.message);
    //   });
  };

  const receive = voucher => {
    general.axios.post('/receiveVoucher', {voucher_id: voucher.id})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          Dialog.success('领取成功');
          voucher.is_received = true;
          update();
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const bouncesShow = item => {
    Dialog.loading();
    general.axios.post('/base/gift/getGiftDetail', {gift_id: item.packid})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        let descData = data.gift_info;
        setGiftDescInfo(data.gift_info);
        setGiftDescInfoImage(descData.new_image.thumb);
        
        // setGift(item);
        setGiftShow(true);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }
//   console.log(comments);
// console.log(game.banner_750);
  return game ? 
    <div className="index-single"  style={{backgroundColor: game.banner_light}}>
    <div className="">
      <div className="index-allban">
        <div className="index-banners">
          <div className="bg" style={{backgroundImage: 'linear-gradient(' + game.banner_color +', ' + game.banner_color+' '}}/>
          <div className="banner-container" style={{backgroundImage: 'url('+ game.banner_750 +'), url(/resources/placeholder/index-banner.png)'}}>
            <Link
              to={"/game?id="+game.game_id}
              className="banner"
            >
              <div className="bg" style={{backgroundImage: 'linear-gradient(to bottom, '+game.banner_color+', rgba(240,235,235,0))'}}/>
            </Link>
          </div>
        </div>
      </div>
    </div>

    <div className="single-menu">
        <div className="experience">
            <Link to={"/game?id="+game.game_id}>
              <img src="/resources/index/single-experience.png" />
            </Link>
        </div>
    </div>

    
    {/***代金券***/}
    <div className="single-voucher">
        <div className="bg" >
          <img src="/resources/index/one-top.png"/>
          <div className="desc-content" style={{backgroundImage:'url(/resources/index/one-body.png)'}}>
            <div className="title">
                <img src="/resources/index/single-left.png" />
                <span>代金券/礼包</span>
                <img src="/resources/index/single-right.png" />
            </div>
            <div className="v-desc" >
                <div className="voucher">
                    <div className="voucher-main">
                    {game.voucherslist?[game.voucherslist[0], game.voucherslist[1], game.voucherslist[2]].map(voucher => voucher ? <div className="item" style={{backgroundImage: voucher.is_received != '0' ? ('url(/resources/game/claimed.png)') : ('url(/resources/game/unclaimed.png)')}}>
                        <div className="amounts">
                        <div className="amount">
                            <span>￥</span><span className="num">{voucher.amount * 1}</span><span>.00</span>
                        </div>
                        <div className="amount-meet">
                        {(() => {
                            let meet_amount = voucher.meet_amount * 1;
                            if(meet_amount === 0) {
                            return '任意金额可用';
                            } else {
                            return '满' + meet_amount + '元可用';
                            }
                        })()}
                            {/* 满{voucher.meet_amount * 1}元可用 */}
                        </div>
                        </div>
                        <div className="operate">
                        {
                            voucher.is_received != '0' ? 
                            <div className="btn-copy">已领取</div>
                            :
                            // <div className="btn-receive" onClick={()=>receive(voucher)}>
                            // 领取
                            // </div>
                            <NavLink className="btn-receive" onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                receive(voucher)
                            }}>领取</NavLink>
                        }
                        </div>
                    </div> : null):null}
                    </div>
                    {game.voucherslist.length >0 ? <div className="more"><img src="/resources/welfare/left-back.png" className="left-back"/></div> : null}
                </div>

                <div className="gift-list">
                    <div className={"body " }>
                        {gifts.map((gift, index) => <div className={"item "} onClick={() => {
                        // setGift(gift);
                        // setGiftShow(true);
                        // giftShowNewWindow(gift);
                        }}>
                        <div className="info">
                            <div className="name">{gift.packname} {gift.is_vip_gift ? <img src="/resources/game/vip-exclusive.png" className="exclusive"/> : null}</div>
                            <div className="desc">
                            {gift.packcontent}
                            <br/>
                            有效期：{moment(gift.starttime * 1000).format('YYYY-MM-DD')} 至 {moment(gift.endtime * 1000).format('YYYY-MM-DD')}
                            </div>
                        </div>
                        
                        {
                        gift.is_received ? <div onClick={() => Dialog.info('已复制')}>
                        <div className="btn-copy">复制</div>
                        </div> :
                        // <div className="btn-receive" onClick={()=>receive_gift(game)}>
                        // 领取
                        // </div>
                        <NavLink className="operate" onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            // giftReceive(gift)
                            bouncesShow(gift)
                        }}>领取</NavLink>
                      }
                        </div>)}
                    </div>
                    <div className="select-all"><Link to={"/game?id="+game.game_id} >查看全部</Link></div>
                    {/* {
                        gifts.length > 3 ? (
                        giftMoreState ? <div className="select-all" onClick={()=>setGiftMoreState(false)}>收起</div> :
                        <div className="select-all" onClick={()=>setGiftMoreState(true)}>查看全部</div>
                        )
                        :null
                    } */}
                </div>
            </div>
          </div>
          <img src="/resources/index/one-bottom.png"/>
        </div>
    </div>

    <div className="single-video">
        <div className="desc">
        {game.video_url ?
          <Player playsInline={true} muted={muted} onVolumeChange={e => setMuted(e.target.muted)}
                  fluid={false} width="100%" height="100%" ref={player => setPlayer(player)} onPlaying={onPlaying}
                  onPause={onPause} webkit-playsinline="true" playsinline="true" poster={game.video_img_url}>
            <source src={game.video_url}/>
          </Player> : null}
          {!video.play ? <div className="play" onClick={()=>{player.play()}}><PlayCircleOutlined/></div> : null }
        </div>
    </div>

    {/***五图***/}
    <div className="single-five-pic">
        <div className="game-imgs" ref={imgRef}>
            <div className="img">
                {game.game_ur_list?.map((img, index, imgs) => <Link to={'/img?imgs='+imgs+'&img=' + index}><Image src={img} alt=""/></Link>)}
            </div>
        </div>

    </div>

    {/***游戏特色****/}
    <div className="single-feature">
        <div className="bg">
          <img src="/resources/index/one-top.png"/>
          <div className="desc-content" style={{backgroundImage:'url(/resources/index/one-body.png)'}}>
            <div className="title">
                <img src="/resources/index/single-left.png" />
                <span>游戏特色</span>
                <img src="/resources/index/single-right.png" />
            </div>
            <div className={'body '} >
                <div className="entity" dangerouslySetInnerHTML={{__html: game.summary ? game.summary.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2') : null}}/>
            </div>
            <div className="select-all"><Link to={"/game?id="+game.game_id} >查看全部</Link></div>
          </div>
          <img src="/resources/index/one-bottom.png"/>
        </div>
    </div>

    <div className="single-active">
        <div className="bg">
          <img src="/resources/index/one-top.png"/>
          <div className="desc-content" style={{backgroundImage:'url(/resources/index/one-body.png)'}}>
            <div className="title">
                <img src="/resources/index/single-left.png" />
                <span>游戏活动</span>
                <img src="/resources/index/single-right.png" />
            </div>
            <div className="desc">
                {
                    game.game_activity?.map((item, index) => <div className="item-list"><Link className="item">
                        {item.title}
                    </Link></div>)
                }
            </div>
            <div className="select-all"><Link to={"/game?id="+game.game_id} >查看全部</Link></div>
          </div>
          <img src="/resources/index/one-bottom.png"/>
        </div>
    </div>

    {/***游戏攻略 */}
    <div className="single-strategy">
        <div className="bg" >
          <img src="/resources/index/one-top.png"/>
          <div className="desc-content" style={{backgroundImage:'url(/resources/index/one-body.png)'}}>
            <div className="title">
                <img src="/resources/index/single-left.png" />
                <span>游戏攻略</span>
                <img src="/resources/index/single-right.png" />
            </div>
            <div className={'body '} >
                <Link to={"/game?id="+game.game_id}>
                <div className="entity" dangerouslySetInnerHTML={{__html: game.introduction ? game.introduction.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2') : null}} />
                <div className="ctrl">更多</div>
                {game.banner_url?<div className="strategy-img"><img src={game.banner_url} /></div>:null}
                </Link>
            </div>
          </div>
          <img src="/resources/index/one-bottom.png"/>
        </div>
    </div>

    {/**热门点评**/}
    <div className="single-comment">
        <div className="bg">
        <img src="/resources/index/one-top.png"/>
        <div className="desc-content" style={{backgroundImage:'url(/resources/index/one-body.png)'}}>
            <div className="title">
                <img src="/resources/index/single-left.png" />
                <span>热门点评</span>
                <img src="/resources/index/single-right.png" />
            </div>
        
            <div className="body">
            {comments.map((comment, index) => <NavLink to={'/game?id=' + game.game_id} className="comment-item">
                {/* {comment.reward_intergral_amount ? <div className="comment-reward"
                                                        style={{backgroundImage: 'url(/resources/comment/reward.png)'}}><div className="text">{comment.reward_intergral_amount * 1}</div></div> : null} */}
                <div className="comment-user">
                <Link to={'/user/record?id=' + comment.user_id} className="avatar"><Image src={comment.user_icon} alt=""/></Link>
                <div className="info">
                    <div className="name">{comment.user_nickname}<img src={'/resources/user/' + comment.user_level + '.png'}/>
                    </div>
                    <div className="count"><CommentOutlined className="icon"/>共发表{comment.user_comment_count}个游戏评论</div>
                </div>
                {/* {comment.reward_intergral_amount ? null : <div className="time">{moment(comment.time * 1000).format('MM-DD HH:mm')}</div>} */}
                </div>
                <div className="comment-content">
                <div className="content">
                    <div ref={ref}>{comment.content}</div>
                </div>
                <div className="ctrl" onClick={toggle} />
                </div>
                {/* <div className="comment-img">
                {comment.pic_list.map((img, index, imgs) => index < 3 ?<Link to={'/img?imgs=' + imgs + '&img=' + index} className="item">
                        <Image src={img} alt=""/>
                        {index === 2 ? <div className="more">+{imgs.length - 3}</div> : ''}
                    </Link> : null
                )}
                </div> */}
                {/* {comment.reply_list.length > 0 ? <div className="comment-reply">
                {comment.reply_list.map(reply => <div className="reply-item">
                    <div className="reply-user">{reply.user_nickname}：</div>
                    <div className="reply-content">{reply.content}</div>
                </div>)}
            
                </div> : null} */}
                <div className="comment-info">
                <div className="device">{comment.device_name}</div>
                <div className="count">
                    <div className="item reply"><CommentOutlined className="icon"/>{comment.reply_count}</div>
                    <NavLink className={'item like ' + (parseInt(comment.me_like) ? ' active' : '')}
                            onClick={e => like(e, index)}><LikeOutlined
                        className="icon"/>{comment.like_count}</NavLink>
                </div>
                </div>
            </NavLink>)}
            <div className="select-all"><Link to={"/game?id="+game.game_id} >查看全部</Link></div>
            </div>
            </div>
            <img src="/resources/index/one-bottom.png"/>
        </div>
    </div>


    <CSSTransition in={giftShow} timeout={250} unmountOnExit={true}>
        <div className="gift-detail">
          <div className="gift-mask" onClick={() => setGiftShow(false)}/>
          <div className="gift-container">
            <div className="gift-top">
              <div className="icon">
              <Image src={giftDescInfoImage}/>
              </div>
              <div className="text">
                <div className="name">{giftDescInfo.gift_name}</div>
                <div className="time">有效期：{moment(giftDescInfo.gift_dealine * 1000).format('YYYY-MM-DD')}</div>
              </div>
            </div>
            <div className="gift-main">
              <div className="gift-desc" style={{borderBottom:"1px solid #ededed",padding:"0 0 10px 0"}}>
                <div className="title">礼包说明</div>
                <div className="main">{giftDescInfo.gift_desc}</div>
              </div>
              <div className="gift-desc" style={{borderBottom:"1px solid #ededed",padding:"10px 0"}}>
                <div className="title">使用说明</div>
                <div className="main">{giftDescInfo.gift_introduce}</div>
              </div>
            </div>
            <NavLink className="gift-bottom" onClick={() => giftReceive(giftDescInfo)}>领取</NavLink>
          </div>
        </div>
      </CSSTransition>

    {/**领取礼包判断小号弹框***/}
    {giftCode ? <div className="dialog active">
      <div className="dialog-mask"/>
      <div className="dialog-content">
        <div className="dialog-modal">
          <div className="modal-body">
            <div className="modal-title">领取成功</div>
            <div className="modal-content">{giftCode}</div>
          </div>
          <div className="modal-btns">
            <div className="modal-btn">
              <CopyToClipboard text={giftCode} onCopy={() => Dialog.info('已复制')}>
                <div onClick={() => {setGiftCode(null);load();}}>复制</div>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
    </div> : null}

    {/* <TrumpetRecycl show={trumpetState} type="05" gameId={game.game_id} close={() => setTrumpetState(false)}/> */}
    {
        trumpetState ?
        <div className="game-trumpet-index">
          <div className="trumpet-site">
            <div className="trumpet-main">
                <div className="trumpet-title">
                温馨提示
                </div>
                <div className="info">
                  <p>您游戏中尚未创建小号，暂时无法领取此礼包</p>
                </div>
                <div className="trumpet-btn">
                  <div className="item btn-about" onClick={()=>{setTrumpetState(false)}}>
                    稍后再领
                  </div>
                  <NavLink to={"/game?id="+game.game_id+"&isExists=1"} className="item btn-confirm" onClick={()=>{setTrumpetState(false);setGiftShow(false);}}>
                    <h2>{giftDescInfo.type == 3 ? '进入游戏' : '下载游戏'}</h2>
                  </NavLink>
                </div>
            </div>
          </div>
        </div>
        : null
      }
  </div>: null;
};