import React, {useEffect, useState} from "react";
import general from "../../general";
import moment from "moment";
import {FieldBinaryOutlined, FieldTimeOutlined, FileAddFilled, FileAddTwoTone, FileDoneOutlined, FileImageFilled} from "@ant-design/icons";
import {Link} from "react-router-dom";
import $ from "jquery";
import {useUpdateEffect, useLockFn, useScroll} from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";

let days = [];
for (let i = 1; i < 6; i++) {
  days.push(moment().add(i, 'days').format('YYYY-MM-DD'));
}

export default ({active, tag}) => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const [day, setDay] = useState(days[0]);
  const load = useLockFn(async (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let data = {
      kf_type: 20,
      kf_start_time: parseInt(moment(day).valueOf() / 1000),
      game_classify_id: tag,
      pagination: {count : 20, page: page},
    };
    if (parms.page) {
      data.pagination.page = parms.page;
    }
    data.game_species_type = '';
    general.axios.post('/base/game/getKaiFuList', data)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setInit(true);
        if (parms.page) {
          setPage(parms.page);
        }
        let {paginated, kaifu_list: list} = data;
        let tmpDatas = parms.page === 1 || !parms.page ? [] : [...datas];
        list.forEach(item => {
          let time = moment(item.kaifu_start_date * 1000).format('HH:mm');
          let index = tmpDatas.findIndex(item => item.time === time);
          if (index === -1) {
            tmpDatas.push({time: time, datas: []});
            index = tmpDatas.length - 1;
          }
          tmpDatas[index].datas.push(item);
        });
        console.log(tmpDatas);
        setDatas(tmpDatas);
        setMore(paginated.more && list.length > 0);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });
  useEffect(() => {
    if (active && !init) {
      load();
    }
  }, [active]);
  useUpdateEffect(() => {
    if (active) {
      $('html,body').scrollTop(0);
      load();
    }
  }, [tag, day]);

  const scroll = useScroll();
  useEffect(() => {
    if (active && init) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 >= $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, active]);

  return <div className="server-today">
    <div className="server-time">
      {days.map(item => <div className={'item' + (item === day ? ' active' : '')} onClick={() => setDay(item)}>{moment(item).format('MM-DD')}</div>)}
    </div>
    <div className="future-body">
      <div className="server-groups">
        {datas.map(item => <div className="server-group">
          <div className="group-title"><FieldTimeOutlined className="icon"/>{item.time}</div>
          <div className="group-body">
            <div className="server-item-div">
            {item.datas.map(game => <Link to={'/game?id=' + game.game_id} className="server-item">
              <div className="icon">
                <Image src={game.game_image.thumb}/>
                {
                  game?.lablePosition == 2 && game.top_lable != '' ?
                  <div className="iconlable jx" style={{backgroundImage: 'linear-gradient('+game?.lableColor.t+', '+game?.lableColor.b+')'}}>
                    {game.top_lable}
                  </div>
                  : null
                }
              </div>
              <div className="text">
                <div className="name">
                  {/* {game.game_name} */}
                  <div className={" " + (game.nameRemark ? " name-new" : "")}>
                    {/* {game.top_lable != '' ? <span className="span">{game.top_lable }</span> : null } */}
                    {game.game_name}
                  </div>
                  {game.nameRemark != '' ? <div className="nameRemark-new">{game.nameRemark}</div> : null}
                </div>
                <div className="info1">
                  {/* {
                    game.nameRemark ?
                    <span className="nameRemark">
                      {game?.nameRemark}&nbsp;
                    </span>
                    : null
                  } */}
                  {/* {
                    game.nameRemark ?
                    <>{game.game_classify_type.replace(/(^\s*)|(\s*$)/g, "").replace(/\s+/g, "&")}</>
                    :
                    <>{game.game_classify_type.trim()}</>
                  }
                  {!game.nameRemark ? <>&nbsp;|&nbsp;{game.game_size?.ios_size}</> : null} */}
                  {game.game_classify_type.trim()}
                  &nbsp;|&nbsp;
                  {game.game_size?.ios_size}
                </div>
                <div className="info2">{game.kaifu_name}</div>
              </div>
              {/* <div className="btn">下载</div> */}
            </Link>)}
            </div>
          </div>
        </div>)}
      </div>
    </div>
    <div style={{display:datas.length === 0 ? 'none' : ''}} className="view-end">别撩啦！已经到底啦~</div>
  </div>;
};