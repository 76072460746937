import React, { useEffect, useState } from 'react'
import general from '../../../general'
import Dialog from '../../../lib/Dialog'
import Image from '../../../lib/Image'
import CopyToClipboard from 'react-copy-to-clipboard'
import Detail from '../../gift/Detail'
import useActiveState from '../../../lib/useActiveState'

export default function ReceiveList(props) {

  const [claim_type, setclaim_type] = useState('voucher')
  const [list, setList] = useState()
  const [init, setInit] = useState(false)
  const [gift, setGift] = useState()
  const [refresh] = useActiveState('receive-refresh')

  const tag = [
    { id: 'voucher', tagname: '代金券' },
    { id: 'gift', tagname: '礼包' }
  ]

  const getData = () => {
    setList([])
    Dialog.loading()
    general.axios.post('/base/signbonus/getMyGiftOrVouch', {
      claim_type,
      pagination: {
        page: 1,
        count: 100000
      }
    }).finally(() => {
      Dialog.close()
    })
      .then((res) => {
        const { data, status } = res.data
        if (status.succeed) {
          setList(claim_type == 'voucher' ? data.voucher_list : data.gift_list)
          if (!init) {
            setInit(true)
          }
        } else {
          Dialog.error(status.error_desc)
        }
      })
  }

  useEffect(() => {
    if (props.active && !init) {
      getData()
    }
  }, [props.active, init])

  const handleTag = (item) => {
    setclaim_type(item.id)
  }

  useEffect(() => {
    getData()
  }, [claim_type])

  useEffect(() => {
    getData()
  }, [refresh])

  const giftShowNewWindow = item => {
    Dialog.loading();

    general.axios.post('/base/gift/getGiftDetail', { gift_id: item.gift_id })
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let { status, data } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        let descData = data.gift_info;
        setGift(data.gift_info);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  return (
    <div className='receive-list'>
      <div className='list-header'>
        {tag.map(item => {
          return (
            <div key={item.id} onClick={() => handleTag(item)} className={'tag ' + (claim_type == item.id ? 'active' : '')}>
              <div className="name">{item.tagname}</div>
            </div>
          )
        })}
      </div>
      <div className="games">
        {list?.map(game => {
          return (
            <div key={JSON.stringify(game)} className='item' onClick={() => {
                if (claim_type == 'gift'){
                  giftShowNewWindow(game)
                }
              }}>
              <div className="game">
                <Image onClick={(e)=>{
                  if (claim_type == 'gift') {
                    e.stopPropagation();
                    e.preventDefault();
                    general.history.push(`game?id=${game.game_id}`)
                  }
                }} className="icon" src={game.game_image.thumb} />
                <div className="text">
                  <div className="name">
                  {game.game_name}
                  <span className='nameRemark'>{game.nameRemark}</span>
                  </div>
                  <div className={'info1 ' + claim_type}>{claim_type == 'voucher' ? game.rules_used : `名称：${game.gift_name}`}</div>
                  <div className={claim_type}>{claim_type == 'voucher' ? game.useEndDateDesc : `礼包码：${game.gift_code}`}</div>
                </div>
                {claim_type == 'gift' ? <CopyToClipboard text={game.gift_code} onCopy={() => Dialog.info('复制成功')}>
                  <div className="btn">复制</div>
                </CopyToClipboard> : 
                <div className={"btn " + (claim_type == 'voucher' && parseInt(game.is_expired) == 1 ? ' expired' : '')} onClick={() => {
                    if (parseInt(game.is_expired) == 1) {
                      return
                    }
                    general.history.push(`/game?id=${game.game_id}`)
                  }}>{(parseInt(game.is_expired) == 1 ? '已过期' : '去使用')}</div>
                }
              </div>
            </div>
          )
        })}
      </div>
      {list?.length == 0 && init && <div className="recovery-empty"><img src="/resources/recovery/empty.png" alt=""/>暂无数据</div>}
      {list?.length > 0 && init ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
      <Detail gift={gift} setGift={setGift} copyShow={false}></Detail>
    </div>
  )
}
