import React, { useEffect, useState } from "react";
import general from "../../../general";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useUpdateEffect, useScroll } from "ahooks";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import useActiveState from "../../../lib/useActiveState";
import Subtitle from "../../components/Subtitle";

export default props => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [games, setGames] = useState([]);
  const [more, setMore] = useState(true);
  const [randomColorInfo] = useActiveState('randomColorInfo');
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      pagination: { page: page },
    };
    if (parms.page) {
      tmp.pagination = { page: parms.page };
    }
    general.axios.post('/base/wechatMiniGame/getPlayedMiniGame', tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then(response => {
        setInit(true);
        let result = response.data.data;
        if (parms.page) {
          setPage(parms.page);
        }
        result.game_list.forEach(d => {
          d.infoColor = randomColorInfo[parseInt(Math.random() * 4)]
        })
        setGames(games => parms.page === 1 || !parms.page ? result.game_list : [...games, ...result.game_list]);
        setMore(result.paginated.more);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);
  // useUpdateEffect(() => {
  //   if (props.active && (!init || props.action === 'POP')) {
  //     load();
  //   }
  // }, [props]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll.top < -80) {
        load({ page: 1 });
      }
      if (scroll.top + window.innerHeight + 20 > $(document).height() && more) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  return <div className="user-game" style={{ 
    padding: '15px',
    backgroundColor: '#f5f6f8',
     minHeight:'calc(100vh - env(safe-area-inset-top) - 40px)'
     }}><div className="play-game">
    {games.map(game => <div className="item" onClick={()=>{
      window.open(game.single_promot_url)
    }}>
      <div className="icon">
        <Image style={{borderRadius:'13px'}} src={game.game_image.thumb} />
      </div>
      <div className="subject">
        <div className="text">
          <div className="name">
            <div className={" " + (game.nameRemark ? " name-new" : "")}>
              {game.game_name}
            </div>
          </div>
          <div className="info1">
            {/* {
              game.nameRemark ?
              <span className="nameRemark">
                {game?.nameRemark}&nbsp;
              </span>
              : null
            } */}
            {/* {game.game_classify_type.trim()} */}
            {game.game_classify_type.split(' ').map(item =><span 
            style={{
                backgroundColor:'#F2F2F2',
                padding:'1px 3px',
                marginRight:'3px',
                textAlign:'center',
                borderRadius:'2px',
                fontSize:'11px',
                color:'#999'
                }}>
                  {item}
                </span>)
            }
            {/* {!game.nameRemark && game.game_species_type != 3 ? <>&nbsp;|&nbsp;{game.howManyPlay}人在玩</> : null} */}
            <Subtitle>{game.nameRemark}</Subtitle>

          </div>
          {/* <div className="info2">
            {game.game_desc ? game.game_desc.split('+').map(item => <div className="tag">{item}</div>) : <span style={{color: game.infoColor}}>{game.introduction}</span>}
          </div> */}
          <div className="info3">
            {
              game.bottom_lable ?
                <>
                  {Object.keys(game.bottom_lable).length > 0 ?
                    <div >
                      <span className="first-text" style={{ background: game.bottom_lable.type == 1 ? '#FF8C50' : '#9F9DFC' }}>{game.bottom_lable.left_content}</span><span className="second-text" style={{ color: game.bottom_lable.type == 1 ? '#FF8C50' : '#9F9DFC', borderColor: game.bottom_lable.type == 1 ? '#FF8C50' : '#9F9DFC' }}>{game.bottom_lable.right_content}</span>
                    </div> : null}
                </>
                : null
            }
            <span style={{ color: '#888' }}>{game.introduction}</span>
          </div>
          <div onClick={e=>{
            e.preventDefault();
            e.stopPropagation();
            window.open(game.single_promot_url)
          }} 
          style={{
            padding:'3.5px 10px',
            borderRadius:'14px',
            backgroundColor:'#E0EDFD',
            position:'absolute',
            right:'0px',
            top:'10px'
            }}>
            <img height={10} src="resources/game/play.png" alt="" />
            <span style={{color:'#0567DF',fontSize:'11px',paddingLeft:'4px',fontWeight:'500'}}>去玩</span>
          </div>
        </div>
      </div>
    </div>)}
    {games.length > 6 && !more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
    {games.length === 0 ? <div className="recovery-empty"><img src="/resources/user/game_empty.png" alt="" />暂未玩过任何游戏</div> : null}
  </div>
  </div>
};