import {useEffect, useState} from "react";
import Game from "./Game";
import Complaint from "./Complaint";
import {Swiper, SwiperSlide} from 'swiper/react';

export default props => {
  const [router, setRouter] = useState([
    {
      id: 'game',
      component: Game,
      name: '游戏反馈',
    },
    {
      id: 'complaint',
      component: Complaint,
      name: '投诉反馈',
    },
  ]);
  useEffect(() => {
    if (props.query.id) {
      setRouter([router[0]]);
    }
  }, [props]);
  const [routerIndex, setRouterIndex] = useState(0);
  const [swiper, setSwiper] = useState(null);
  useEffect(() => {
    if (swiper) {
      swiper.update();
    }
  }, [props.active, swiper]);
  useEffect(() => {
    if (swiper) {
      swiper.slideTo(routerIndex);
    }
  }, [routerIndex, swiper]);

  return <div className="feedback-index">
    {props.query.id ? null : <div className="feedback-head">
      {router.map((item, index) => <>
        <div className={'feedback-head-item ' + (routerIndex === index ? 'active' : '')} onClick={() => setRouterIndex(index)}>{item.name}</div>
      </>)}
    </div>}
    <div className="feedback-body">
      <Swiper onSwiper={setSwiper} onSlideChange={swiper => setRouterIndex(swiper.activeIndex)}>
        {router.map((item, index) => <SwiperSlide>
          <div className={'feedback-body-item ' + (routerIndex === index ? 'active' : '')}>
            <item.component active={props.active && routerIndex === index}/>
          </div>
        </SwiperSlide>)}
      </Swiper>
    </div>
  </div>;
};