import React, { useEffect, useState } from 'react'
import './css/gamesign.css'
import general from '../../../general'
import Dialog from '../../../lib/Dialog'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Modal } from 'antd-mobile'
import SignAlert from './SignAlert'
import { NavLink } from '../../../lib/Navigation'
import { useLockFn, useScroll } from 'ahooks'
import useUrlState from "@ahooksjs/use-url-state";
import useActiveState from '../../../lib/useActiveState'

export default function GameSign(props) {

  const [data, setData] = useState()
  const [record, setRecord] = useState([])
  const [visible, setVisible] = useState(false)
  const [gift, setGift] = useState()
  const [init, setInit] = useState(false)
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});
  const [user,setUser] = useActiveState('user')

  const usePageVisibility=(onVisible,onHidden)=>{
    useEffect(()=>{
      const handlevisibilitychange =()=>{
        if(document.visibilityState == "visible"){
          onVisible && onVisible();
        } else {
          onHidden && onHidden();
        }
      }
      document.addEventListener("visibilitychange", handlevisibilitychange);
      return()=>{
        document.removeEventListener("visibilitychange",handlevisibilitychange);
      };
    },[onVisible, onHidden]);
  }

  usePageVisibility(()=>{
    urlLogin()
  },()=>{
    
  })

  useEffect(()=>{
    urlLogin()
  },[])

  const urlLogin = () => {
    if (params.userid && params.username && params.token) {
      if (window.navigator.standalone && localStorage.getItem('logout')) {
        return;
      }
      localStorage.setItem('user_id', params.userid);
      localStorage.setItem('username', params.username);
      localStorage.setItem('token', params.token);
      load_load()
    }
  }

  const load_load = useLockFn(async () => {
    await general.axios.post('/user/user/getMemberInfo')
      .finally(() => {
      })
      .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {

        } else {
          setUser({isLogin: true, ...data.member_info});
          getData()
        }
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  })

  const getData = () => {
    if (!init){
      Dialog.loading()
    }
    general.axios.post('/base/signbonus/getData', {
      topic_id: props.query.id,
    }).finally(() => {
      Dialog.close()
    }).then(res => {
      const { data, status } = res.data
      if (status.succeed) {
        setData(data)
        setInit(true)
      } else {
        Dialog.error(status.error_desc)
      }
    })
  }

  const getRecord = () => {
    general.axios.post('/base/signbonus/record', {
      topic_id: props.query.id,
    }).then(res => {
      const { data, status } = res.data
      if (status.succeed) {
        setRecord(data)
      } else {
        Dialog.error(status.error_desc)
      }
    })
  }

  useEffect(() => {
    if (props.active) {
      getData()
      getRecord()
      props.setOperate(<NavLink to='/user/gift' style={{fontSize:'13px'}}>我的礼包</NavLink>)
    }
  }, [props.active])

  const scroll = useScroll()
  useEffect(() => {
    if (scroll.top > 40){
      props.setTitle(data?.game_info?.game_name)
    }else {
      props.setTitle('')
    }
  }, [scroll,data])

  const sign = () => {

    if (!data?.is_can_sign){
      Modal.show({
        title: '登录游戏后即可领取礼包',
        closeOnMaskClick: true,
        className:'game-sign-title',
        header:(
          <div>
            <img src="resources/game/sign/礼包.png" width={58} style={{marginBottom:'10px'}} alt="" />
          </div>
        ),
        content: (
          <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
            <div onClick={()=>{
              Modal.clear()
              general.history.push(`/game?id=${data?.game_info.game_id}`)
            }} style={{
              backgroundColor:'var(--MainColor)',
              width:'183px',height:'44px',
              color:'white',fontSize:'15px',textAlign:'center',
              borderRadius:'22px',lineHeight:'44px',marginTop:'10px'
              }}>下载游戏</div>
            <div onClick={()=>{
              Modal.clear()
            }} style={{
              fontSize:'13px',color:'#999',paddingTop:'10px'
            }}>我知道了</div>
          </div>
        ),
      })
      return
    }
    if (data?.isTodaySign) {
      return
    }
    general.axios.post('/base/signbonus/sign', {
      topic_id: props.query.id,
    }).then(res => {
      const { data, status } = res.data
      if (status.succeed) {
        setGift(data)
        setVisible(true)
        getData()
        getRecord()
      } else {
        Dialog.error(status.error_desc)
      }
    })
  }

  const handleRecord = () => {
    if (record.length == 0) {
      Dialog.info('暂无签到记录')
      return
    }
    Modal.show({
      title: '签到记录',
      closeOnMaskClick: true,
      showCloseButton:true,
      content: (
        <div style={{minHeight:'160px'}}>
          {record.map(record => {
            return (
              <div style={{ display: 'flex', alignItems: 'center', padding: '10px 10px 0', justifyContent: 'space-between' }}>
                <div className="time" style={{ fontSize: '13px', color: '#666' }}>{record.time}</div>
                <div className="desc" style={{ fontSize: '13px', color: '#666' }}>已签到</div>
              </div>
            )
          })}
        </div>
      )
    })
  }

  return (
    <div>
      {data && <div className='game-sign'>
        <div className='icon-container'>
          <img src={data?.game_info?.banner_url} alt="" />
          <div className="icon"></div>
        </div>
        <div className='game-sign-container'>
          <NavLink to={`/game?id=${data?.game_info.game_id}`} className='header style'>
            <img src={data?.game_info.game_image.thumb} alt="" height={51} />
            <div className="content">
              <div className="name">{data?.game_info.game_name}</div>
              <div className="desc">{data?.game_info.game_classify_type.trim()} · {data?.game_info.nameRemark}</div>
            </div>
            <div className="download">下载</div>
          </NavLink>
          <div className='date style'>
            <div className="date-header">
              <img src="resources/game/sign/累计签到领好礼.png" alt="" height={17} />
              <NavLink className='record' onClick={handleRecord}>签到记录{'>'}</NavLink>
            </div>
            <div className="line"></div>
            <div className="sign">
              {data?.sign_info?.map((item, index) => {
                return (
                  <div key={index} className='item'>
                    <div className={"imgs " + (item.is_sign ? 'active' : '')}>
                      <img className='img' src={item.img} alt="" />
                      {item.is_sign && <img className='back-img' src="resources/game/sign/sign.png" alt="" />}
                    </div>
                    <div className={"day " + (item.is_sign ? 'active' : '')}>{index + 1}天</div>
                  </div>
                )
              })}
            </div>
            <NavLink className={"sign-btn " + (data?.isTodaySign ? 'disable' : '')} onClick={sign}>{data?.isTodaySign ? '今日已签' : '立即签到'}</NavLink>
          </div>
          <div className='list'>
            {data?.gift_info?.map((item, index) => {
              return (
                <div key={index} className='item'>
                  <img height={50} src={item.img} alt="" />
                  <div className="content">
                    <div className="key">{item.packname}</div>
                    <div className="value">{item.packcontent}</div>
                  </div>
                  {item.is_sign ? 
                    <CopyToClipboard text={item.card} onCopy={() => Dialog.info('已复制到剪贴板')}>
                      <div className={"day copy"}>复制</div>
                    </CopyToClipboard> : 
                    <div className={"day"}>{item.button}</div>
                  }
                  
                </div>
              )
            })}
          </div>
          <div className='desc style'>
            <div className="title">签到规则</div>
            <div className="text" dangerouslySetInnerHTML={{ __html: data?.sign_rule.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2') }}></div>
          </div>
        </div>
        {visible && <SignAlert gift={gift} visible={visible} action={() => setVisible(false)}></SignAlert>}
      </div>}
    </div>
  )
}
