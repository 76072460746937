import React, { useEffect, useState } from 'react'
import general from '../../../../general'
import Dialog from '../../../../lib/Dialog'
import { Avatar,Button,Switch } from 'antd-mobile'
import './css/TeamSetting.css'
import { CloseOutlined, RightOutlined } from '@ant-design/icons';
import { NavLink } from '../../../../lib/Navigation'
import { CSSTransition } from 'react-transition-group'

export default function TeamSetting(props) {

  const tid = props.query.tid
  const [info,setInfo] = useState()
  const [alert,setAlert] = useState(false)
  const [alertType,setAlertType] = useState(0)
  const [ruleIndex,setRuleIndex] = useState(0)

  useEffect(()=>{
    getClusterInfo()
  },[])

  const getClusterInfo = ()=>{
    Dialog.loading()
    general.axios.post('/base/yunxin/getClusterInfo',{tid})
    .finally(()=>{
      Dialog.close()
    })
    .then(res=>{
      const { data, status } = res.data
      if (status.succeed){
        setInfo(data.info)
        if (data.info.role_list.length > 0){
          data.info.role_list.map((item,index)=>{
            if (item.zonename === data.info.bindRoleZoneName){
              setRuleIndex(index)
            }
          })
        }
      }else{
        Dialog.error(status.error_desc)
      }
    })
  }

  const retreatCluster = ()=>{
    Dialog.loading()
    general.axios.post('/base/yunxin/retreatCluster',{tid})
    .finally(()=>{
      Dialog.close()
    })
    .then(res=>{
      const { data, status} = res.data
      if (status.succeed){
        Dialog.info('退出群聊成功',() => general.history.go(-2))
      }else{
        Dialog.error(status.error_desc)
      }
    })
  }

  return (
    <div className='team-setting'>
      {info ?
      <div className='team-setting-container'>
        <div className="team-setting-header">
          <div className="info">
            <Avatar className='avatar' src={info?.icon}></Avatar>
            <span className='tname'>{info?.tname}</span>
          </div>
          <NavLink to={`/teamMembers?tid=${tid}`}>
            <div className="members flex">
              <div className='title'>群成员</div>
              <div>
                <span className='desc'>{info?.user_count}</span>
                <RightOutlined className='right-icon'></RightOutlined>
              </div>
            </div>
          </NavLink>
          {info.game && <div className="role flex" onClick={()=>{
            if (info.role_list.length == 0){
              return
            }
            setAlertType(0)
            setAlert(true)
          }}>
            <div className='title'>游戏角色绑定</div>
            <div>
              <span className='desc'>{info?.role_list.length == 0 ? '暂无角色' : (info?.bindRoleZoneName.length === 0 ? '暂未绑定' : info?.bindRoleZoneName)}</span>
              <RightOutlined className='right-icon'></RightOutlined>
            </div>
          </div>}
        </div>
        <div className="set">
          <div className='flex'>
            <div className='title'>消息免打扰</div>
            <div>
              <Switch defaultChecked={info?.is_nodisturb} onChange={(e)=>{
                general.axios.post('/base/yunxin/muteTeamMessage',{tid,ope:e?1:2})
                .then(res=>{
                  const { status } = res.data
                  if (!status.succeed){
                    Dialog.error(status.error_desc)
                  }else{
                    PubSub.publish('reload_is_nodisturb',{tid,is_nodisturb:e})
                  }
                })
              }} style={{
                '--checked-color': '#00b578',
                '--height': '27px',
                '--width': '45px'
              }}></Switch>
            </div>
          </div>
          <div className='flex'>
            <div className='title'>设为置顶</div>
            <div>
              <Switch defaultChecked={info?.is_top} onChange={(e)=>{
                general.axios.post('/base/yunxin/setTopTeams',{tid,ope:e?1:2})
                .then(res=>{
                  const { status } = res.data
                  if (!status.succeed){
                    Dialog.error(status.error_desc)
                  }else{
                    PubSub.publish('reload_is_top',{tid,is_top:e})
                  }
                })
              }} style={{
                '--checked-color': '#00b578',
                '--height': '27px',
                '--width': '45px',
              }}></Switch>
            </div>
          </div>
        </div>
        <div className="route flex" onClick={()=>{
          setAlertType(1)
          setAlert(true)
        }}>
          <div className='title'>群规则</div>
          <div>
            <RightOutlined className='right-icon'></RightOutlined>
          </div>
        </div>
        <Button size='large' shape='rounded' style={{
          '--background-color':'#ffc000',
          '--border-width':'0px',
        }} className="exit" onClick={retreatCluster}>退出群聊</Button>
      </div> : null}
      <CSSTransition in={alert} timeout={250} unmountOnExit={true}>
      <div className="team-setting-alert">
        <div className="team-setting-alert-mask" onClick={() => setAlert(false)}/>
        <div className="team-setting-alert-container">
          <div className="top">
            <div className="title">{alertType === 0 ? '游戏角色绑定' : info?.rule.title}</div>
            <CloseOutlined className='close' onClick={()=>setAlert(false)}></CloseOutlined>
          </div>
          <div className="main">
            {alertType === 0 ? 
            <div className='list'>
              {info?.role_list.map((item,index)=>{
                return <div className='item' key={index} onClick={()=>setRuleIndex(index)}>
                  <div className="title">{item.zonename}-{item.rolename}</div>
                  <img src={"resources/account/" + (index === ruleIndex ? "checked.png" : "check.png")}/>
                </div>
              })}
            </div> :
            <div className="content" dangerouslySetInnerHTML={{__html: info?.rule.content.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2')}}></div>}
          </div>
          <div className="bottom" onClick={()=>{
            if (alertType === 0){
              general.axios.post('/base/yunxin/bindRoleUser',{tid,role_id:info.role_list[ruleIndex].id})
              .then(res=>{
                const {data,status} = res.data
                if (status.succeed){
                  info.bindRoleZoneName = info.role_list[ruleIndex].zonename
                  setAlert(false)
                }else{
                  Dialog.error(status.error_desc)
                }
              })
            }else{
              setAlert(false)
            }
          }}>确定</div>
        </div>
      </div>
      </CSSTransition>
    </div>
  )
}
