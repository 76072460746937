import general from "../../general"

export default class Queue {

  static init = () => {
    return new Promise (resolve => {
      general.axios.post('/getUnid')
      .then(res => {
        let {status, data} = res.data;
        resolve(data.unid)
      })
    })
  }

  static active = (params)=>{
    return new Promise (resolve => {
      general.axios.post('/eventActive',params)
        .then(res => {
          resolve(res)
        })
    })
  }

  static upload = (params)=>{
    return new Promise (resolve => {
      general.axios.post('eventSubmit',params)
        .then(res => {
          resolve(res)
        })
    })
  }

}

