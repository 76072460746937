import React, {useEffect, useState} from "react";
import general from "../../general";
import $, { data } from "jquery";
import {useLocalStorageState, useLockFn, useScroll} from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import Operate from "./Operate";
import {Link, useHistory} from "react-router-dom";
import moment from "moment";
import {CSSTransition} from "react-transition-group";

export default props => {
  useEffect(() => {
    props.setOperate(<Operate type="system" on={() => load({page: 1})}/>);
  }, []);
  const history = useHistory();
  const [groups, setGroups] = useLocalStorageState('message-group', []);
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const [loaded, setLoaded] = useState(false);

  const [vipcard, setVipcard] = useState(false)
  const [temporaryUrl, setTemporaryUrl] = useState(); //新的临时的支付链接 20220408
  const [vipRouterIndex, setVipRouterIndex] = useState(0);//新的支付弹框的选项 20220408
  const [vipMenuSign, setVipMenuSign] = useState([]);
  const [paying, setPaying] = useState(false);
  const [payUrl, setPayUrl] = useState();
  const [price, setPrice] = useState(0);

  const load = useLockFn(async (parms = {}) => {
    Dialog.loading();
    let tmp = {
      pagination: {count: 20, page: page},
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios.post('mess_index_data', tmp)
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          if (parms.page) {
            setPage(parms.page);
          }
          let {cate_message: groups, system_message: {message_list: list, paginated}} = data;
          let tmp = [];
          for (const key in groups) {
            tmp.push(groups[key]);
          }
          setLoaded(true);
          setGroups(tmp);
          setDatas(datas => parms.page === 1 || !parms.page ? list : [...datas, ...list]);
          setMore(paginated.more && list.length > 0);
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });
  useEffect(() => {
    props.active && load();
  }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 >= $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, props.active]);
  // console.log(datas);
  //系统消息点击
  const sysMesOnclick = item => {
    if(item.message_type == '3') {
      history.push('/rebate/detail?id=' + item.message_params);
    } else if(item.message_type == '2') {
      Dialog.confirm({
        title: item.content,
        yes: () => {
          Dialog.loading();
          general.axios.post('/base/receiveBalance', {id:item.message_params})
          .finally(() => {
            Dialog.close();
          })
          .then(res => {
            let {status, data} = res.data;
            if(status.succeed !== 1) {
              Dialog.info(status.error_desc);
            } else {
              Dialog.info('领取成功');
              load();
            }
          })
          .catch(err => {
            Dialog.error(err.message);
          });
        },
      });
    } else if(item.message_type == '1') {
      general.messagenew = item;
      history.push('/message/new');
    } else if(item.message_type == '4') {
      //互动消息
      history.push('/message/infobox?id=' + item.message_id);
    } else if(item.message_type == '5') {
      //会员到期提醒
      // history.push('/save?type=vip');
      history.push('/save/newvip');
    } else if(item.message_type == '6') {
      //至尊版到期提醒
      setVipMenuSign(item);

      let payUrl = item.supremePayUrl + "&from=react";
      setPayUrl(payUrl + "&version=20220408&amount=" + item.vipSignMenu.items[0].price);
      setTemporaryUrl(payUrl);
      setPrice(item.vipSignMenu.items[0].price);
      setVipcard(true);
      return;
    } else if(item.message_type == '0') {
      history.push('/trade/detail?id=' + item.link_value);
    }
  }

  return <div className="message-index">
    <div className="message-group">
      {groups.map(group => group.type === 'topic' ? null : <Link to={'/message/' + group.type} className="item">
        <div className="icon"><Image src={'/resources/message/' + group.type + '.png'} alt=""/></div>
        <div className="info">
          <div className="name">{group.title}</div>
          {group.message_total ? <div className="data">{group.message_total}条消息</div> : <div className="empty">暂无新消息通知</div>}
        </div>
      </Link>)}
    </div>
    <div className="message-sys">
      <div className="sys-title">系统通知</div>
      <div className="sys-items">

      </div>
    </div>


    {/* {datas.length > 0 ? <div className="view-end">别撩啦！已经到底啦~</div> : null} */}

    {datas.length > 0 ? 
    <>
    {datas.map(item=>
    <div className="sys-mess-index">
      <div className="sys-mess-data" onClick={()=>sysMesOnclick(item)}>
        <div className="data-head">
          <div className="text">
            {item.title}
          </div>
          <div className="time">
            {moment(item.message_time * 1000).format('MM-DD HH:mm')}
          </div>
        </div>
        <div className="tips">
          {item.content}
        </div>
        <div className="operate">
          {(()=>{
            if(item.message_type == '4') {
              return "进入对话框>"
            } else if(item.message_type == '5' || item.message_type == '6') {
              return "点击续费>";
            } else {
              return "查看详情>"
            }
          })()}
        </div>
      </div> 
    </div>
    )}
    </>
    : null}

    {datas.length > 0 ? <div className="view-end">别撩啦！已经到底啦~</div> : null}

    {datas.length === 0 && loaded ? <div className="recovery-empty"><img src="/resources/message/message_empty.png" alt=""/>暂无系统消息</div> : null}


    <CSSTransition in={paying} timeout={250} unmountOnExit={true}>
      <div className="game-save-pay">
        <div className="mask" onClick={() => setPaying(null)}/>
        <div className="container">
          <div className="title">支付</div>
          <iframe src={payUrl}/>
        </div>
      </div>
    </CSSTransition>


    <CSSTransition in={vipcard} timeout={250} unmountOnExit={true}>
        <div className="game-vipcard-detail">
          <div className="vipcard-mask" />
          <div className="vipcard-container">
            <div className="vipcard-top">
              <div className="text">
                <div className="name">{vipMenuSign?.vipSignMenu?.title} <img src="/resources/game/tips-wh.png"  onClick={() => Dialog.alert('温馨提示', vipMenuSign?.vipSignMenu?.bottomTips)}/></div>
                <div className="desc">{vipMenuSign?.vipSignMenu?.comment1}</div>
                <div className="useful">
                  {vipMenuSign?.vipSignMenu?.useDesc}
                </div>
              </div>
              <div className="close" onClick={()=> {
                  setVipcard(false);
                }}>
                <img src="/resources/party/couple-close.png" />
              </div>
            </div>
            <div className="vipcard-tips">
              {vipMenuSign?.vipSignMenu?.comment2}
            </div>
            <div className={"vipcard-main " + (vipMenuSign?.vipSignInfo?.isRealBuy ? "renewal" : "")}>
              {
                vipMenuSign?.vipSignMenu?.items.map((item,index) => <div className={"item " + (index == vipRouterIndex ? 'active' : '')} onClick={()=>{setVipRouterIndex(index);setPayUrl(temporaryUrl + '&version=20220408&amount='+item.price);setPrice(item.price)}}>
                  {item.topLable ? 
                    <div className="good" style={{backgroundImage:'url(/resources/game/vipcard-good.png)'}}>
                      {item.topLable}
                    </div>
                    : null
                  }
                  <div className="price">￥<span>{item.price}</span></div>
                  <div className="tag">{item.buttonTitle}</div>
                  <div className="tip2">
                    <div className="intro">{item.comment1}</div>
                    <div className="object">{item.comment2}</div>
                  </div>
                </div>)
              }
            </div>
            <div className="vipcard-bottom" onClick={()=>{
                setPaying(true);
              }} >{vipMenuSign?.vipSignMenu?.items[vipRouterIndex].submitButtonTitle}</div>
          </div>
        </div>
    </CSSTransition>


  </div>;
};