import React, {useEffect, useState} from "react";
import general from "../../general";
import {useLockFn} from "ahooks";
import Dialog from "../../lib/Dialog";

export default props => {
  const [content, setContent] = useState([]);

  useEffect(() => {
    props.setTitle(content.title);
  }, [content]);

  const load = useLockFn(async (parms = {}) => {
    Dialog.loading();
    let tmp = {
        news_id: props.query.id
    };
    general.axios.post('base/news/getNewsDetail', tmp)
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }

          let datas = {...data, ...data.news_info};
          setContent((datas));

        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });

  useEffect(() => {
    load();
  }, [props.query]);

  return <div className="message-index">
    <div className="" style={{padding:'.2rem'}}>
      <div dangerouslySetInnerHTML={{__html: content.content}}></div>
      {/* <p><strong>紧急通知：</strong></p>
      <p>&nbsp; &nbsp; <strong>根据2021年8月30日国家新闻出版署下发通知，要求进一步严格管理切实防止未成年人沉迷网络游戏</strong>；</p>
      <p>&nbsp; &nbsp; 本平台将积极配合国家规定，对未进行实名认证的用户统一采取关闭游戏登录措施！请大家务必尽快完成实名认证，<strong>平</strong><strong>台所有游戏将于2021年9月1日全部开启限制，届时未完成实名认证及未满18周岁的用户将无法进入游戏！！！</strong></p>
      <p>&nbsp; &nbsp; <strong><span style={{color: 'rgb(255, 0, 0)'}}>完成实名认证即可领取30金币奖励，请前往APP内“赚金”-”新手任务”内领取哦~</span></strong></p> */}
    </div>
  </div>;
};