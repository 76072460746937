import React from "react";
import {useLocalStorageState} from "ahooks";
import KeepScroll from "../../lib/KeepScroll";
import Ptb from "./Ptb";
import Game from "./Game";
import Time from "./Time";
import {Link} from "react-router-dom";
import {QuestionCircleOutlined} from '@ant-design/icons';
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";

const types = [
  {
    id: '',
    text: '平台币兑换',
    component: Ptb,
  },
  {
    id: '',
    text: '游戏礼品兑换',
    component: Game,
  },
  {
    id: '',
    text: '限时兑换',
    component: Time,
  }
];

export default props => {
  const [user] = useActiveState('user');
  const [typeIndex, setTypeIndex] = useLocalStorageState('credit-type', 0);

  return <div className="credit-index">
    <div className="credit-head">
      <div className="container">
        <div className="banner" style={{backgroundImage: 'url(/resources/credit/bg.png)'}}>
          <div className="btns">
            <Link to="/user/credit" className="btn">兑换记录</Link>
            <Link to="/credit/gift" className="btn">我的礼包</Link>
          </div>
        </div>
        <div className="info">
          <div className="credit">我的积分：{user.integral || '?'}</div>
          <div className="operate" onClick={() => Dialog.alert('如何获得积分', <div>
            1.每日签到<br/>
            2.发布游戏优质评论<br/>
            3.完成每日积分任务<br/>
            4.交易角色够买<br/>
            5.后续会陆续开放更多积分获取途径<br/>
          </div>)}>如何获得积分<QuestionCircleOutlined className="icon"/></div>
        </div>
      </div>
      <div className="type">
        {types.map((item, index) => <div className={'item' + (index === typeIndex ? ' active' : '')} onClick={() => setTypeIndex(index)}>{item.text}</div>)}
      </div>
    </div>
    <div className="credit-body">
      {types.map((item, index) => <div className={'type-item' + (index === typeIndex ? ' active' : '')}>
        <KeepScroll active={props.active && index === typeIndex ? 'active' : ''}>
          <item.component active={props.active && index === typeIndex ? 'active' : ''} action={props.action}/>
        </KeepScroll>
      </div>)}
    </div>
  </div>;
};