import React, { useEffect, useState } from 'react'
import general from '../../../../general'
import './css/Announcement.css'
import { CSSTransition } from 'react-transition-group'
import { CloseOutlined } from '@ant-design/icons'
import moment from 'moment'

export default function Announcement(props) {

  const [list,setList] = useState([])
  const [item,setItem] = useState()
  const [alert,setAlert] = useState(false)

  useEffect(()=>{
    getClusterInfo()
  },[])

  useEffect(()=>{
    if (alert){
      readAnnoument()
    }
  },[alert])

  const getClusterInfo = ()=>{
    general.axios.post('/base/yunxin/getClusterInfo',{tid:props.query.tid})
    .then(res=>{
      const { data, status } = res.data
      if (status.succeed){
        setList(data.info.announcement.list)
        if (props.query.open){
          setItem(data.info.announcement.list[0])
          setAlert(true)
        }
      }else{
        Dialog.error(status.error_desc)
      }
    })
  }

  const readAnnoument = ()=>{
    general.axios.post('/base/yunxin/readAnnoument',{id:item.id})
    .then(res=>{
      const { data, status } = res.data
      if (status.succeed) {
        item.is_read = true
      }else{
        Dialog.error(status.error_desc)
      }
    })
  }

  return (
    <div className='announcement-index'>
    {list.map((item,idx)=>{
      return <div className="item" key={idx} onClick={()=>{
        setItem(item)
        setAlert(true)
      }}>
        <div className="header">
          <span className="tag" style={{display:item.is_top ? '' : 'none'}}></span>
          <span className="title">{item.title}</span>
          <div className='point' style={{display:item.is_read ? 'none' : ''}}></div>
        </div>
        <div className="content" dangerouslySetInnerHTML={{__html: item.content.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2')}}></div>
        <div className="time">{moment(item.time*1000).format('YYYY-MM-DD HH:mm:ss')}</div>
      </div>
    })}
    <CSSTransition in={alert} timeout={250} unmountOnExit={true}>
      <div className="announcement-alert">
        <div className="announcement-mask" onClick={() => setAlert(false)}/>
        <div className="announcement-container">
          <div className="announcement-top">
            <div className="title"></div>
            <CloseOutlined className='close' onClick={()=>setAlert(false)}></CloseOutlined>
          </div>
          <div className="announcement-main">
            <div className="title">{item?.title}</div>
            <div className="desc" dangerouslySetInnerHTML={{__html: item?.content.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2')}}></div>
            <div className="time">{item ? moment(item.time*1000).format('YYYY-MM-DD HH:mm:ss') : 'aaa'}</div>
          </div>
        </div>
      </div>
    </CSSTransition>
    </div>
  )
}
