import {useHistory} from "react-router-dom";
import React, {useState} from "react";
import general from "../../general";
import {CloseOutlined} from "@ant-design/icons";
import useActiveState from "../../lib/useActiveState";
import {useLockFn} from "ahooks";
import Dialog from "../../lib/Dialog";

export default props => {
  const history = useHistory();
  const [user, setUser] = useActiveState('user');
  const [data, setData] = useState(user);
  const [focus, setFocus] = useState('');
  const [saving, setSaving] = useState(false);
  const save = useLockFn(async () => {
    if (saving) {
      return;
    }
    if (!data.nick_name) {
      Dialog.error('请输入昵称');
      return;
    }
    setSaving(true);
    Dialog.loading();
    await general.axios.post('/user/user/editMemberInfo', {nick_name: data.nick_name})
        .finally(() => {
          setSaving(false);
          Dialog.close();
        })
        .then(response => {
          let {status} = response.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            setUser({...user, ...data});
            Dialog.success('保存成功', () => history.goBack());
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });

  return <div className="user-nickname">
    <div className="user-input">
      <div className="name">昵称</div>
      <input name="nick_name" type="text" value={data.nick_name} placeholder="请输入昵称" autoComplete="off" onFocus={() => setFocus('nick_name')} onChange={e => setData({...data, nick_name: e.target.value})}/>
      {focus === 'nick_name' && data.nick_name ? <CloseOutlined className="close" onClick={() => setData({...data, nick_name: ''})}/> : null}
    </div>
    <div className="user-btn" onClick={save}>保存</div>
  </div>;
};