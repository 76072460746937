import React, { useEffect, useState } from 'react'
import './css/giftproject.css'
import GameList from './GameList'
import general from '../../../general'
import Dialog from '../../../lib/Dialog'
import ReceiveList from './ReceiveList'
import { NavLink } from '../../../lib/Navigation'
import { useScroll } from 'ahooks'

export default function GiftProject(props) {

  const [index, setIndex] = useState(0)
  const [show, setShow] = useState(false)

  const scroll = useScroll()
  useEffect(()=>{
    if (scroll.top > 40){
      props.setTitle('领648福利')
    }else {
      props.setTitle('')
    }
  },[scroll])

  return (
    <div className='sign-gift'>
      <div className="container">
        {show && <img className='top-icon' src="resources/game/sign/648.png" alt="" />}
        <div className="content">
          {show &&<div className="header">
            <div className={"item " + (index == 0 ? 'active' : '')} onClick={() => setIndex(0)}>游戏列表</div>
            <NavLink className={"item " + (index == 1 ? 'active' : '')} onClick={() => {
              if (general.isLogin()){
                setIndex(1)
              }
            }}>我的领取</NavLink>
          </div>}
          <div className="list">
            <div style={{ display: index == 0 ? 'block' : 'none' }}>
              <GameList active={props.active && index == 0} handleShow={()=>setShow(true)}></GameList>
            </div>
            <div style={{ display: index == 1 ? 'block' : 'none' }}>
              <ReceiveList active={props.active && index == 1}></ReceiveList>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
