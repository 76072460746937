import {useState, useEffect} from "react";
import general from "../../general";
import {useMount, useToggle, useLocalStorageState, useLockFn, useInterval} from "ahooks";
import {CloseOutlined, DownOutlined, EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {prod, url} from "../../env";
import {Link, useHistory} from "react-router-dom";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";
import URI from "urijs";
import MobileDetect from "mobile-detect";
import {NavLink} from "../../lib/Navigation";
import useUrlState from "@ahooksjs/use-url-state";
import {CSSTransition} from "react-transition-group";
import { CenterPopup } from 'antd-mobile'

const mobileDetect = new MobileDetect(navigator.userAgent);
const phoneNumberServer = new PhoneNumberServer();
phoneNumberServer.setLoggerEnable(false);

export default props => {
  // useEffect(() => {
  //     props.setOperate(<Link to="/login" className="rebate-index-guide">密码登录</Link>);
  // }, []);
  const history = useHistory();
  const {aplus_queue} = window;
  const [app] = useActiveState('app');
  useMount(() => {
    general.callback = props.query.callback || null;
  });
  const [datas, setDatas] = useLocalStorageState('users-0804', []);
  const [focus, setFocus] = useState('');
  const [password, {toggle: togglePassword}] = useToggle(true);
  const [data, setData] = useState(datas.length > 0 ? {...datas[0]} : {});
  // const [agreement, setAgreement] = useLocalStorageState('agreement', false);
  const [config, setConfig] = useActiveState('config');
  const [agreement, setAgreement] = useState(true);
  const [loging, setLoging] = useState(false);
  const [user, setUser] = useActiveState('user');

  const [codIng, setCodIng] = useState(false);
  const [codeTime, setCodeTime] = useState(0);
  const [users, setUsers] = useState([]);//多个账号
  const [agent] = useActiveState('agent');
  const [areaNumData, setAreaNumData] = useState([]);
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});
  const [whether, setWhether] = useActiveState('whether');//是否进行过退出操作
  const [toggle,setToggle] = useState(localStorage.getItem('toggle') || (config.isOverseas ? '1' : '0'))
  const [showLogin,setShowLogin] = useState(false)
  const [loginAlert,setLoginAlert] = useState(false)
  const [authIdx,setAuthIdx] = useState(0)
  const [authCode,setAuthCode] = useState('')
  const [authCoding, setAuthCoding] = useState(false);
  const [authCodeTime, setAuthCodeTime] = useState(0);
  const [authArray, setAuthArray] = useState();
  const [authMobile, setAuthMobile] = useState('');
  const [visible, setVisible] = useState(false)
  const [autoLogin, setAutoLogin] = useState(false)

  useEffect(() => {
    if (autoLogin && agreement){
      login()
    }
  }, [autoLogin,agreement]);

  useEffect(() => {
    setAreaNumData(general.areaNumber);
  }, [props.active]);

  useInterval(() => {
    if (codeTime) {
      setCodeTime(codeTime - 1);
    }
  }, 1000);
  const codeGet = () => {
    if (!data.phone) {
        Dialog.error('请输入手机号');
        return;
    }
    if (codIng || codeTime) {
      return;
    }
    // if(agent == 'rr2f') {
    //   Dialog.info("当前无法完成登录，请联系客服进行处理！");
    //   return;
    // }


    setCodIng(true);
    Dialog.loading();
    general.axios.post('/base/tool/getVerifyCode', {
      mobile: (areaNumData?areaNumData:'86') + '-' + data.phone,
      code: 'code',
      type: '08',
    })
    .finally(() => {
      setCodIng(false);
      Dialog.close();
    })
    .then(res => {
      let {status} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
      } else {
        setCodeTime(60);
      }
    })
    .catch(err => {
      Dialog.error(err.message);
    });
  };

  const login = () => {
    if (toggle === '0'){
      if (!data.phone) {
        Dialog.error('请输入手机号');
        return;
      }
      if (!data.code) {
        Dialog.error('请输入验证码');
        return;
      }
      if (!agreement) {
        // Dialog.error('请阅读下方的协议');
        setVisible(true)
        return;
      }
      if (loging) {
        return;
      }
  
      let location = window.location.href;
      if(window.location.host == 'webbox.99maiyou.cn') {
        if(params.agent) {
          location = window.location.href
        } else {
          location = window.location.href + "?agent=" + agent;
        }
      }
  
      Dialog.loading();
      general.axios.post('/smsLogin', {
        mobile: (areaNumData?areaNumData:'86') + '-' + data.phone,
        code: data.code,
        location_href: agent == 'rr2f' ? location : ''
      })
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            let users = data.users;
            if (users.length < 2) {
              setWhether('true');
              if(!data.isSetPassword) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('user_id', data.user_id);
                localStorage.setItem('username', data.username);
                aplus_queue.push({
                  action: 'aplus.record',
                  arguments: ['MobileNumberRegisterSuccess', 'CLK', {username: data.username, agent: agent}]
                });
                aplus_queue.push({
                  action: 'aplus.record',
                  arguments: ['register', 'CLK', {username: data.username, agent: agent}]
                });

                //临时测试记录--渠道号不一致
                if(agent == 'rr2f') {
                  // temporaryAdjuct();
                }

                history.push('/user/setpassword');
              } else {
                localStorage.setItem('token', data.token);
                localStorage.setItem('user_id', data.user_id);
                localStorage.setItem('username', data.username);
                aplus_queue.push({
                  action: 'aplus.record',
                  arguments: ['MobileNumberLoginSuccess', 'CLK', {username: data.username, agent: agent}]
                });
                aplus_queue.push({
                  action: 'aplus.record',
                  arguments: ['login', 'CLK', {username: data.username, agent: agent}]
                });
                load_load()
              }
            } else {
              setUsers(users);
            }
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
    }else{
      if (!data.username) {
        Dialog.error('请输入用户名');
        return;
      }
      if (!data.password) {
        Dialog.error('请输入密码');
        return;
      }
      if (!agreement) {
        setVisible(true)
        return;
      }
      if (loging) {
        Dialog.error(loging);
        return;
      }
      setLoging(true);
      Dialog.loading();
      general.axios.post('/user/user/login', data)
          .finally(() => {
            // Dialog.info("login.预返回");
            setLoging(false);
            Dialog.close();
          })
          .then(res => {
            // Dialog.info("login.then方法");
            let {status, data: result} = res.data;
            // Dialog.info(status.succeed);
            if (!status.succeed) {
              Dialog.error(status.error_desc);
              if(status.error_code == '1046') {
                setTimeout(()=>{
                  // window.history.back();
                  setToggle('0');
                }, 1000);
              }
            } else {
              if (parseInt(result['isLock']) == 1){
                setLoginAlert(true)
                const motheds = result['motheds']
                setAuthArray([...motheds])
              }else{
                localStorage.setItem('user_id', result.user_id);
                localStorage.setItem('username', data.username);
                localStorage.setItem('token', result.token);
                localStorage.setItem('toggle', '1');
                let tmp = datas.filter(item => item.username !== data.username);
                tmp.unshift(data);
                setDatas(tmp);
                aplus_queue.push({
                  action: 'aplus.record',
                  arguments: ['AccountPasswordLoginSuccess', 'CLK', {username: data.username, agent: agent}]
                });
                aplus_queue.push({
                  action: 'aplus.record',
                  arguments: ['login', 'CLK', {username: data.username, agent: agent}]
                });
                // Dialog.info(result.user_id);
                load_load();
                setWhether('true');
              }
            }
          })
          .catch(err => {
            Dialog.error(err.message);
          });
    }
    
  }

  const load_load = useLockFn(async () => {
    await general.axios.post('/user/user/getMemberInfo')
      .finally(() => {
        // Dialog.info("getMemberInfo.then方法");
      })
      .then(response => {
        // Dialog.info("getMemberInfo.then方法");
        let {status, data} = response.data;
        // Dialog.info(status.succeed);
        if (!status.succeed) {

        } else {
          setUser({isLogin: true, ...data.member_info});
          Dialog.success('登录成功', () => general.goBack(true));
        }
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  })

  const finalLogin = user => {
    if (loging) {
      return;
    }
    setLoging(true);
    Dialog.loading();
    general.axios.post('/tickLogin', {username: user.username, tick: user.tick})
        .finally(() => {
          setLoging(false);
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            setLoging(false);
            Dialog.error(status.error_desc);
            return;
          }
          localStorage.setItem('token', data.token);
          localStorage.setItem('user_id', data.user_id);
          localStorage.setItem('username', data.username);
          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['MobileNumberLoginSuccess', 'CLK', {
              username: data.username,
              agent: agent
            }]
          });
          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['login', 'CLK', {username: data.username, agent: agent}]
          });
          setWhether('true');
          load_load();
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  const temporaryAdjuct = ()=>{
    let data = {};
    data.agent = agent;
    data.host = window.location.href;
    data.username = localStorage.getItem("username");
    data.type = 'register';
    general.axios.post('/home/testGame/temporaryAdjuct', data)
        .finally(() => {
          setRegistering(false);
          Dialog.close();
        })
        .then(response => {
       
        })
        .catch(err => {

        });
  }

  // const load_load = useLockFn(async () => {
  //   await general.axios.post('/user/user/getMemberInfo')
  //     .finally(() => {
  //     })
  //     .then(response => {
  //       let {status, data} = response.data;
  //       if (!status.succeed) {

  //       } else {
  //         setUser({isLogin: true, ...data.member_info});
  //       }
  //     })
  //     .catch(err => {
  //         Dialog.error(err.message);
  //       });
  // })

  

  const updateAgreement = () => {
    let a = agreement == true ? false : (agreement == false ? true : false);
    setAgreement(a);
  }

  useEffect(()=>{
    getAccessToken()
  },[])

  const getAccessToken = ()=>{
    general.axios.post('/getAuthToken',{aliTokenHost:location.origin})
    .finally(() => {
      setCodIng(false);
    })
    .then(res => {
      let {status,data} = res.data;
      if (!status.succeed) {
        // Dialog.error(status.error_desc);
      } else {
        let tokenInfo = {
          JwtToken: data.jwtToken,
          AccessToken: data.accessToken,
        }
        checkLogin(tokenInfo.JwtToken, tokenInfo.AccessToken);
      }
    })
    .catch(err => {
      Dialog.error(err.message);
    });
  }

  const checkLogin = (jwtToken,accessToken)=>{
    phoneNumberServer.checkLoginAvailable({
      accessToken: accessToken,
      jwtToken: jwtToken,
      success: function (res) {
        console.log("身份鉴权成功, 可唤起登录界面", res);
        if (res.code === 600000) {
          // 在此调用getLoginToken方法
          getToken();
        }
      },
      error: function (res) {
        console.log("身份鉴权失败", res);
      },
    });
  }

  const getToken = (jwtToken, accessToken)=>{
    phoneNumberServer.getLoginToken({
      // 成功回调
      success: function (res) {
        // 一键登录: 可发请求到服务端调用 GetPhoneWithToken API, 获取用户手机号, 完成登录
        if (res.code === 600000) {
          Dialog.loading();
          general.axios.post('/cellularLogin',{authType:'ali',spToken:res.spToken})
          .finally(() => {
            setCodIng(false);
            Dialog.close();
          })
          .then(res => {
            let {status,data} = res.data;
            if (!status.succeed) {
              Dialog.error(status.error_desc);
            } else {
              const users = data.users;
              if (users.length < 2) {
                setWhether('true');
                const user = users[0]
                if(!user.isSetPassword) {
                  localStorage.setItem('token', user.token);
                  localStorage.setItem('user_id', user.id);
                  localStorage.setItem('username', user.username);
                  history.push('/user/setpassword');
                } else {
                  localStorage.setItem('token', user.token);
                  localStorage.setItem('user_id', user.id);
                  localStorage.setItem('username', user.username);
                  load_load()
                }
              } else {
                setUsers(users);
              }
            }
          })
          .catch(err => {
            Dialog.error(err.message);
          });
        }
      },
      // 失败回调
      error: function (res) {},
      // 授权页状态监听函数
      watch: function (status, data) {
        if (data.isSuccess){
          setShowLogin(data.isSuccess)
        }
      },
      // 配置选项
      authPageOption: {
        mount:'alilogin',
        navText: "一键登录",
        subtitle: "", // 副标题
        isHideLogo: true, // logo显示隐藏
        logoImg: "",
        btnText: "立即登录",
        agreeSymbol: " ",
        privacyOne: ["《用户协议》", `//api3.app.wakaifu.com/userAgreement?channel=${agent}`],
        privacyTwo: ["《隐私政策》", `//api3.app.wakaifu.com/privacyPolicy?channel=${agent}`],
        showCustomView: false,
        customView: {
          element:
            '<div class="btn_box other" onclick="clickEvent()">切换其他登录方式</div>',
          style: ".btn_box.other{background: #fff; color: #f00}",
          js: "function clickEvent(){alert(666666)}",
        },
        privacyBefore: "我已阅读并同意",
        privacyEnd: "",
        vendorPrivacyPrefix: "《",
        vendorPrivacySuffix: "》",
        privacyVenderIndex: 2,
        isDialog: true, // 是否是弹窗样式
        manualClose: false, // 是否手动关闭弹窗/授权页
        isPrePageType: false,
        isShowProtocolDesc: false,
        // prePageTypeOption: {
        //     // mount: '',
        //     privacyOne: ['条款1', 'https://wap.cmpassport.com/resources/html/contract.html'],
        //     privacyTwo: ['条款2', 'https://wap.cmpassport.com/resources/html/contract.html'],
        //     // showCustomView: true,
        //     agreeSymbol: '及',
        //     tips: <div style={{ position: 'absolute', top: '10px', right: '10px', borderRadius: '30%', fontSize: '12px', color: '#fff'}}>tips</div>,
        //     btnText: '',
        // }
      },
      protocolPageWatch:function (params) {
        
      }
    });
  }

  const getAuthCode = ()=>{
    if (loging){
      return
    }
    if (authCodeTime > 0){
      return
    }
    setAuthCoding(true)
    Dialog.loading();
    general.axios.post('/base/tool/getVerifyCode', {
      username: data.username,
      type: '10',
    })
      .finally(() => {
        Dialog.close();
        setAuthCoding(false)
      })
      .then(response => {
        let result = response.data;
        if (!result.status.succeed) {
          Dialog.error(result.status.error_desc);
        } else {
          setAuthCodeTime(60);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }
  //绑定手机号发送验证
  const code = () => {
    if (authMobile.length == 0) {
      Dialog.error('请输入手机号');
      return;
    }
    if (authCodeTime > 0){
      return
    }
    setAuthCoding(true);
    Dialog.loading();
    general.axios.post('/base/tool/getVerifyCode', {
      mobile: (areaNumData?areaNumData:'86') + '-' + authMobile,
      username: data.username,
      type: '11',
    })
      .finally(() => {
        setAuthCoding(false);
        Dialog.close();
      })
      .then(response => {
        let result = response.data;
        if (!result.status.succeed) {
          Dialog.error(result.status.error_desc);
        } else {
          setAuthCodeTime(60);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const authSubmit = ()=>{
    if (loging){
      return
    }
    var params = {}
    if (authArray.length == 0){
      if (authMobile.length == 0) {
        Dialog.error('请输入手机号');
        return;
      }
      if (authCode.length == 0) {
        Dialog.error('请输入验证码');
        return;
      }
      params['username'] = data.username
      params['password'] = data.password
      params['mobile'] = authMobile
      params['code'] = authCode
      params['type'] = '3'
    }else{
      if (authCode.length == 0) {
        Dialog.error('请输入验证信息');
        return;
      }
      params['username'] = data.username
      params['code'] = authCode
      params['type'] = authArray[authIdx]['type']
    }
    Dialog.loading();
    general.axios.post('/unlockAccount',params)
    .finally(() => {
      setLoging(false);
      Dialog.close();
    })
    .then(response => {
      let result = response.data;
      if (!result.status.succeed) {
        Dialog.error(result.status.error_desc);
        setAuthCode('')
      } else {
        setLoginAlert(false)
        setAuthCode('')
        login()
      }
    })
    .catch(err => {
      Dialog.error(err.message);
    });
  }

  useInterval(() => {
    if (authCodeTime) {
      setAuthCodeTime(authCodeTime - 1);
    }
    console.log(authCodeTime);
  }, 1000);

  const closeAction = ()=>{
    setLoginAlert(false)
    setAuthCode('')
    setAuthMobile('')
  }

  return <div id="alilogin" className="account-verificode-login">
    <div className="verificode-body">
      <div className="logo"><img src={app.applogo} alt=""/></div>
      <div className="toggle">
        <div className={"item " + (toggle === '0' ? 'active' : '')} onClick={()=>setToggle('0')}>验证码登录</div>
        <div className={"item " + (toggle === '1' ? 'active' : '')} onClick={()=>setToggle('1')}>账号登录</div>
      </div>
      {toggle === '0' ?
      <div>
        <div className="input-item">
          <div className="label">
          {/* 手机号 */}
          <div className="area-num-text">
            <img src="/resources/account/jia.png" />
            <Link to="/account/country" className="value">
              {areaNumData ? areaNumData : '86'}
            </Link>
            <img src="/resources/account/sel-down.png" />
          </div>
          </div>
          <div className="input">
          <input name="phone" type="number" value={data.phone || ''} placeholder="请输入手机号"
                 autoComplete="off"
                 onFocus={e => setFocus('phone')}
                 onChange={e => setData({...data, phone: e.target.value})}
          />
          {focus == 'phone' && data.phone
              ? <CloseOutlined
                  className="assist close"
                  onClick={() => setData({...data, phone: ''})}
              />
              : null}
          </div>
        </div>
        <div className="input-item">
          <div className="label">验证码</div>
          <div className="input">
            <input name="code" type={'number'} value={data.code || ''} placeholder="请输入验证码" key="code"
                 onFocus={e => setFocus('code')}
                 onChange={e => setData({...data, code: e.target.value})}
            />
            {focus == 'code' && data.code
              ? <CloseOutlined
                  className="assist close"
                  onClick={() => setData(data => ({...data, code: ''}))}/>
              : null}
          </div>
          <div className={'code-btn ' + (codIng || codeTime ? 'disable' : '')} onClick={codeGet}>
          {codIng ? '发送中' : (codeTime ? (codeTime + '秒后重发') : '发送验证码')}
          </div>
        </div>
        <div className="login-desc">未注册的用户可直接注册登录</div>
      </div> : 
      <div>
        <div className="input-item">
          <div className="label">账号</div>
          <div className="input">
          <input name="username" type="text" value={data.username || ''} placeholder="请输入账号/手机号"
                 autoComplete="off"
                 onFocus={e => setFocus('username')}
                 onChange={e => setData({...data, username: e.target.value})}
                //  ref={inputRefs}
          />
          {focus == 'username' && data.username
              ? <CloseOutlined
                  className="assist close"
                  onClick={() => setData({...data, username: ''})}
              />
              : null}
          <div className="assist select">
            <DownOutlined className="select"/>
            <select onChange={e => {
              setData(e.target.value != '' ? datas[parseInt(e.target.value)] : {username: '', password: ''})
            }}>
              <option value="">手动输入</option>
              {datas.map((item, index) => <option key={index} value={index}>{item.username}</option>)}
            </select>
          </div>
        </div>
        </div>
        <div className="input-item">
          <div className="label">密码</div>
          <div className="input">
            <input name="password" type={password ? 'password' : 'text'} value={data.password || ''} placeholder="请输入密码" key="password"
                 onFocus={e => setFocus('password')}
                 onChange={e => setData({...data, password: e.target.value})}
            />
            {focus == 'password' && data.password
              ? <CloseOutlined
                  className="assist close"
                  onClick={() => setData(data => ({...data, password: ''}))}/>
              : null}
            <div className="assist password" onClick={() => togglePassword()}>
            {password ? <EyeInvisibleOutlined/> : <EyeOutlined/>}
            </div>
          </div>
        </div>
        <div className="register">
          <Link to="/register">立即注册</Link>
          <Link to="/forget">忘记密码?</Link>
        </div>
      </div>}
      <div className={'operate ' + (loging ? 'disable' : '')} onClick={login}>登录</div>
      {showLogin ? 
      <div style={{height:'50px',lineHeight:'3.5',margin:'0 auto',fontSize:'14px'}} onClick={getToken}>一键登录</div>
      : null}
    </div>
    {users.length > 0 ? <div className="verificodelogin-users">
      <div className="mask" onClick={() => setUsers([])}/>
      <div className="body">
        <div className="title">选择用户</div>
        <div className="users">
          {users.map(user => <div className="user" onClick={() => finalLogin(user)}>{user.username}</div>)}
        </div>
      </div>
    </div> : null}
    <div className="account-agreement">
      <label>
        <span className={'agreement' + (agreement ? ' checked' : '')}  onClick={updateAgreement}/>
        登录即代表您同意
        <NavLink to={(() => {
            // let url = URI(url + '/userAgreement')
            let url = URI('//api3.app.wakaifu.com/userAgreement')
                .addQuery('title', '用户协议')
                .addQuery('channel', agent)
                .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: '《用户协议》'}}
            user={false}
          >《用户协议》</NavLink>
        <NavLink to={(() => {
            // let url = URI(url + '/userAgreement')
            let url = URI('//api3.app.wakaifu.com/privacyPolicy')
                .addQuery('title', '隐私政策')
                .addQuery('channel', agent)
                .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: '《隐私政策》'}}
            user={false}
          >《隐私政策》</NavLink>
        {/* <Link to={'/iframe?url=' + url + '/userAgreement?title=用户协议&channel=ue70&device_type=' + (mobileDetect.os() == 'iOS'?10:20)}>《用户协议》</Link>、 */}
        {/* <Link to={'/iframe?url=' + url + '/privacyPolicy?title=隐私政策&channel=ue70&device_type=' + (mobileDetect.os() == 'iOS'?10:20)}>《隐私政策》</Link> */}
        <br/>
        接受免除或者限制责任、诉讼管辖约定等粗体标示条款
      </label>
    </div>
    <CenterPopup visible={visible} onMaskClick={()=>setVisible(false)}>
      <div className="centerpopup-login-alert">
        <div className="centerpopup-login-alert-container">
          <div className="centerpopup-content-title">登录须知</div>
          <div className="centerpopup-content-content">
            我已阅读同意<NavLink className="centerpopup-content-link" to={(() => {
            // let url = URI(url + '/userAgreement')
            let url = URI('//api3.app.wakaifu.com/userAgreement')
                .addQuery('title', '用户协议')
                .addQuery('channel', agent)
                .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: '《用户协议》'}}
            user={false}
          >《用户协议》</NavLink>
            、<NavLink className="centerpopup-content-link" to={(() => {
            // let url = URI(url + '/userAgreement')
            let url = URI('//api3.app.wakaifu.com/privacyPolicy')
                .addQuery('title', '隐私政策')
                .addQuery('channel', agent)
                .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: '《隐私政策》'}}
            user={false}
          >《隐私政策》</NavLink>接受免除或者限制责任、诉讼管辖约定等粗体标识条款
          </div>
          <div className="centerpopup-content-agree" onClick={()=>{
            setAgreement(true)
            setVisible(false)
            setAutoLogin(true)
          }}>同意并登录</div>
          <div className="centerpopup-content-cencel" onClick={()=>setVisible(false)}>取消</div>
        </div>
      </div>
    </CenterPopup>
    <CSSTransition in={loginAlert} timeout={250} unmountOnExit={true}>
      <div className="login-alert">
        <div className="login-mask" onClick={closeAction}/>
        <div className="login-container">
          <div className="login-top">
            <span>{authArray?.length > 0 ? '身份信息验证' : '绑定手机号'}</span>
            <img onClick={closeAction} className="close" src="resources/user/close.png" alt="" />
          </div>
          {authArray?.length == 0 ? 
          <div className="login-nickname">
            <div className="user-input">
              <div className="name">
                <div className="area-num-text">
                  <img src="/resources/account/jia.png" />
                  <Link to="/account/country" className="value">
                    {areaNumData ? areaNumData : '86'}
                  </Link>
                  <img src="/resources/account/sel-down.png" />
                </div>
              </div>
              <input name="mobile" type="text" placeholder="请输入手机号" autoComplete="off" onChange={e => setAuthMobile(e.target.value)}/>
            </div>
            <div className="user-input">
              <div className="name">验证码</div>
              <input name="code" type="text" placeholder="请输入验证码" autoComplete="off" onChange={e => setAuthCode(e.target.value)}/>
              <div className={'btn ' + (authCoding || authCodeTime ? 'disable' : '')} onClick={code}>
                {authCoding ? '发送中' : (authCodeTime ? (authCodeTime + '秒后重发') : '发送验证码')}
              </div>
            </div>
          </div> :
          <div>
            <div className="login-main">
              {authArray?.map((item,index)=>{
                return <div className="login-item" onClick={()=>setAuthIdx(index)}>
                  <div className="title">{item.name}</div>
                  <img src={"resources/account/" + (authIdx == index ? "checked.png" : "check.png")} alt="" />
                </div>
              })}
            </div>
            <div className="login-bottom">
              <input type="text" value={authCode} placeholder={(authArray ? authArray[authIdx]['tips'] : '')} onChange={(e)=>{
                setAuthCode(e.target.value)
              }}/>
              <span className="code" onClick={getAuthCode} style={{display:(authArray && authArray[authIdx]['name'].search('验证码') !== -1 ? '':'none')}}>{authCoding ? '发送中' : (authCodeTime ? (authCodeTime + '秒后重发') : '获取验证码')}</span>
            </div>
          </div>}
          <div className={"login-submit"} onClick={authSubmit}>确定</div>
        </div>
      </div>
    </CSSTransition>
  </div>;
};