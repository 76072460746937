import {imgPlaceholder} from "../general";
import {useState} from "react";

export default props => {
  const [load, setLoad] = useState();
  const [error, setError] = useState();

  return <img
      {...({...props, ...(error || !props.src ? {src: imgPlaceholder.default} : [])})}
      onLoad={() => setLoad(true)}
      onError={() => setError(true)}
      style={{
        ...props.style,
        background: 'url(' + (!(load || error) ? imgPlaceholder.default : '') + ') center no-repeat',
        // backgroundSize: '100% 100%',
        WebkitBackgroundSize: '100% 100%',
      }}/>
};