import React, { useEffect, useRef, useState } from 'react'
import moment from "moment";
import general from '../../general';
import { Image } from 'antd-mobile'

export default function CzjlItem(props) {

  const [trade] = useState(props.children);

  return (
    <div className='czjl-item' onClick={()=>{
      general.history.push('/trade/detail?id=' + trade.trade_id)
    }}>
      <div className="content">
        <Image className='icon' placeholder={
              <img src='resources/placeholder/game-icon.png' alt="" />
            }  src={trade.account_icon} alt="" />
        <div className="info">
          <div className='game-name'>
            <span className='name'>{trade.game_name}</span>
            <span className='nameRemark'>{trade.nameRemark}</span>
          </div>
          <div className="text">{trade.title}</div>
          <div className="text">
            上架时间:{moment(trade.sell_time * 1000).format('YYYY-MM-DD HH:mm:ss')}
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="service">
          区服：{trade.server_name}&nbsp;累充金额：<span>{trade.total_amount}</span>元
        </div>
        <div className="price">{trade.trade_price}</div>
      </div>
    </div>
  )
}
