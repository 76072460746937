import { useEffect, useState } from 'react'
import App from './App'
import { useStateContext } from '@xkit-yx/im-kit-ui'
import general from './general'
import { redux } from './general'

export default function IMAppContainer() {

  const { store,nim } = useStateContext()
  const [allUnreadCount,setAllUnreadCount] = useState(0)

  useEffect(()=>{
    redux.dispatch({
      type:'unread',
      data:{
        unread:allUnreadCount
      }
    })
  },[allUnreadCount])

  const handleUnread = ()=>{
    setAllUnreadCount(store.uiStore.sessionUnread)
    // store.sessionStore.sessions.forEach((session,sessionId)=>{
    //   store.teamMemberStore.getTeamMemberActive(session['to']).then(members=>{
    //     members.forEach(member=>{
    //       if (member['account'] === localStorage.getItem('yx_account') && member['muteTeam'] === true) {
    //         store.sessionStore.resetSession(sessionId).then(res=>{
    //           setTimeout(() => {
    //             setAllUnreadCount(store.uiStore.sessionUnread)
    //           }, 500);
    //         })
    //       }
    //       setTimeout(() => {
    //         setAllUnreadCount(store.uiStore.sessionUnread)
    //       }, 500);
    //     })
    //   })
    // })
  }

  const filterUnreadCount = ()=>{
    setTimeout(() => {
      handleUnread()
    }, 1000);
  }

  useEffect(()=>{
    nim.on('msg',msg=>{
      setTimeout(() => {
        handleUnread()
      }, 100);
    })
    nim.on('syncdone',res=>{
      localStorage.setItem('yx_login',true)
      filterUnreadCount()
    })
    nim.on('disconnect',()=>{
      nim.connect().then(()=>{

      })
    })
  },[])

  return (
      <App/>
    )
}
