import axios from "axios";
import Qs from "qs";
import {createStore} from 'redux';
import {url} from "./env";
import MobileDetect from "mobile-detect";


const reducer = (state = {}, action) => {
  if (action.data) {
    return action.data;
  }
  return state;
}

export const redux = createStore(reducer);
// const createAxios = axios.create({baseURL: url, timeout: 600000});

// createAxios.defaults.timeout = 600000;

const mobileDetect = new MobileDetect(navigator.userAgent);


const createAxiosNew = axios.create({baseURL: url/*, timeout: 15000*/});

createAxiosNew.interceptors.request.use(
    config => {
      config.paramsSerializer = params => Qs.stringify(params, {arrayFormat: 'indices'})
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      return config;
    },
    err => {
      return Promise.reject(err);
    }
)

// console.log(window.location.hash.substring(1));
const generalnew = {
  history: null,
  location: null,
  goBack(callback) {
    if (callback) {
      if (generalnew.callback) {
        generalnew.callbacking = true;
        var si = setInterval(() => {
          let url = window.location.hash.substring(1);
          if (url === generalnew.callback || url === '/') {
            clearInterval(si);
            generalnew.callbacking = null;
            generalnew.callback = null;
            redux.dispatch({
              type: 'type',
              data: {
                url: url,
              }
            });
          } else {
            window.history.back();
          }
        }, 250);
      }
    }
  },
  axiosnew: createAxiosNew,
}

export default generalnew;