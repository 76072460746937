import React, { useEffect, useState } from 'react'
import { Popup, Space } from 'antd-mobile'
import './css/recommend.css'
import general from '../../../general'
import useActiveState from '../../../lib/useActiveState'

export default function Recommend() {

  const [visible, setVisible] = useState(false)
  const [data, setData] = useState([])
  const [arrar, setArray] = useState([])
  const [likeTags, setLikeTags] = useActiveState('likeTags')

  useEffect(()=>{
    getData()
  },[])
  
  const getData = ()=>{
    general.axios.post('/getTags')
    .then(res=>{
      const { status, data } = res.data
      if (status?.succeed == 1 && !localStorage.getItem('likeTags') && window.navigator.standalone){
        setData(data)
        setVisible(true)
      }
    })
  }

  const done = ()=>{
    general.axios.post('/saveLikeTags',{unid:localStorage.getItem("appkey"),tags:arrar.join(',')})
    .then(res=>{
      const { status, data } = res.data
      if (status.succeed == 1){
        setVisible(false)
        localStorage.setItem('likeTags','1')
        setLikeTags('1')
      }
    })
  }

  return (
    <div className='recommend-index'>
      <Space>
        <Popup visible={visible} bodyClassName='recommend-index-content' bodyStyle={{
          borderTopLeftRadius: '13px',
          borderTopRightRadius: '13px',
          overflow: 'hidden',
        }}>
          <div className='close' onClick={()=>{
            localStorage.setItem('likeTags','1')
            setVisible(false)
          }}>跳过</div>
          <div className='title'>选择感兴趣的游戏分类</div>
          <div className="container">
            {data.map(item=>{
              return (
                <div key={item.id} 
                  className={'item ' + (arrar.indexOf(item.id) == -1 ? '' : 'active')}
                  onClick={()=>{
                    if (arrar.indexOf(item.id) == -1){
                      arrar.push(item.id)
                    }else{
                      arrar.splice(arrar.indexOf(item.id), 1)
                    }
                    // const arr = arrar
                    setArray([...arrar])
                    console.log(arrar)
                  }}>
                  {item.name}
                </div>
              )
            })}
          </div>
          <div className={"done " + (arrar.length > 0 ? 'active' : '')} onClick={done}>确定</div>
          <div className="desc">*将根据您的选择智能推荐高品质好游</div>
        </Popup>
      </Space>
    </div>
  )
}
