import React, { useEffect, useState } from 'react'
import { NavLink } from '../../../lib/Navigation'
import URI from 'urijs'
import Manager from '../Manager'

export default function HomeTopItem(props) {

  const [newMenus] = useState(props.newMenus)

  const item = (menu,single,index)=>{
    return <div className={'index-home-top-item ' + (single ? 'right' : '')}>
      <div className={single ? "contents" : "content"}>
        <div className="title">{menu.title}</div>
        <div className="desc">{menu.desc}</div>
      </div>
      <img className={'icon ' + (single ? 'right-icon' : '')} src={menu.icon} alt="" />
    </div>
  }

  const clickIconOfHomePage = (item)=>{
    Manager.push({ event_id:item.event_id })
  }

  const div = (menus,single)=>{
    return <div className={single ? 'index-home-top-item' : 'index-home-top-content'}>
      {menus.map((menu,index) => <div key={index} className='menus-div' style={{
        marginRight:(props.newMenus.length == 2 && index == 0 && !single ? '10px' : '0'),
        marginBottom:(props.newMenus.length == 3 && index == 0 && !single ? '10px' : '0')
      }}>
        {(()=>{
          if(menu.type == 'webview') {
            return <NavLink  to={(()=>{
              let url = URI(menu.value)
              .addQuery('username', localStorage.getItem('username'))
              .addQuery('token', localStorage.getItem('token'))
              .addQuery('location_url', window.location.origin)
              .addQuery('origin', 'react')
              .addQuery('uid', localStorage.getItem('user_id'))
              .toString();
              return url;
            })()} iframe={{title:menu.browserTitle}} user={menu.login} onClick={()=>clickIconOfHomePage(menu)}>
              {item(menu,single,index)}
            </NavLink>
          } else if(menu.type == 'syGift'){
            return <NavLink  to={(()=>{
              let url = URI(menu.value)
              .addQuery('username', localStorage.getItem('username'))
              .addQuery('token', localStorage.getItem('token'))
              .addQuery('location_url', window.location.origin)
              .addQuery('origin', 'react')
              .addQuery('uid', localStorage.getItem('user_id'))
              .toString();
              return url;
            })()} iframe={{title:menu.title}} user={menu.login} onClick={()=>clickIconOfHomePage(menu)}>
              {item(menu,single,index)}
            </NavLink>
          } else if(menu.type == 'active_novice'){
            return <NavLink  to={(()=>{
              let url;
              if(menu.version == 1) {
                url = URI(menu.value)
                .addQuery('username', localStorage.getItem('username'))
                .addQuery('token', localStorage.getItem('token'))
                .addQuery('location_url', window.location.origin)
                .addQuery('origin', 'react')
                .addQuery('uid', localStorage.getItem('user_id'))
                .toString();
              } else {
                url = '/party/couple';
              }
              return url;
            })()} iframe={(()=>{
              if(menu.version == '1') {
                return {title: menu.browserTitle};
              } else if(menu.version == '2') {
                return false;
              }
            })()} user={menu.login} onClick={()=>clickIconOfHomePage(menu)}>
              {item(menu,single,index)}
            </NavLink>
          } else if(menu.type == 'active_flyback'){
            return <NavLink  to={(()=>{
              let url;
              if(menu.version == '1') {
                url = URI(menu.value)
                .addQuery('username', localStorage.getItem('username'))
                .addQuery('token', localStorage.getItem('token'))
                .addQuery('location_url', window.location.origin)
                .addQuery('origin', 'react')
                .addQuery('uid', localStorage.getItem('user_id'))
                .toString();
                return url;
              } else if(menu.version == '2'){
                url = '/party/regression';
                return url;
              }
            })()} iframe={(()=>{
              if(menu.version == '1') {
                return {title: menu.browserTitle};
              } else if(menu.version == '2') {
                return false;
              }
            })()} user={menu.login} onClick={()=>clickIconOfHomePage(menu)}>
              {item(menu,single,index)}
            </NavLink>
          } else {
            return <NavLink to={(()=>{
              if(menu.type == 'mcard') {
                return '/save/newcard';
              } else if(menu.type == 'rechargeVip'){
                return '/save/newvip';
              } else if(menu.type == 'freeReceiveVoucher') {
                return '/voucher';
              } else if(menu.type == 'special') {
                if(menu.special_type == '6') {
                  return "/party/beta?id=" + menu.value;
                } else {
                  return '/game/project?id=' + menu.value;
                }
              } else if(menu.type == 'trade') {
                return '/trade';
              } else if(menu.type == 'openServer') {
                return '/server';
              } else if(menu.type == 'goldMall') {
                return '/shop';
              } else if(menu.type == 'share') {
                return config?.inviteFriendsType == 2 ? "/newinvite" : "/invite";
              } else if(menu.type == 'noviceTask') {
                return '/task/novice';
              } else if(menu.type == 'video') {
                return '/index/video';
              } else if(menu.type == 'tradeGoods') {
                return '/trade/good?source=czjl';
              } else if(menu.type == 'webview') {
                let url = URI(menu.value)
                  .addQuery('username', localStorage.getItem('username'))
                  .addQuery('token', localStorage.getItem('token'))
                  .addQuery('location_url', window.location.origin)
                  .addQuery('origin', 'react')
                  .toString();
                return url;
              } else if(menu.type == 'authoriry_record') {
                return '/user/authority';
              } else if (menu.type == 'tryPlay'){
                return '/task/tryplay'
              } else if(menu.type == 'vouchandgift') {
                return '/giftproject'
              } else if(menu.type == 'signgift') {
                return '/signgift'
              }
            })()} user={menu.login} onClick={()=>clickIconOfHomePage(menu)}>
            {item(menu,single,index)}
          </NavLink>
          } 
        })()}
      </div>)}
    </div>
  }

  const two = ()=>{
    return <div>
      {div([props.newMenus[0],props.newMenus[1]],false)}
    </div>
  }

  const three = ()=>{
    return <div className='index-home-top-content'>
      <div className='index-home-top-item right-space'>
        <div className='left'>
        {div([props.newMenus[0],props.newMenus[1]],false)}
        </div>
      </div>
      {div([props.newMenus[2]],true)}
    </div>
  }

  return (
    <div>
      {props.newMenus.length == 2 && two()}
      {props.newMenus.length == 3 && three()}
    </div>
  )
}
