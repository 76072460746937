import {useCallback, useState} from "react";
import moment from "moment";
import general from "../../../general";
import {NavLink} from "../../../lib/Navigation";
import Explain from "../../voucher/Explain";
import {CSSTransition} from "react-transition-group";
import Dialog from "../../../lib/Dialog";
import {useUpdate} from "ahooks";

export default ({game, show, setShow}) => {
  const [explain, setExplain] = useState();
  const update = useUpdate();
  const receive = voucher => {
    general.axios.post('/receiveVoucher', {voucher_id: voucher.id})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          Dialog.success('领取成功');
          voucher.is_received = true;
          update();
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const ref = useCallback(node => {
    if (node) {
      node.addEventListener('touchstart', e => {
        e.stopPropagation();
      }, {passive: false});
      node.addEventListener('touchmove', e => {
        e.stopPropagation();
      }, {passive: false});
      node.addEventListener('touchend', e => {
        e.stopPropagation();
      }, {passive: false});
    }
  }, []);

  return <CSSTransition in={show} timeout={250} appear={true} unmountOnExit={true}>
    <div className="game-voucher">
      <div className="mask" onClick={() => setShow(false)}/>
      <div className="container">
        <div className="head">
          <div className="text">代金券领取</div>
          <div className="close" onClick={() => setShow(false)}>X</div>
        </div>
        <NavLink to="/save?type=card" className="tips">
          <div className="icon"><img src="/resources/game/voucher-icon2.png" alt=""/></div>
          <div className="name">6元通用券</div>
          <div className="info">30天每天获得一张满6减6券</div>
          <div className="operate">></div>
        </NavLink>
        <div className="body" ref={ref}>
          {game.voucherslist?.map(voucher => <div className="item" style={{backgroundImage: voucher.vip_level ? voucher.vip_level > 0 ? ('url(/resources/game/vip-vouch-bag.png)') : ('url(/resources/game/vouch-bag.png)') :('url(/resources/game/vouch-bag.png)')}}>
            <div className={"amounts " + (voucher.vip_level ? voucher.vip_level > 0 ? "vip-amounts" : "" : "")}>
              {
                voucher.vip_level ? 
                  voucher.vip_level > 0 ?
                  <div className="vip-label">
                    <img src="/resources/game/vip-label.png" />
                    <div className="text">
                      {voucher.vip_level_desc}
                    </div>
                  </div>
                  :null
                :null
              }
              
              <div className="amount">￥{voucher.amount * 1}</div>
              <div className="amount-meet">
              {(() => {
                let meet_amount = voucher.meet_amount * 1;
                if(meet_amount === 0) {
                  return '任意金额可用';
                } else {
                  return '满' + meet_amount + '元可用';
                }
              })()}
                {/* 满{voucher.meet_amount * 1}元可用 */}
              </div>
            </div>
            <div className="info">
              <div className="name">{voucher.name}</div>
              <div className="time">{moment(voucher.start_time * 1000).format('YYYY-MM-DD')} ~ {moment(voucher.end_time * 1000).format('YYYY-MM-DD')}</div>
              <div className="game">仅限《{voucher.game_name}》</div>
              <div className={"use " + (voucher.vip_level ? voucher.vip_level > 0 ? "vip-use" : "" : "")} onClick={() => setExplain(voucher)}>使用方法></div>
            </div>
            {voucher.is_received != '0'
              ? <div className="operate disable">已领取</div>
              : <NavLink className={"operate " + (voucher.vip_level ? voucher.vip_level > 0 ? "vip-operate" : "" : "")} onClick={() => receive(voucher)}>立即领取</NavLink>}
          </div>)}
        </div>
      </div>
      {explain ? <Explain voucher={explain} setVoucher={setExplain}/> : null}
    </div>
  </CSSTransition>;
};