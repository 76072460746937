import React, {useEffect, useState} from "react";
import general from "../../general";
import {Link} from "react-router-dom";
import $ from "jquery";
import {useScroll, useLockFn, useMount} from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import useActiveState from '../../lib/useActiveState';

// export default ({active, type}) => {
export default props => {
  const {aplus_queue} = window;
  const [page, setPage] = useState(1);
  const [games, setGames] = useState([]);
  const [more, setMore] = useState(true);
  const [obsolete, setObsolete] = useState();
  const [user] = useActiveState('user');
  const [agent] = useActiveState('agent');
  const [randomColorInfo] = useActiveState('randomColorInfo');
  const [init, setInit] = useState(false);
  const [data, setData] = useState([]);

  const load = useLockFn(async (parms = {}) => {
    Dialog.loading();

    let tmp = {
      pagination: {count: 20, page: page},
    };

    if (parms.page) {
      tmp.pagination.page = parms.page;
    }

    await general.axios.post('/transferV2/records', tmp)
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setInit(true);
        setObsolete(false);
        parms.page && setPage(parms.page);
        let {items: list, paginated} = data;
        setData(data => parms.page === 1 || !parms.page ? list : [...data, ...list]);
        setMore(paginated.more && list.length > 0);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 > $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, props.active]);

  return <div className="around-record">
    {
      data?.map((item, index) => <div className="record-block">
        <div className="input-item">
          <div className="name">游戏名称</div>
          <div className="value">
            {/* {item.game_name} */}
              <div className="game_names">
                <div className={" " + (item.nameRemark ? " name-new" : "")}>
                  {item.game_name}
                </div>
                {item.nameRemark ? <div className="nameRemark-new">{item.nameRemark}</div> : null}
              </div>
          </div>
        </div>
        <div className="input-item">
          <div className="name">{item.type == 2 ? '礼包名称' : '代金券'}</div>
          <div className="value">
            {item.remark}
          </div>
        </div>
        <div className="input-item">
          <div className="name">消耗转游点</div>
          <div className="value">
            {item.score}转游点
          </div>
        </div>
        <div className="input-item">
          <div className="name">兑换时间</div>
          <div className="value">
            {item.create_time}
          </div>
        </div>
      </div>)
    }

    {data.length === 0 && init ? <div className="around-empty"><img src="/resources/game/report_empty.png" alt=""/>暂无转游记录~</div> : null}
    {data.length > 0 && !more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
  </div>;
};