import {useEffect, useState} from "react";
import general from "../../../general";
import MobileDetect from "mobile-detect";
import Dialog from "../../../lib/Dialog";
import ImagePicker from "../../util/ImagePicker";
import {Link} from "react-router-dom";
import {useMount, useLockFn, useReactive, useUpdate} from "ahooks";
import useActiveState from "../../../lib/useActiveState";
import useUrlState from "@ahooksjs/use-url-state";

const mobileDetect = new MobileDetect(navigator.userAgent);

export default props => {
  const update = useUpdate();
  useMount(()=>{
    props.setOperate(<Link to="/detail/report" className="invite-index-record">我的举报</Link>);
  })

  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});

  const [data, setData] = useState({
    game_uploadedshots: [],
    images:"",
    maiyou_gameid: props.query.id,
    mobile_model: mobileDetect.os() + ' ' + mobileDetect.version(mobileDetect.os()),
    mobile_system_code: mobileDetect.os() + ' ' + mobileDetect.version(mobileDetect.os()),
    type:'submit'
  });

  // const data = useReactive({
  //   images: [],
  //   maiyou_gameid: props.query.id,
  // });

  const [imgs, setImgs] = useState([]);
  const [submiting, setSubmiting] = useState(false);
  const [user, setUser] = useActiveState('user');
  // console.log(imgs);
  const submit = useLockFn(async () => {
    if(!data.platform) {
      Dialog.info("请输入折扣平台");
      return false;
    }
    if(!data.contact) {
      Dialog.info("请输入联系方式");
      return false;
    }

    setSubmiting(true);
    Dialog.loading();

    // let game_uploadedshots = [];
    if(imgs.length > 0) {
      // Dialog.info('开始图片上传');
      for (let i = 0; i < imgs.length; i++) {
        let formData = new FormData();
        formData.append('type', 'discountComplaint');
        formData.append('img', imgs[i].file);
        try {
          await general.axios.post('/upload_image', formData)
            .then(res => {
              let {status, data: result} = res.data;
              if (!status.succeed) {
                Dialog.error(status.error_desc);
              } else {
                data.game_uploadedshots.push(result.path);
                // game_uploadedshots.push(result.path);
                data.images.concat("," + result.path);
                setData({...data});
                // Dialog.info('上传图片' + (data.images.length) + '/' + imgs.length);
              }
            });
        } catch (e) {
          Dialog.error(e.message);
          return;
        }
      }
    }

    let images = [];
    for (let name in data) {
      if (name === 'game_uploadedshots') {
        for (let i = 0; i < data[name].length; i++) {
          images.push(data[name][i]);
        }
      }
    }
    // console.log(images.join(","));
    // console.log(data);
    // return false;
    data.images = images.join(",");

    await general.axios.post('/discountComplaint?noaccess=1', data)
        .finally(() => {
          setSubmiting(false);
          Dialog.close();
        })
        .then(response => {
          let result = response.data;
          if (!result.status.succeed) {
            Dialog.error(result.status.error_desc);
          } else {
            // data.game_uploadedshots = [];
            // data.platform = "";
            // data.down_link = "";
            // data.contact = "";
            // data.images = "";
            setData({...data,...{game_uploadedshots:[], platform:'', down_link:'', discount:'', contact:'', images:''}});
            setImgs([]);
            // update();
            Dialog.success('提交成功', general.history.goBack());
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  })

  // console.log(user);
  return <div className="game-complaintdetail">
    <div className="complaintdetail-body">
      <div className="complaintdetail-title">
        <div className="title-entipy">
          <div className="icon">
            <img src="/resources/game/compaont-icon.png" />
          </div>

          <div className="tips">
            近期游戏内出现了大量骗子，以充值折扣为诱饵来骗取用户钱财；
            平台在此严重声明，切勿相信充值折扣，保护自己的财产安全！
            若因此出现被骗情况，与本平台无关，请各位须知！
          </div>
        </div>
        
      </div>
      <div className="complaintdetail-block">
        <div className="input-item">
          <div className="name">折扣游戏</div>
          <div className="value">
            <input type="text" value={props.query.name} />
          </div>
        </div>
        <div className="input-item">
          <div className="name">账号</div>
          <div className="value">
            <input type="text" value={user.member_name}/>
          </div>
        </div>
        <div className="input-item">
          <div className="name">折扣平台<span className="mandatory">*</span></div>
          <div className="value">
            <input type="text" value={data.platform} placeholder="请填写折扣平台名称" onChange={e => setData({...data, platform: e.target.value})}/>
          </div>
        </div>
        <div className="input-item">
          <div className="name">下载链接</div>
          <div className="value">
            <input type="text" value={data.down_link} placeholder="请输入折扣平台下载链接" onChange={e => setData({...data, down_link: e.target.value})}/>
          </div>
        </div>
        <div className="input-item">
          <div className="name">充值折扣</div>
          <div className="value">
            <input type="text" value={data.discount} placeholder="请填写充值折扣" onChange={e => setData({...data, discount: e.target.value})}/>
          </div>
        </div>
        <div className="input-item">
          <div className="name">联系人方式<span className="mandatory">*</span></div>
          <div className="value">
            <input type="number" value={data.contact} placeholder="请提供您的手机/QQ/微信" onChange={e => setData({...data, contact: e.target.value})}/>
          </div>
        </div>
      </div>
      <div className="complaintdetail-block imgs-block">
        <div className="name">上传证据截图<span className="tips">（比如：微信、QQ、游戏内聊天截图）</span></div>
        <ImagePicker multiple={true} max={3} files={imgs} setFiles={setImgs}/>
      </div>
    </div>
    <div className="complaintdetail-operate">
      <div className={'btn ' + (submiting ? 'disable' : '')} onClick={submit}>确认提交</div>
      <div className="tips">
      我们收到反馈后将第一时间核实或与您联系，请保持联系方式的畅通；处理完成后我们将积极为您申请补偿奖励。
      </div>
    </div>
  </div>;
};