import React, { useState } from 'react'
import './css/CommunityItem.css'
import Manager from '../../../../components/Manager'

export default function ComnunitySort(props) {

  const [list] = useState(props.sortList)

  return (
    <div className='community_sort' onClick={()=>props.sortAction({})}>
      <div className="sort-container" style={{top:props.top + 30}}>
        <div className="sort-list">
          {list.map((item,index) => <div className='sort-item' onClick={(e)=>{
            Manager.push({event_id:`D-4-7-${index+1}`})
            e.stopPropagation()
            props.sortAction(item)
          }}>{item.title}</div>)}
        </div>
      </div>
    </div>
  )
}
