import ActiveContext from "./ActiveContext";
import {useEffect, useState} from "react";
import {useScroll, useUpdateEffect} from "ahooks";
import $ from "jquery";

export default ({active, children}) => {
  const [mount, setMount] = useState(false);
  useEffect(() => {
    active && !mount && setMount(true);
  }, [active, mount]);
  const windowScroll = useScroll();
  const [scroll, setScroll] = useState({top: 0, left: 0});
  useEffect(() => {
    if (active) {
      $('html,body').scrollTop(scroll.top);
      $('html,body').scrollLeft(scroll.left);
    } else {
      setScroll(windowScroll);
    }
  }, [active]);

  return <ActiveContext.Provider value={active}>
    {mount || active ? children : null}
  </ActiveContext.Provider>
};