import general from "../../general";
import {useEffect, useState} from "react";
import Dialog from "../../lib/Dialog";

const tasks = [
  {
    name: '累计签到',
    reward: '＋100金币',
    id: 'CumulativeSignIn',
    info: '累计签到58次即可获得100金币奖励',
    operate: '去完成',
  },
  {
    name: '优质评价',
    reward: '＋30金币',
    id: 'goodCommentNew',
    info: '优质评价让你金币拿到手软',
    operate: '去完成',
  },
  {
    name: '充值满100元',
    reward: '＋50金币',
    id: 'Recharge100',
    info: '累计充值100元，即可获得奖励',
    operate: '去完成',
  },
  {
    name: '充值满1000元',
    reward: '＋666金币',
    id: 'Recharge1000',
    info: '累计充值1000元，即可获得奖励',
    operate: '去完成',
  },
];

export default props => {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState({});
  const load = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    general.axios.post('/user/task/getTaskDataByName', {type: 'achievement'})
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then(response => {
        setLoaded(true);
        let result = response.data;
        if (!result.status.succeed) {
          Dialog.error(result.status.error_desc);
        } else {
          console.log(result.data.task_info);
          setData(result.data.task_info);
        }
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  }
  useEffect(() => {
    if (props.active && (!loaded || props.action === 'POP')) {
      load();
    }
  }, [props]);

  const [receiving, setReceiving] = useState(false);
  const handle = task => {
    let state = data[task.id];
    if (!state.taked) {
      if (state.completed) {
        if (receiving) {
          return;
        }
        setReceiving(true);
        Dialog.loading();
        general.axios.post('/user/task/takeBalance', {name: task.id})
          .finally(() => {
            setReceiving(false);
            Dialog.close();
          })
          .then(res => {
            let result = res.data;
            if (!result.status.succeed) {
              Dialog.error(result.status.error_desc);
            } else {
              Dialog.success('领取成功');
              data[task.id].completed = true;
              data[task.id].taked = true;
              setData({...data});
            }
          })
          .catch(err => {
            Dialog.error(err.message);
          });
      } else {
        Dialog.alert('温馨提示', task.info);
      }
    }
  }

  return <div className="task-success">
    <div className="bg-center" style={{backgroundImage: 'url(/resources/task/success-center.png)'}}>
      <div className="bg-top" style={{backgroundImage: 'url(/resources/task/success-top.png)'}}>
        <div className="bg-bottom" style={{backgroundImage: 'url(/resources/task/success-bottom.png)'}}>
          <div className="success-head">
            <img src="/resources/task/success-title-milu.png" alt=""/>
          </div>
          <div className="success-body">
            <div className="title">
              <img src="/resources/task/success-left.png" alt=""/>
              任务内容
              <img src="/resources/task/success-right.png" alt=""/>
            </div>
            <div className="tasks">
              {tasks.map(task => <div className="task" onClick={() => handle(task)}>
                <div className="icon"><img src={'/resources/task/success-' + (task.id) + '.png'} alt=""/></div>
                <div className="info">
                  <div className="name">
                    {task.name}
                    <div className="progress">({data[task.id]?.success_num}/{data[task.id]?.total})</div>
                    <div className="reward">{task.reward}</div>
                  </div>
                  <div className="desc">{task.info}</div>
                </div>
                <div className={'operate' + (data[task.id]?.completed ? (data[task.id]?.taked ? ' completed' : ' complete') : '')}>
                  {data[task.id]?.completed ? (data[task.id]?.taked ? '已完成' : '领取') : (task.operate + '>')}
                </div>
              </div>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
};