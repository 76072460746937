import {useEffect, useState} from "react";
import {StarFilled} from "@ant-design/icons";
import moment from "moment";
import general from "../../../general";
import { NavLink } from "../../../lib/Navigation";

export default props => {
  const [game, setGame] = useState({});
  useEffect(() => {
    // let game = props.game;
    let game = props.game || general.game;
    if (game.kaifu_info) {
      game.kaifu_info = game.kaifu_info.map(item => {
        item.remind = localStorage.getItem('game-' + game.game_id + '-server-remind-' + item.starttime);
        return item;
      });
    }
    // console.log(game.kaifu_info);
    // console.log(navigator.connection);
    setGame(game);
  }, [props.game, general.game]);

  const setRemind = index => {
    if (!general.isLogin()){
      PubSub.publish('alert-login',true)
      return
    }
    let kaifu_info = game.kaifu_info;
    kaifu_info[index].remind = !kaifu_info[index].remind;
    let key = 'game-' + game.game_id + '-server-remind-' + kaifu_info[index].starttime;
    if (kaifu_info[index].remind) {
      localStorage.setItem(key, true);
    } else {
      localStorage.removeItem(key);
    }
    setGame({...game, kaifu_info});
  }

  const formatClassName = (info,index) => {
    if (index == 0){
      if (info[0].kaifuname === '动态开服') {
        return ''
      }else{
        return 'item nth'
      }
    }else{
      if (info[0].kaifuname === '动态开服') {
        return (index % 2 === 1 ? 'item nth' : 'item')
      }else{
        return (index % 2 === 0 ? 'item nth' : 'item')
      }
    }
  }

  return <div className="game-server-index">
    <div className="tips">注：仅供参考，以游戏内开服为准！</div>
    {/* <div className="dynamic">
      <div className="img">
        <img src="/resources/game/service-dynamic-tips.png" />
        <div className="name">
          动态开服
        </div>
      </div>
      <div className="desc">
        上一区服玩家人数达到要求后自动开启下一区服，具体以游戏内区服为准
      </div>
    </div> */}
    {game.kaifu_info?.map((server, index) => <div className={formatClassName(game.kaifu_info,index)}>

      {
        server.kaifuname == '动态开服' ? <>
          <div className="dynamic">
            <div className="img">
              <img src="/resources/game/service-dynamic-tips.png" />
              <div className="dtname">
                动态开服
              </div>
            </div>
            <div className="desc">
              上一区服玩家人数达到要求后自动开启下一区服，具体以游戏内区服为准
            </div>
          </div>
        </> 
        :
        <>
          {/* <div className="icon"><StarFilled/></div> */}
          <div className="left">
            {server.starttime != '0' ? 
            <div className="time">{moment(server.starttime * 1000).format('MM-DD HH:mm')}</div>
            : null}
            <div className="name">{server.kaifuname}</div>
          </div>
          <div className="right">
            {server.starttime != '0' ? 
            <>
            {server.starttime * 1000 < moment().valueOf() ? <div className="operate disable">已开服</div> : <div>
              <div className="operate" onClick={() => setRemind(index)}>
                {server.remind ? '取消提醒' : '提醒'}
              </div>
            </div>}</>
            :null}
          </div>
        </>
      }
    </div>)}
    {game.kaifu_info?.length == 0 ? 
    <div className="dynamic">
      <div className="img">
        <img src="/resources/game/service-dynamic-tips.png" />
        <div className="dtname">
          动态开服
        </div>
      </div>
      <div className="desc">
        上一区服玩家人数达到要求后自动开启下一区服，具体以游戏内区服为准
      </div>
    </div> : null}
  </div>;
};