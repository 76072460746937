import {useEffect, useState} from "react";
import general from "../../general";

export default ({setTitle}) => {
  const [game] = useState(general.game);
  const [vip, setVip] = useState('');
  useEffect(() => {
    setTitle(game.game_name + 'VIP表');
    if (game.game_feature_list) {
      let result = /<table[^>]*?>([\s\S]*)<\/table>/g.exec(game.game_feature_list[2].content);
      console.log(result);
      if (result && result[0]) {
        setVip(result[0]);
      }
    }
  }, [game]);

  return <div className="game-vip" dangerouslySetInnerHTML={{__html: vip}}/>;
};