import React, { useEffect, useState } from 'react'
import CommunityItem from './CommunityItem'
import Dialog from '../../../../../lib/Dialog'
import general from '../../../../../general'
import {Link} from "react-router-dom";
import { useMount, useScroll } from 'ahooks';
import $ from "jquery";

export default function CommunityList(props) {

  const [loading,setLoading] = useState(false)
  const [communityList,setCommunityList] = useState([])
  const [page,setPage] = useState(1)
  const [more,setMore] = useState()
  const [init,setInit] = useState(false)
  const [sort,setSort] = useState(props.sort)
  const [isfootMark] = useState(props.isfootMark)

  const getCommunityList = (params={})=>{
    if (loading){
      return
    }
    setLoading(true)
    Dialog.loading()
    let tmp = {
      sort:props.sort,
      tagid:props.tagid,
      topic_id:isfootMark ? '' : props.topic_id,
      uid:isfootMark ? props.user.member_id : '',
      // tagid:'2',
      // topic_id:'11416',
      search_info:props.search ?? '',
      pagination:{page:page,count:'10'}
    }
    if (params.page){
      tmp.pagination = {page:params.page,count:'10'}
    }
    // console.log('tmp',tmp);
    general.axios.post(isfootMark ? '/base/community/getFootMarkList' : '/base/community/getCommunityList',tmp)
    .finally(()=>{
      setLoading(false)
      Dialog.close()
    })
    .then(res => {
      setInit(true)
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
      } else {
        params.page && setPage(params.page);
        const community_list = isfootMark ? data.list : data.community_list
        setCommunityList(communityList => params.page === 1 || !params.page ? community_list : [...communityList,...community_list])
        setMore(data.paginated.more);
      }
    })
  }

  useEffect(()=>{
    if (props.isSearch && props.active){
      getCommunityList({page:1})
    }
  },[props.isSearch])

  useEffect(()=>{
    if (props.active && !init){
      getCommunityList({page:1})
    }
  },[props.active])

  const scroll = useScroll()
  useEffect(()=>{
    if (props.active){
      if (scroll.top < -80){
        getCommunityList({page:1})
      }
      // console.log(scroll.top,window.innerHeight,$(document).height());
      if (scroll.top + window.innerHeight + 20 > $(document).height() && more){
        getCommunityList({page:page+1})
      }
    }
  },[scroll,props.active])

  useEffect(()=>{
    if (props.active){
      getCommunityList({page:1})
    }
  },[props.sort])

  return (
    <>
    {communityList?.length === 0 && init ? <div className="empty"><img src="/resources/empty.png" alt=""/>暂无数据</div> : null}
    {communityList?.map(item=>{
      return <Link key={item.id} to={'/communityDetail?id=' + (parseInt(item.recover) === 2 ? item.community_id : item.id) + '&tagid=' + item.tagid}>
        <CommunityItem 
          item={item} 
          tagid={props.tagid}
          isfootMark={isfootMark}></CommunityItem>
      </Link>
    })}
    {communityList?.length > 0 && !more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
    </>
  )
}
