export default props => {
  return <div className="task-tryplay-explain">
    <dl>
      <dt>怎么通过试玩游戏赚金币？</dt>
      <dd>1、首先领取任务，数量有限，先抢先得</dd>
      <dd>2、然后下载游戏，按照要求在任务有效期内创建角色或者达到指定等级</dd>
      <dd>3、返回试玩任务页面领取金币</dd>
    </dl>
    <dl>
      <dt>任务完成了，为什么没有获得金币？</dt>
      <dd>1、任务需要领取后才可完成，未领取状态下试玩游戏，无法获得金币</dd>
      <dd>2、完成任务不能领金币也可能是系统延时，刷新页面或退出重新登录游戏即可领取</dd>
      <dd>3、在指定时间内完成任务即可领取，任务结束仍未完成，不能领取金币</dd>
    </dl>
    <dl>
      <dt>领任务有限制么？每天可领取多少个？</dt>
      <dd>1、每人每天可领取的任务无上限，重要的是手要快</dd>
      <dd>2、同个游戏，同个设备每个账号只能领取一次</dd>
    </dl>
    <dl>
      <dt>重要提示</dt>
      <dd>1、一旦发现使用非正常手段刷金币，将永久封号并收回所得</dd>
      <dd>2、如有问题请进入客服页面联系官方客服</dd>
    </dl>
  </div>;
};