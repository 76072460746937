import React,{ useState, useEffect } from 'react'
import Dialog from '../../../lib/Dialog'
import general from '../../../general'
import useActiveState from '../../../lib/useActiveState'
import Image from '../../../lib/Image'
import {Link} from "react-router-dom";
import './css/ExtendGame.css'
import Subtitle from '../../components/Subtitle'

export default function ExtendGame(props) {

  const [list,setList] = useState([])
  const [title,setTitle] = useState()
  const [randomColorInfo] = useActiveState('randomColorInfo');
  const [infoTextColor, setInfoTextColor] = useState();
  const [more, setMore] = useState(false);

  useEffect(() => {
    if (props.active && !title){
      setInfoTextColor(randomColorInfo[parseInt(Math.random() * 4)]);
      extendGameList()
    }
  }, [props.active])
  
  useEffect(()=>{
    if (props.active && title){
      props.setTitle(title)
    }
  },[props.active,title])

  const extendGameList = ()=>{
    Dialog.loading()
    const data = {
      genre_id:props.query.id,
      genre_type:props.query.type,
      pagination:{
        page:1,
        count:1000
      }
    }
    general.axios.post('getExtendGameList',data)
    .finally(()=>{
      Dialog.close()
    })
    .then(res=>{
      let {status,data} = res.data
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }
      setList(data.game_list)
      setTitle(data.title)
      setMore(data.paginated.more);
    })
  }

  return (
    <div className="extend-game-index">
      <div className="extend-game-content">
        {list.map((game,index)=>{
          return <Link to={'/game?id='+game.game_id} className="entity" key={index}>
                  <div className="icon">
                    <Image src={game.game_image.thumb}/>
                    {
                      game?.lablePosition == 2 && game.top_lable != '' ?
                      <div className="iconlable jx" style={{backgroundImage: 'linear-gradient('+game?.lableColor.t+', '+game?.lableColor.b+')'}}>
                        {game.top_lable}
                      </div>
                      : null
                    }
                  </div>
                  <div className="text">
                    <div className="name">
                      <div className={" " + (game.nameRemark ? " name-new" : "")}>
                        {game?.lablePosition == 1 && game.top_lable != '' ? <div className="span">{game.top_lable}</div> : null }
                        {game.game_name}
                      </div>
                    </div>
                    <div className="info1">
                      {game.game_classify_type.trim()}
                      <Subtitle>{game.nameRemark}</Subtitle>
                    </div>
                    <div className="info3">
                    {
                      game.bottom_lable ? 
                      <>
                      {Object.keys(game.bottom_lable).length > 0 ? 
                      <div style={{display:'flex'}}>
                        <span className="first-text" style={{background:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.left_content}</span><span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
                      </div> : null }
                      </>
                      : null
                    }
                    {game.exclusive_game_describe?.length > 0 ? <div className="speed">{game.exclusive_game_describe}</div> : null}
                    {game.is_support_accele ? <div className="speed">可加速</div> : null}
                    {game.is_easy_play ? <div className="speed">省心玩</div> : null}
                    {Object.keys(game.bottom_lable).length == 0 && !game.is_support_accele && !game.is_easy_play && game.introduction && game.exclusive_game_describe?.length == 0 ? <span style={{color:'#888'}}>{game.introduction}</span> : null}                    </div>
                    {game.discount < 1 ? <div className="discount" ><span>{game.discount * 10}</span>折</div> : null}
                  </div>
                  {index < 99 ? <div className="idx">
                    <img className="index-icon" src={"resources/index/new-" + (index < 3 ? index + 1 : 'more') + ".png"} alt="" />
                    {index > 2 ? <div style={{left:(index < 9 ? '8px' : '3px')}} className="index-text">{index+1}</div> : null}
                  </div> : null}
                </Link>
        })}
        {list.length > 6 && !more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
      </div>
    </div>
  )
}
