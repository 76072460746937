import React, { useState, useEffect, useRef } from 'react'
import { Popup, Swiper, Image } from 'antd-mobile'
import useActiveState from '../../../lib/useActiveState'
import general from '../../../general'
import { useMount, useToggle, useLocalStorageState, useLockFn, useInterval } from "ahooks";
import { CloseOutlined, DownOutlined, EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import Dialog from '../../../lib/Dialog';
import {url} from "../../../env";
import URI from "urijs";
import MobileDetect from "mobile-detect";
import useUrlState from "@ahooksjs/use-url-state";
import { NavLink } from '../../../lib/Navigation';
import PubSub from 'pubsub-js'
import './css/ AlertLogin.css'

const mobileDetect = new MobileDetect(navigator.userAgent);
const phoneNumberServer = new PhoneNumberServer();
phoneNumberServer.setLoggerEnable(true);

export default function Login(props) {

  const [app] = useActiveState('app');
  const [datas, setDatas] = useLocalStorageState('users-0804', []);
  const [focus, setFocus] = useState('');
  const [password, { toggle: togglePassword }] = useToggle(true);
  const [data, setData] = useState(datas.length > 0 ? { ...datas[0] } : {});
  const [config, setConfig] = useActiveState('config');
  const [agreement, setAgreement] = useState(true);
  const [loging, setLoging] = useState(false);
  const [user, setUser] = useActiveState('user');

  const [codIng, setCodIng] = useState(false);
  const [codeTime, setCodeTime] = useState(0);
  const [users, setUsers] = useState([]);//多个账号
  const [agent] = useActiveState('agent');
  const [areaNumData, setAreaNumData] = useState([]);
  const [params, setParams] = useUrlState({}, { navigateMode: 'replace' });
  const [whether, setWhether] = useActiveState('whether');//是否进行过退出操作
  const [toggle, setToggle] = useState(localStorage.getItem('toggle') || '0')
  const [showLogin, setShowLogin] = useState(false)
  const [authCoding, setAuthCoding] = useState(false);
  const [authCodeTime, setAuthCodeTime] = useState(0);
  const [visible, setVisible] = useState(false)
  const [autoLogin, setAutoLogin] = useState(false)
  const [visiable, setVisiable] = useState(false)
  const [visibility, setVisibility] = useState('visible')
  const [email, setEmail] = useState(true)
  const userAgreement = `${url}/userAgreement`;
  const privacyPolicy = `${url}/privacyPolicy`;
  const coderef = useRef()
  const phoneRef = useRef()
  const accountRef = useRef()

  useEffect(() => {

    const handleshow = PubSub.subscribe('alert-login', (name,data) => {
      if (data.forgot == true){
        
      }else {
        setVisiable(true)
        setVisibility('visible')
        getAccessToken()
        setData(datas.length > 0 ? { ...datas[0] } : {phone:localStorage.getItem('phone')});
      }
    })

    const handlelogin = PubSub.subscribe('handlelogin', () => {
      setAgreement(true)
      login()
    })

    const handleBack = PubSub.subscribe('alert-back', () => {

      if (parseInt(props.registerAgreement) == 1) {
        setVisibility('visible')
      }
    })

    return () => {
      PubSub.unsubscribe(handleshow)
      PubSub.unsubscribe(handlelogin)
      PubSub.unsubscribe(handleBack)
    }

  }, [data, props.registerAgreement])

  useEffect(() => {
    setVisibility(props.visibility);
  }, [props.visibility]);

  useEffect(() => {
    setAreaNumData(general.areaNumber);
  }, []);

  useInterval(() => {
    if (codeTime) {
      setCodeTime(codeTime - 1);
    }
  }, 1000);

  const codeGet = () => {
    if(app.is_overseas && email){
      if (!data.email) {
        Dialog.error('请输入邮箱');
        return;
      }
      if (codIng || codeTime) {
        return;
      }
 
      setCodIng(true);
      Dialog.loading();
      general.axios.post('/sendEmailCode', {
        email: data.email,
        type: 'login',
      })
        .finally(() => {
          setCodIng(false);
          Dialog.close();
        })
        .then(res => {
          let { status } = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            // coderef.current.focus()
            setCodeTime(60);
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
    }else {
      if (!data.phone) {
        Dialog.error('请输入手机号');
        return;
      }
      if (codIng || codeTime) {
        return;
      }

      setCodIng(true);
    Dialog.loading();
    general.axios.post('/base/tool/getVerifyCode', {
      mobile: (areaNumData ? areaNumData : '86') + '-' + data.phone,
      code: 'code',
      type: '08',
    })
      .finally(() => {
        setCodIng(false);
        Dialog.close();
      })
      .then(res => {
        let { status } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          // coderef.current.focus()
          setCodeTime(60);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
    }
  };

  // 账号密码登录
  const pwdLogin = ()=>{
    if (!data.username) {
      Dialog.error('请输入用户名');
      return;
    }
    if (!data.password) {
      Dialog.error('请输入密码');
      return;
    }
    if (!agreement) {
      setVisibility('hidden')
      props.thingsknow()
      return;
    }
    if (loging) {
      Dialog.error(loging);
      return;
    }
    setLoging(true);
    Dialog.loading();
    general.axios.post('/user/user/login', data)
      .finally(() => {
        setLoging(false);
        Dialog.close();
      })
      .then(res => {
        let { status, data: result } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          if (status.error_code == '1046') {
            setTimeout(() => {
              setToggle('0');
            }, 1000);
          }
        } else {
          if (parseInt(result['isLock']) == 1) {
            const motheds = result['motheds']
            props.auth(motheds, data)
          } else {
            localStorage.setItem('user_id', result.user_id);
            localStorage.setItem('username', data.username);
            localStorage.setItem('token', result.token);
            if (!app.is_overseas){
              localStorage.setItem('toggle', '1');
            }
            let tmp = datas.filter(item => item.username !== data.username);
            tmp.unshift(data);
            setDatas(tmp);
            load_load();
            setWhether('true');
          }
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  // 手机号登录
  const mobileLogin = ()=>{
    if (!data.phone) {
      Dialog.error('请输入手机号');
      return;
    }
    if (!data.code) {
      Dialog.error('请输入验证码');
      return;
    }
    if (!agreement) {
      setVisibility('hidden')
      props.thingsknow()
      return;
    }
    if (loging) {
      return;
    }
  
    let location = window.location.href;
    if (window.location.host == 'webbox.99maiyou.cn') {
      if (params.agent) {
        location = window.location.href
      } else {
        location = window.location.href + "?agent=" + agent;
      }
    }

    Dialog.loading();
    general.axios.post('/smsLogin', {
      mobile: (areaNumData ? areaNumData : '86') + '-' + data.phone,
      code: data.code,
      location_href: agent == 'rr2f' ? location : ''
    })
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let { status, data:rdata } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setData({...data,code:''})
          let users = rdata.users;
          localStorage.setItem('phone',data.phone)
          if (users.length < 2) {
            setWhether('true');
            if (!rdata.isSetPassword) {
              localStorage.setItem('token', rdata.token);
              localStorage.setItem('user_id', rdata.user_id);
              localStorage.setItem('username', rdata.username);
              props.password();
            } else {
              localStorage.setItem('token', rdata.token);
              localStorage.setItem('user_id', rdata.user_id);
              localStorage.setItem('username', rdata.username);
              load_load()
            }
          } else {
            setVisibility('hidden')
            props.account(users)
          }
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const login = () => {
    if (app.is_overseas){
      if (toggle == '0'){
        pwdLogin()
      }else{
        if (email){
          if (!data.email) {
            Dialog.error('请输入邮箱');
            return;
          }
          if (!data.code) {
            Dialog.error('请输入验证码');
            return;
          }
          if (!agreement) {
            setVisibility('hidden')
            props.thingsknow()
            return;
          }
          if (loging) {
            return;
          }
        
          let location = window.location.href;
          if (window.location.host == 'webbox.99maiyou.cn') {
            if (params.agent) {
              location = window.location.href
            } else {
              location = window.location.href + "?agent=" + agent;
            }
          }
    
          Dialog.loading();
          general.axios.post('/emailLogin', {
            email: data.email,
            code: data.code,
            type: 'login'
          })
            .finally(() => {
              Dialog.close();
            })
            .then(res => {
              let { status, data } = res.data;
              if (!status.succeed) {
                Dialog.error(status.error_desc);
              } else {
                let users = data.users;
                localStorage.setItem('email',phoneRef.current.value)
                if (users.length < 2) {
                  setWhether('true');
                  if (!data.isSetPassword) {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('user_id', data.user_id);
                    localStorage.setItem('username', data.username);
                    props.password();
                  } else {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('user_id', data.user_id);
                    localStorage.setItem('username', data.username);
                    load_load()
                  }
                } else {
                  setVisibility('hidden')
                  props.account(users)
                }
              }
            })
            .catch(err => {
              Dialog.error(err.message);
            });
        }else {
          mobileLogin()
        }
      }
    }else {
      if (toggle == '0'){
        mobileLogin()
      }else {
        pwdLogin()
      }
    }

  }

  const load_load = () => {
    general.axios.post('/user/user/getMemberInfo')
      .then(response => {
        let { status, data } = response.data;
        if (!status.succeed) {

        } else {
          if (!localStorage.getItem('yx_token')){
            setTimeout(() => {
              PubSub.publish('loginSubscribe',true)
            }, 2000);
          }
          localStorage.setItem('yx_account',data.member_info.yx_account)
          localStorage.setItem('yx_token',data.member_info.yx_token)
          setUser({ isLogin: true, ...data.member_info });
          Dialog.success('登录成功', props.dismiss());
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const temporaryAdjuct = () => {
    let data = {};
    data.agent = agent;
    data.host = window.location.href;
    data.username = localStorage.getItem("username");
    data.type = 'register';
    general.axios.post('/home/testGame/temporaryAdjuct', data)
      .finally(() => {
        setRegistering(false);
        Dialog.close();
      })
      .then(response => {

      })
      .catch(err => {

      });
  }

  const updateAgreement = () => {
    let a = agreement == true ? false : (agreement == false ? true : false);
    setAgreement(a);
  }

  const getAccessToken = () => {
    general.axios.post('/getAuthToken', { aliTokenHost: location.origin,code:'1' })
      .finally(() => {
        setCodIng(false);
      })
      .then(res => {
        let { status, data } = res.data;
        if (!status.succeed) {
          // Dialog.error(status.error_desc);
        } else {
          let tokenInfo = {
            JwtToken: data.jwtToken,
            AccessToken: data.accessToken,
          }
          checkLogin(tokenInfo.JwtToken, tokenInfo.AccessToken);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const checkLogin = (jwtToken, accessToken) => {
    phoneNumberServer.checkLoginAvailable({
      accessToken: accessToken,
      jwtToken: jwtToken,
      success: function (res) {
        console.log("身份鉴权成功, 可唤起登录界面", res);
        if (res.code === 600000) {
          // 在此调用getLoginToken方法
          getToken();
        }
      },
      error: function (res) {
        console.log("身份鉴权失败", res);
      },
    });
  }

  const getToken = () => {
    phoneNumberServer.getLoginToken({
      // 成功回调
      success: function (res) {
        console.log('成功回调',res);
        // 一键登录: 可发请求到服务端调用 GetPhoneWithToken API, 获取用户手机号, 完成登录
        if (res.code === 600000) {
          Dialog.loading();
          general.axios.post('/cellularLogin', { authType: 'ali', spToken: res.spToken })
            .finally(() => {
              setCodIng(false);
              Dialog.close();
            })
            .then(res => {
              let { status, data } = res.data;
              if (!status.succeed) {
                Dialog.error(status.error_desc);
              } else {
                setVisiable(false)
                const users = data.users;
                if (users.length < 2) {
                  setWhether('true');
                  const user = users[0]
                  if (!user.isSetPassword) {
                    localStorage.setItem('token', user.token);
                    localStorage.setItem('user_id', user.id);
                    localStorage.setItem('username', user.username);
                    props.password()
                  } else {
                    localStorage.setItem('token', user.token);
                    localStorage.setItem('user_id', user.id);
                    localStorage.setItem('username', user.username);
                    load_load()
                  }
                } else {
                  setVisibility('hidden')
                  props.account(users)
                }
              }
            })
            .catch(err => {
              Dialog.error(err.message);
            });
        }
      },
      // 失败回调
      error: function (res) { 
        console.log('失败回调',res);
      },
      // 授权页状态监听函数
      watch: function (status, data) {
        console.log('授权页状态监听函数',status,data);
        if (data.isSuccess) {
          setShowLogin(data.isSuccess)
        }
      },
      timeout:10,
      // 配置选项
      authPageOption: {
        // mount: 'alilogin',
        navText: "一键登录",
        subtitle: "", // 副标题
        isHideLogo: true, // logo显示隐藏
        logoImg: "",
        btnText: "立即登录",
        agreeSymbol: " ",
        privacyOne: ["《用户协议》", `${userAgreement}?channel=${agent}`],
        privacyTwo: ["《隐私政策》", `${privacyPolicy}?channel=${agent}`],
        showCustomView: false,
        customView: {
          element:
            '<div class="btn_box other" onclick="clickEvent()">切换其他登录方式</div>',
          style: ".btn_box.other{background: #fff; color: #f00}",
          js: "function clickEvent(){alert(666666)}",
        },
        privacyBefore: "我已阅读并同意",
        privacyEnd: "",
        vendorPrivacyPrefix: "《",
        vendorPrivacySuffix: "》",
        privacyVenderIndex: 2,
        isDialog: true, // 是否是弹窗样式
        manualClose: false, // 是否手动关闭弹窗/授权页
        isPrePageType: false,
        isShowProtocolDesc: false,
      },
      protocolPageWatch: function (params) {

      }
    });
  }

  useInterval(() => {
    if (authCodeTime) {
      setAuthCodeTime(authCodeTime - 1);
    }
  }, 1000);

  const callback = (e) => {
    setVisibility('visible');
    setAreaNumData(e);
  }

  useEffect(()=>{
    // if (toggle == '0'){
    //   if (phoneRef.current){
    //     phoneRef.current.focus()
    //   }
    // }else {
    //   if (accountRef.current){
    //     accountRef.current.focus()
    //   }
    // }
  },[toggle,phoneRef,accountRef])

  return (
    <div>
      <Popup className={"alert-login-index " + visibility}
        visible={visiable}
        bodyStyle={{
          borderTopLeftRadius: '13px',
          borderTopRightRadius: '13px',
          overflow: 'hidden',
        }}>
        <CloseOutlined className='alert-close' onClick={() => {
          setData({})
          setVisiable(false)
        }}></CloseOutlined>
        <div className="toggle">
          <div className={"item " + (toggle === '0' ? 'active' : '')} onClick={() => {
            setToggle('0')
          }}>{app.is_overseas ? '账号登录' : '验证码登录'}</div>
          <div className={"item " + (toggle === '1' ? 'active' : '')} onClick={() => {
            setToggle('1')
          }}>{app.is_overseas ? '验证码登录' : '账号登录'}</div>
        </div>
        <div className="alert-login-content">
          {app.is_overseas ? 
          (toggle === '0' ?
            <div>
              <div className="input-item">
                {/* <div className="label">账号</div> */}
                <div className="input auth-input">
                  <input name="username" ref={accountRef} type="text" 
                    value={data.username || ''} 
                    placeholder="請輸入帳號/Email/手機號"
                    autoComplete="off"
                    onFocus={e => setFocus('username')}
                    onChange={e => setData({ ...data, username: e.target.value })}
                  />
                  {focus == 'username' && data.username
                    ? <CloseOutlined
                      className="assist close"
                      onClick={() => {
                        setData({ ...data, username: '' })
                        document.getElementById('option-input').selected = true
                      }}
                    />
                    : null}
                  <div className="assist select">
                    <DownOutlined className="select" />
                    <select onChange={e => {
                      setData(e.target.value != '' ? datas[parseInt(e.target.value)] : { username: '', password: '' })
                    }}>
                      <option id='option-input' selected={true} value="">手动输入</option>
                      {datas.map((item, index) => <option key={index} value={index} selected={false}>{item.username}</option>)}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-item">
                {/* <div className="label">密码</div> */}
                <div className="input auth-input">
                  <input name="password" type={password ? 'password' : 'text'} 
                    value={data.password || ''} 
                    placeholder="請輸入密碼" 
                    key="password"
                    onFocus={e => setFocus('password')}
                    onChange={e => setData({ ...data, password: e.target.value })}
                  />
                  {focus == 'password' && data.password
                    ? <CloseOutlined
                      className="assist close"
                      onClick={() => setData(data => ({ ...data, password: '' }))} />
                    : null}
                  <div className="assist password" onClick={() => togglePassword()}>
                    {password ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                  </div>
                </div>
              </div>
              <div className="register">
                <span onClick={() => {
                  props.register()
                }}>立即注册</span>
                <span onClick={() => {
                  props.forgot()
                }}>忘记密码?</span>
              </div>
            </div>
             :
            <div>
              <div className="input-item">
                {email ? <div className="label">邮箱</div>:
                <div className="label">
                  <Link onClick={() => { setVisibility('hidden') }} to={{
                    pathname: "/account/country",
                    callback: callback
                  }} className="area-num-text">
                    <img src="/resources/account/jia.png" />
                    <span className="value">
                      {areaNumData ? areaNumData : '86'}
                    </span>
                    <img src="/resources/account/sel-down.png" />
                  </Link>
                </div>}
                <div className="input">
                  <input ref={phoneRef} name="phone" type={email ? 'text' : '"number"'} 
                    defaultValue={email ? localStorage.getItem('email') : localStorage.getItem('phone')} 
                    value={email ? data.email || '' : data.phone || ''} 
                    placeholder={email ? '请输入邮箱' : '请输入手机号'}
                    autoComplete="off"
                    onFocus={e => setFocus('phone')}
                    onChange={e => {
                      if (email){
                        setData({ ...data, email: e.target.value })
                      }else{
                        setData({ ...data, phone: e.target.value })
                      }
                    }}
                  />
                  {/* {focus == 'phone' && data.phone
                    ? <CloseOutlined
                      className="assist close"
                      onClick={() => setData({ ...data, phone: '' })}
                    />
                    : null} */}
                    <div className='change-email' onClick={()=>{
                    setEmail(e=>!e)
                    setData({})
                  }}>{email ? '切换到手机号' : '切换到邮箱'}</div>
                </div>
              </div>
              <div className="input-item">
                <div className="label">验证码</div>
                <div className="input">
                  <input ref={coderef} name="code" type={'number'} 
                    value={data.code || ''} placeholder="请输入验证码" key="code"
                    onFocus={e => setFocus('code')}
                    onChange={e => setData({ ...data, code: e.target.value })}
                  />
                  {focus == 'code' && data.code
                    ? <CloseOutlined
                      className="assist close"
                      onClick={() => setData(data => ({ ...data, code: '' }))} />
                    : null}
                </div>
                <div className={'code-btn ' + (codIng || codeTime ? 'disable' : '')} onClick={codeGet}>
                  {codIng ? '发送中' : (codeTime ? (codeTime + '秒后重发') : '发送验证码')}
                </div>
              </div>
              <div className="register" style={{justifyContent:'center'}}>未注册的用户可直接注册登录</div>
            </div>
            )
          :
          (toggle === '0' ?
            <div>
              <div className="input-item">
                <div className="label">
                  <Link onClick={() => { setVisibility('hidden') }} to={{
                    pathname: "/account/country",
                    callback: callback
                  }} className="area-num-text">
                    <img src="/resources/account/jia.png" />
                    <span className="value">
                      {areaNumData ? areaNumData : '86'}
                    </span>
                    <img src="/resources/account/sel-down.png" />
                  </Link>
                </div>
                <div className="input">
                  <input ref={phoneRef} name="phone" type="number" 
                    value={data?.phone || ''} placeholder="请输入手机号"
                    autoComplete="off"
                    onFocus={e => setFocus('phone')}
                    onChange={e => setData({ ...data, phone: e.target.value })}
                  />
                  {focus == 'phone' && data.phone
                    ? <CloseOutlined
                      className="assist close"
                      onClick={() => setData({ ...data, phone: '' })}
                    />
                    : null}
                </div>
              </div>
              <div className="input-item">
                <div className="label">验证码</div>
                <div className="input">
                  <input ref={coderef} name="code" type={'number'} 
                    value={data.code || ''} placeholder="请输入验证码" key="code"
                    onFocus={e => setFocus('code')}
                    onChange={e => setData({ ...data, code: e.target.value })}
                  />
                  {focus == 'code' && data.code
                    ? <CloseOutlined
                      className="assist close"
                      onClick={() => setData(data => ({ ...data, code: '' }))} />
                    : null}
                </div>
                <div className={'code-btn ' + (codIng || codeTime ? 'disable' : '')} onClick={codeGet}>
                  {codIng ? '发送中' : (codeTime ? (codeTime + '秒后重发') : '发送验证码')}
                </div>
              </div>
              <div className="register" style={{justifyContent:'center'}}>未注册的用户可直接注册登录</div>
            </div> :
            <div>
              <div className="input-item">
                {/* <div className="label">账号</div> */}
                <div className="input auth-input">
                  <input name="username" ref={accountRef} 
                    type="text" value={data.username || ''} 
                    placeholder="请输入账号/手机号"
                    autoComplete="off"
                    onFocus={e => setFocus('username')}
                    onChange={e => setData({ ...data, username: e.target.value })}
                  />
                  {focus == 'username' && data.username
                    ? <CloseOutlined
                      className="assist close"
                      onClick={() => {
                        setData({ ...data, username: '' })
                        document.getElementById('option-input').selected = true
                      }}
                    />
                    : null}
                  <div className="assist select">
                    <DownOutlined className="select" />
                    <select onChange={e => {
                      setData(e.target.value != '' ? datas[parseInt(e.target.value)] : { username: '', password: '' })
                    }}>
                      <option id='option-input' selected={true} value="">手动输入</option>
                      {datas.map((item, index) => <option key={index} value={index} selected={false}>{item.username}</option>)}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-item">
                {/* <div className="label">密码</div> */}
                <div className="input auth-input">
                  <input name="password" type={password ? 'password' : 'text'} 
                    value={data.password || ''} 
                    placeholder="请输入密码" 
                    key="password"
                    onFocus={e => setFocus('password')}
                    onChange={e => setData({ ...data, password: e.target.value })}
                  />
                  {focus == 'password' && data.password
                    ? <CloseOutlined
                      className="assist close"
                      onClick={() => setData(data => ({ ...data, password: '' }))} />
                    : null}
                  <div className="assist password" onClick={() => togglePassword()}>
                    {password ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                  </div>
                </div>
              </div>
              <div className="register">
                <span onClick={() => {
                  props.register()
                }}>立即注册</span>
                <span onClick={() => {
                  props.forgot()
                }}>忘记密码?</span>
              </div>
            </div>)
          }
          <div className={'operate ' + (loging ? 'disable' : '')} onClick={login}>登录</div>
          <div className='autologin' onClick={getToken}>{showLogin ? '一键登录>' : ''}</div>
          <div className="account-agreement">
            <span className={'agreement' + (agreement ? ' checked' : '')} onClick={updateAgreement} />
            <label>
              登录即代表您同意
              <NavLink onClick={() => {
                props.handleRegisterAgreement(1)
                setVisibility('hidden')
              }} to={(() => {
                let url = URI(userAgreement)
                  .addQuery('title', '用户协议')
                  .addQuery('channel', agent)
                  .addQuery('device_type', (mobileDetect.os() == 'iOS' ? 10 : 20))
                  .toString();
                return url;
              })()}
                iframe={{ title: '《用户协议》' }}
                user={false}
              >《用户协议》</NavLink>
              <NavLink onClick={() => {
                props.handleRegisterAgreement(1)
                setVisibility('hidden')
              }} to={(() => {
                let url = URI(privacyPolicy)
                  .addQuery('title', '隐私政策')
                  .addQuery('channel', agent)
                  .addQuery('device_type', (mobileDetect.os() == 'iOS' ? 10 : 20))
                  .toString();
                return url;
              })()}
                iframe={{ title: '《隐私政策》' }}
                user={false}
              >《隐私政策》</NavLink>
              <br />
              接受免除或者限制责任、诉讼管辖约定等粗体标示条款
            </label>
          </div>
        </div>
      </Popup>
    </div>
  )
}
