import React from 'react'
import Active from './view/index/Active'
import URI from 'urijs';
import useActiveState from './lib/useActiveState';

export default function Overseas() {

  const [agent] = useActiveState('agent');

  return (
    <div>
      <div style={{
        paddingTop: 'env(safe-area-inset-top)', position: 'fixed', zIndex: '9',
        left: '0', right: '0', top: '0', backgroundColor: 'white'
      }}>
        <div className="operate" style={{position:'absolute',right:'0',height:'40px',lineHeight:'40px',paddingRight:'10px',fontSize:'15px'}}>
          <a href="#/user/bill?type=1">平台币记录</a>
        </div>
        <div style={{ textAlign: 'center', fontSize: '18px', color: '#282828', height: '40px', lineHeight: '40px' }}>储值</div>
      </div>
      <div className="view-iframe" style={{
        paddingTop: 'calc(env(safe-area-inset-top) + 40px)',
        height: 'calc(100vh - env(safe-area-inset-top) - 40px)',
      }}>
        <iframe src={(() => {
          let url = URI("https://sys.wakaifu.com/home/MixPay/ptb")
            .addQuery('username', localStorage.getItem('username'))
            .addQuery('token', localStorage.getItem('token'))
            .addQuery('location_url', window.location.origin)
            .addQuery('agent', agent)
            .addQuery('from', 'react')
            .toString();
          return url;
        })()} />
      </div>

    </div>
  )
}
