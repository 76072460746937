import React, { useEffect, useState } from 'react'
import './css/records.css'
import general from '../../../general'
import Dialog from '../../../lib/Dialog'

export default function Winning(props) {
    const [data,setData] = useState([])
    const [init,setInit] = useState(false)

    const load = ()=>{
        general.axios.request('/getWingList').then(res=>{
            const {status,data} = res.data
            setInit(true)
            if (status.succeed){
                setData(data.list)
            }else {
                Dialog.error(status.error_desc)
            }
        })
    }

    useEffect(()=>{
        if (props.active){
            load()
        }
    },[props.active])

  return (
    <div className='winning-container'>
        <div className="head">
            <div>奖品</div>
            <div>时间</div>
        </div>
        <div className="list">
            {data.map((item,index)=><div key={index} className="item">
                <div>{item.name}</div>
                <div>{item.time}</div>
            </div>)}
        </div>
        {data.length == 0 && init ? <div className="empty">
            <img className="icon" src="resources/hangup/placeholder.png" alt="" />
            <div className="desc">暂无领取记录</div>
        </div> : null}
    </div>
  )
}
