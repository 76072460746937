import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from "react-router-dom";
import { useLocalStorageState } from 'ahooks'
import { Popup, Space } from 'antd-mobile'
import { CloseOutlined } from '@ant-design/icons'
import { NavLink } from '../../../lib/Navigation'
import Dialog from '../../../lib/Dialog'
import general from '../../../general'
import URI from 'urijs'
import './css/worryfree.css'

export default function Worryfree(props) {

  const [handleAlert,setHandleAlert] = useLocalStorageState('worryfree-alert-state',true)
  const [select,setSelect] = useState(false)
  const [showAlert,setShowAlert] = useState(true)
  const [data,setData] = useState()
  const [visible,setVisible] = useState(false)
  const [isSend,setIsSend] = useState(false)
  const inputRef = useRef()
  const history = useHistory()
  const [timeCount,setTimeCount] = useState(0)

  const easyPlayInfo = ()=>{
    Dialog.loading()
    general.axios.post('/easyPlayInfo',{maiyouGameid:props.query.id})
     .finally(() => {
        Dialog.close()
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setData(data);
      })
  }

  const handleAction = ()=>{
    if (data?.status == 0){
      history.goBack()
    }else if (data?.status == 1){
      if (parseFloat(data?.amount.split('元')[0]) < 1){
        return
      }
      if (data.mobile.split('-')[1].length == 0){
        history.push("/user/phone")
      }else{
        setVisible(true)
      }
    }
  }

  useEffect(() => {
    if (props.active){
      props.setOperate(<NavLink to={(() => {
        let url = URI('https://api3.app.wakaifu.com/easyPlayRule')
            .addQuery('title', '规则')
            .toString();
          return url;
        })()}
        iframe={{title: '规则'}}
        user={false}
      >规则</NavLink>)
      easyPlayInfo()
    }
  }, [props.active])

  useEffect(()=>{
    const timer = setTimeout(() => {
      if (isSend){
        if (timeCount == 0){
          clearTimeout(timer)
        }else{
          setTimeCount(e=>e-1)
        }
      }
    }, 1000);

    return ()=>{
      clearTimeout(timer)
    }
  },[props.active,timeCount])
  
  /**
   * 发送验证码
   */
  const handleSendCode = ()=>{
    if (timeCount > 0){
      return
    }
    Dialog.loading()
    general.axios.post('/base/tool/getVerifyCode',{type:12})
    .finally(() => {
      Dialog.close()
    })
    .then(res => {
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
      }else{
        setTimeCount(60)
      }
    })
  }

  /**
   * 提交
   * @returns 
   */
  const easyPlaySubmit = ()=>{
    if (inputRef.current.value.length == 0){
      Dialog.error('请输入验证码')
      return
    }
    setVisible(false)
    Dialog.loading()
    let data = {
      scode: inputRef.current.value,
      maiyouGameid: props.query.id,
    }
    general.axios.post('/easyPlaySubmit',data)
     .finally(() => {
        Dialog.close()
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setVisible(false)
        Dialog.info('提交成功')
        setTimeout(() => {
          history.goBack()
        }, 2000);
      })
  }

  return (
    <div className="worry-free-index">
      <div className="worry-free-container">
        {data?.status < 2 ? <div className="top-noti">
          <img className="noti-icon" src="resources/worryfree/noti.png" alt="" />
          <span className="noti-text">首笔充值后</span>
          <span className="noti-text active">2小时内</span>
          <span className="noti-text">可申请退款</span>
        </div> : null}
        <div className="game-content">
          <div className="game-info">
            <img className="game-icon" src={data?.gameLogo} alt="" />
            <div className="game-name">{data?.gameName}<div className="nameRemark" >{data?.nameRemark}</div></div>
          </div>
          {(()=>{
            if (data?.status == 0 || data?.status == 2){
              return <div className="game-no-pay">
                <div className="game-time-item">
                  <div className="time-item-key">首笔充值时间</div>
                  <div className="time-item-value">{data?.firstRechargeTime}</div>
                </div>
                <div className="game-time-item" style={{position:'relative'}}>
                  <div className="time-item-key">申请截止时间</div>
                  <div className="time-item-value">{data?.endSubmitTime}</div>
                  <span style={{position:'absolute',right:'-1px',bottom:'3px',fontSize:'10px',color:'#bbb'}}>(满足条件延长至7天)</span>
                </div>
              </div>
            } else {
              return <div className="game-pay">
                <div className="pay-title">充值明细<span>（不包括代金券、金币等优惠）</span></div>
                <div className="account">
                  <div className="account-name">{data?.xhAlias}</div>
                  <div className="account-name">充值：{data?.amount}</div>
                </div>
                <div className="pay-item">
                  <div className="pay-item-key">充值总额</div>
                  <div className="pay-item-key">{data?.amount}</div>
                </div>
                <div className="pay-item">
                  <div className="pay-item-key">退还比例</div>
                  <div className="pay-item-key">{data?.refundRatio}</div>
                </div>
                <div className="pay-amount">
                  <div className="pay-amount-key">{data?.status == 3 ? '已退金币': '可退金币'}</div>
                  <div className="pay-amount-value">{data?.refundBalance}</div>
                </div>
              </div>
            }
          })()}
        </div>
        {(()=>{
          if (data?.status == 0 || data?.status == 2){
            return <div className="game-notice">
              <div className="notice-top">
                <img className="notice-top-icon" src="resources/worryfree/left.png" alt="" />
                <div className="notice-top-title">温馨提示</div>
                <img className="notice-top-icon" src="resources/worryfree/right.png" alt="" />
              </div>
              {data?.status == 2 ?
              <div className="notice-content">
                <span className="text-normal">您未在有效期内申请退款，感谢您对平台游戏的喜爱，祝您游戏愉快!</span>
              </div> :
              <div className="notice-content">
                <span className="text-normal">您还有</span>
                <span className="text-normal active">1次</span>
                <span className="text-normal">退还机会未使用，实付金额需大于<span className="text-normal active">1元</span>，游戏体验不满意可按要求申请退还</span>
              </div>}
              <div className="notice-bottom">
                <img className="notice-line" src="resources/worryfree/noti-line.png" alt="" />
                <div className="notice-bottom-item">
                  <img className="notice-bottom-item-icon"src="resources/worryfree/point.png" alt="" />
                  <div className="notice-bottom-content">游戏首笔充值后<span>2小时内</span>可申请</div>
                </div>
                <div className="notice-bottom-item">
                  <img className="notice-bottom-item-icon"src="resources/worryfree/point.png" alt="" />
                  <div className="notice-bottom-content">收回小号，退还2小时内总充值<span>金币*50%</span></div>
                </div>
                <div className="notice-bottom-item">
                  <img className="notice-bottom-item-icon"src="resources/worryfree/point.png" alt="" />
                  <div className="notice-bottom-content">退还金币至余额，最高可退<span>5000金币</span></div>
                </div>
              </div>
            </div>
          } else {
            return <div className="worry-content">
              <div className="worry-content-item">
                <div className="worry-content-item-key">首笔充值时间</div>
                <div className="worry-content-item-value">{data?.firstRechargeTime}</div>
              </div>
              <div className="worry-content-item">
                <div className="worry-content-item-key">申请截止时间</div>
                <div className="worry-content-item-value">{data?.endSubmitTime}</div>
              </div>
              <div className="worry-content-item">
                <div className="worry-content-item-key">小号回收</div>
                <div className="worry-content-item-value">{data?.xhRecoveryTips}</div>
              </div>
            </div>
          }
        })()}

        {data?.status == 1 && parseFloat(data?.amount.split('元')[0]) < 1 ? <div className="handle-desc">实付金额需大于1元</div> : null}
        <div className={"handleAction handleAction" + (data?.status == 1 && parseFloat(data?.amount.split('元')[0]) < 1 ? '2' : data?.status.toString())} onClick={handleAction}>
          {(()=>{
            let title = ''
            if (data?.status == 0){
              title = '去玩游戏'
            }else if (data?.status == 1){
              title = '申请退还'
            }else if (data?.status == 2){
              title = '已过期'
            }
            return title
          })()}
        </div>

        {handleAlert && showAlert ? 
        <div className="worry-free-alert">
          <div className="alert-content">
            <div style={{position:'relative',height:'162px'}}>
              <img className="alert-content-top-icon" src="resources/worryfree/alert-top.png" alt="" />
              <h2 className="alert-content-top-text">返金币～</h2>
            </div>
            <div className="alert-item">
              <img className="icon" src="resources/worryfree/1.png"></img>
              <div className="content">
                <div className="title">该游戏支持充值退还</div>
                <div className="desc">首笔充值后，<span>2小时内</span>可申请退还</div>
              </div>
            </div>
            <div className="alert-item">
              <img className="icon" src="resources/worryfree/2.png"></img>
              <div className="content">
                <div className="title">2小时内充值可退一半</div>
                <div className="desc">可退2小时内总充值<span>金币*50%</span></div>
              </div>
            </div>
            <div className="alert-item">
              <img className="icon" src="resources/worryfree/3.png"></img>
              <div className="content">
                <div className="title">返还金币，回收小号</div>
                <div className="desc">退还后该游戏<span>所有已充值</span>的小号（角色）将被收回</div>
              </div>
            </div>
            <div className="alert-action">
              <div className="action" onClick={()=>{
                setShowAlert(false)
                setHandleAlert(!select)
              }}>我知道了</div>
            </div>
            <div className="alert-cancel" onClick={e=>{
              e.preventDefault();
              setSelect(e=>!e)
            }}>
              <img className="icon" src={"resources/worryfree/" + (select ? 'select' : 'normal') + '.png'} alt="" />
              <span className="text">下次不再提醒</span>
            </div>
          </div>
        </div> : null}

          <Space direction='vertical'>
            <Popup 
              visible={visible} 
              bodyStyle={{
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
              }}
              onMaskClick={()=>setVisible(false)} >
              <div className="worry-free-space-index">
                <div className="worry-free-space-container">
                  <div className="space-top">确认退还</div>
                  <CloseOutlined onClick={()=>setVisible(false)} className="close-action"></CloseOutlined>
                  {
                    !isSend ? <div className="space-desc">当前游戏仅有一次退还机会，您的<span>小号将被回收</span>您将收到：</div> :
                    <div className="space-desc">{'已发送到您的手机'}{data.mobile.split('-')[1].substring(0,3)}****{data.mobile.split('-')[1].substring(7,11)}</div>
                  }
                  {!isSend ? <div className="space-content">
                    <div className="space-content-key">可退金币</div>
                    <div className="space-content-key">{data?.refundBalance}</div>
                  </div> :
                    <div className="space-content">
                      <input ref={inputRef} type="text" className="space-content-input space-content-key" placeholder="请输入验证码"/>
                      <div className="space-content-action" onClick={handleSendCode}>{timeCount == 0 ? '发送验证码' : timeCount.toString()}</div>
                    </div>
                  }
                  <div className="space-action" onClick={()=>{
                    if (!isSend){
                      setIsSend(true)
                      handleSendCode()
                    }else{
                      easyPlaySubmit()
                    }
                  }}>确认</div>
                  <div onClick={()=>{
                    setIsSend(false)
                    setVisible(false)
                  }} className="space-cancel">取消</div>
                </div>
              </div>
            </Popup>
          </Space>

      </div>
    </div>
  )
}
