import {useEffect, useState} from "react";
import $ from "jquery";
import {useScroll} from "ahooks";

// @deprecated

export default props => {
  const _scroll = useScroll();
  const [scroll, setScroll] = useState({top: 0, left: 0});
  useEffect(() => {
    if (props.active) {
      $('html,body').scrollTop(scroll.top);
      $('html,body').scrollLeft(scroll.left);
    } else {
      setScroll(_scroll);
    }
  }, [props.active]);

  return <>
    {props.children}
  </>
};