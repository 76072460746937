import React, {useEffect, useState} from "react";
import general from "../../general";
import {Link} from "react-router-dom";
import $ from "jquery";
import {useScroll, useLockFn, useMount} from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import useActiveState from '../../lib/useActiveState';
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {NavLink} from "../../lib/Navigation";
import Subtitle from "../components/Subtitle";

// export default ({active, type}) => {
export default props => {
  const {aplus_queue} = window;
  const [page, setPage] = useState(1);
  const [games, setGames] = useState([]);
  const [more, setMore] = useState(true);
  const [obsolete, setObsolete] = useState();
  const [user] = useActiveState('user');
  const [agent] = useActiveState('agent');
  const [randomColorInfo] = useActiveState('randomColorInfo');//随机颜色数组
  const [init, setInit] = useState(false);//状态
  const [exchangeState, setExchangeState] = useState(false);//兑换弹框的状态
  const [info, setInfo] = useState([]);
  const [items, setItems] = useState();
  const [contents, setContents] = useState([]);

  const load = useLockFn(async (parms = {}) => {
    Dialog.loading();
    let tmp = {
      // pagination: {count: 20, page: page},
    };

    tmp.id = props.query.id;
    // if (parms.page) {
    //   tmp.pagination.page = parms.page;
    // }
    await general.axios.post('/transferV2/info', tmp)
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }

        setInit(true);
        setObsolete(false);
        setInfo(data.info);
        setItems(data.items);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);

  const exchange = item => {
    if(Number(info?.score?.toFixed(1)) < Number(item.score)) {
      Dialog.info('转游点不足，无法兑换');
      return;
    }
    setContents(item);
    setExchangeState(true);
  }

  const comfirm = () => {
    Dialog.loading();

    let tmp = {};
    if(props.query.id) {
      tmp.trans_id = props.query.id;
    } else {
      tmp.trans_id = info.trans_id;
    }
    tmp.id = contents.id;
    tmp.type = contents.type;
    
    setExchangeState(false);
    general.axios.post('/transferV2/receive', tmp)
    .finally(() => {
      Dialog.close();
    })
    .then(response => {
      let {status, data} = response.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }

      Dialog.info("兑换成功");
      load();
    })
    .catch(err => {
      Dialog.error(err.message);
    });
  }

  return <div className="around-detail">
    <div className="around-amound" >
      <div className="title">我的转游点：<span>{info?.score == 0 ? 0 : info?.score?.toFixed(1)}</span></div>
    </div>
    {info ? 
    <Link to={'/game?mid=' + info?.maiyouGameid} className="game-info" >
      <div className="icon">
        <Image src={info?.logo?.thumb}/>
      </div>
      <div className="subject">
        <div className="text">
          <div className="name">
            <div className={" " + (info?.nameRemark ? " name-new" : "")}>
              {info?.topLable != '' ? <span className="span">{info.topLable}</span> : null}
              {info?.gameName}
            </div>
          </div>
          <div className="info1">
            {info?.versioncode}
            <Subtitle>{info?.nameRemark}</Subtitle>
          </div>
          {/* <div className="info2">
            {info?.version ? info.version.split('+').map(item => <div className="tag">{item}</div>) : <span>{info.introduction}</span>}
          </div> */}
          {
            info?.bottomLable ? 
            <>
            {
              Object.keys(info?.bottomLable).length > 0 ?
              <div className="info3">
                  <span className="first-text" style={{background:info.bottomLable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{info.bottomLable.left_content}</span><span className="second-text" style={{color:info.bottomLable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:info.bottomLable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{info.bottomLable.right_content}</span>
              </div>
              :null
            }
            </>
          :null}
        </div>
        {info?.discount < 1 ? <div className="discount" ><span>{info.discount * 10}</span>折</div> : null}
      </div>
    </Link>
    : null}

    <div className="title-name">兑换转游福利</div>

    <div className="items">
      {
        items?.map((item, index) => <div className="item">
          <div className="text">
            <div className="name">{item.name}</div>
            <div className="info">{item.remark}</div>
          </div>
          <div className="base">
            {
              item.isReceived ? 
              <CopyToClipboard text={item.packCard} onCopy={() => Dialog.info('复制成功')}><div className="operate copy">复制</div></CopyToClipboard>
              :
              <div className="operate" onClick={() => exchange(item)}>兑换</div>
            }
            <div className="info">{item.score}转游点</div>
          </div>
        </div>)
      }
      {/* <div className="item">
        <div className="text">
          <div className="name">转游礼包5</div>
          <div className="info">初阶进化卡*200、中阶进化卡*100、进化石礼初阶进化卡*200、中阶进化卡*100</div>
        </div>
        <div className="base">
          <div className="operate">复制</div>
          <div className="info">30转游点</div>
        </div>
      </div>
      <div className="item">
        <div className="text">
          <div className="name">200元代金券</div>
          <div className="info">满2000元可用</div>
        </div>
        <div className="base">
          <div className="operate" onClick={() => setExchangeState(true)}>兑换</div>
          <div className="info">30转游点</div>
        </div>
      </div> */}
    </div>

    {exchangeState ? 
    <div className="exchange-index">
      <div className="exchange-site">
        <div className="exchange-main">
          <div className="close"  onClick={() => setExchangeState(false)}>
            <img src="/resources/user/close.png" />
          </div>
          <div className="exchange-title">
            {/* 200元代金券 */}
            {contents?.name}
          </div>
          <div className="info">
            <p>{contents?.type == 1 ? '使用要求' : '礼包内容'} ：{contents?.remark}</p>
            <p>消耗转游点数：{contents?.score}转游点</p>
            {info?.countAlt > 0 ? <p className="des2">提示：您有{info.countAlt}个小号可回收，回收可获得{info?.altBalance}金币。</p> : null}
          </div>
          <div className="exchange-btn">
            <div className="item btn-confirm" onClick={() => comfirm()}>
              兑换
            </div>
            {info?.countAlt > 0 ? 
            <NavLink to="/recovery" className="item btn-about" >
              回收小号
            </NavLink>
            : null}
          </div>
        </div>
      </div>
    </div>
    : null}

  </div>;
};