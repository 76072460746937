import { Mask } from 'antd-mobile'
import React, { useEffect, useState } from 'react'
import './css/gamesign.css'
import CopyToClipboard from 'react-copy-to-clipboard'
import Dialog from '../../../lib/Dialog'
import general from '../../../general'

export default function SignAlert(props) {

  const [visible,setVisible] = useState(props.visible)
  const [gift,setGift] = useState()

  const close = () => {
    props.action()
  }

  useEffect(()=>{
    setGift(props.gift)
  },[props.gift])

  return (
    <div className='sign-alert'>
      <Mask visible={visible} getContainer={props.getContainer ? props.getContainer : ''} onMaskClick={() => setVisible(false)}>
        <div className="sign-alert-container">
          <div className="container">
            <div className="head">
              <img className='ic' src="resources/game/sign/礼包.png" alt="" />
            </div>
            <div className="title">签到成功，已领取礼包！</div>
            <div className="gift">礼包名称：{gift?.packname}</div>
            <div className="gift">礼包内容：{gift?.packcontent}</div>
            <div className="code">
              <div className="value">礼包码：{gift?.packcard}</div>
              <CopyToClipboard text={gift?.packcard} onCopy={()=>{Dialog.info('复制成功')}}>
                <div className="copy">复制</div>
              </CopyToClipboard>
            </div>
            <div className="open" onClick={()=>{
              close()
              general.history.push('/game?id=' + gift?.game_id)
            }}>打开游戏</div>
          </div>
          <img className='close' onClick={close} src="resources/game/sign/close.png" alt="" />
        </div>
      </Mask>
    </div>
  )
}
